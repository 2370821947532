import React from "react"

import { FIELD_TYPES } from "common/constants/fields"

import i18n from "i18n"

export const getFieldByKey = (key, fields) => {
    const field = fields.find(item => item.key === key)
    return field || null
}

export const getFieldLabelByKey = (key, fields) => {
    const field = fields.find(item => item.key === key)
    return field?.label || ''
}

export const getFormattedValue = (initialValue, key, fields = [], fallbackValue = 'N/A') => {
    if (typeof initialValue === "undefined") return <span style={{color: '#979797'}}>{fallbackValue}</span>

    const field = getFieldByKey(key, fields)

    if (!field) return initialValue

    let formattedValue = initialValue
    if (field.type === FIELD_TYPES.EMAIL) {
        formattedValue = <a href={`mailto:${initialValue}`}>{initialValue}</a>
    }
    if (field.type === FIELD_TYPES.PHONE_NUMBER) {
        formattedValue = <a href={`tel:${initialValue}`}>{initialValue}</a>
    }
    if (field.type === FIELD_TYPES.URL) {
        formattedValue = <a href={initialValue}>{initialValue}</a>
    }
    if (field.type === FIELD_TYPES.CHECKBOX) {
        if (initialValue === 'true' || initialValue === true) formattedValue = i18n.t('Yes')
        if (initialValue === 'false' || initialValue === false || initialValue === '') formattedValue = i18n.t('No')
    }

    return formattedValue
}

export const getCustomFieldsMap = fields =>
    fields.filter(field => field.key.startsWith('customField')).reduce((acc, v) => ({ ...acc, [v.key]: v.label }), {})

export const getCsvFilename = (label, from, to) => {
    const fromDate = new Date(from)
    const toDate = to ? new Date(to) : new Date()

    const dates = {
        from: `${fromDate.toLocaleDateString('en-US')} ${fromDate.toLocaleTimeString('en-US')}`,
        to: `${toDate.toLocaleDateString('en-US')} ${toDate.toLocaleTimeString('en-US')}`,
    }
    return `${label} (${dates.from} - ${dates.to})_${Date.now()}.csv`
}