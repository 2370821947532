import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ResponsiveChoropleth } from '@nivo/geo'

import OverText from '../../../OverText/OverText'

import countries from './featureCollection.json'

import { colors } from './constants'

import mockData from './mockData'

import './WorldMap.scss'

const WorldMap = ({ data, isLocked = false }) => {
    const { t } = useTranslation()
    const [scrollX] = useState(0.7)
    const [coords] = useState([0.5, 0.58])
    const [scale] = useState(127)

    const [formattedData, setFormattedData] = useState([])
    const [max, setMax] = useState(0)

    useEffect(() => {
        const tmpData = []
        let tmpMax = 0
        const arr = isLocked ? mockData : data
        arr.forEach(country => {
            if (tmpMax < country.clientsCount) {
                tmpMax = country.clientsCount
            }
            tmpData.push({
                id: country.isoCountryCode,
                value: country.clientsCount,
            })
        })
        setFormattedData(tmpData)
        setMax(tmpMax)
    }, [data, isLocked])

    return (
        <div className="map-block">
            <div className="map-container">
                <ResponsiveChoropleth
                    data={formattedData}
                    features={countries.features}
                    margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                    colors={colors}
                    domain={[0, max]}
                    unknownColor="#666666"
                    label="properties.name"
                    projectionScale={scale}
                    projectionTranslation={coords}
                    projectionRotation={[scrollX, 0, 0]}
                    enableGraticule={true}
                    graticuleLineWidth={0}
                    graticuleLineColor="#dddddd"
                    borderWidth={1}
                    borderColor="#152538"
                    valueFormat={v => v.toFixed(1)}
                    legends={[
                        {
                            anchor: 'bottom',
                            direction: 'row',
                            justify: false,
                            translateX: 2,
                            translateY: 0,
                            itemsSpacing: 28,
                            itemWidth: 70,
                            itemHeight: 10,
                            itemDirection: 'left-to-right',
                            itemTextColor: '#444444',
                            itemOpacity: 0.85,
                            symbolSize: 18,
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemTextColor: '#000000',
                                        itemOpacity: 1,
                                    },
                                },
                            ],
                        },
                    ]}
                />
            </div>
            {isLocked && <OverText />}
        </div>
    )
}

export default WorldMap
