import React from 'react'
import i18n from 'i18n'

import { FIELD_TYPES } from 'common/constants/fields'
import { IconVisible } from 'svg'

import styles from './components/LeadInfoModal.module.scss'

const fieldNames = {
    firstName: i18n.t('First name'),
    lastName: i18n.t('Last name'),
    email: i18n.t('Email'),
    phoneNumber: i18n.t('Phone number'),
    company: i18n.t('Company'),
    companyURL: i18n.t('Company URL'),
    nickname: i18n.t('Nickname'),
}

const MAX_COLUMNS_IN_TABLE = 4

export const getColumns = (fields, onSelectColumn) => {
    if (!fields) return []

    const cols = []
    fields.forEach(field => {
        const props = {}

        if (field.type === FIELD_TYPES.EMAIL) {
            props.getValue = v => <a href={`mailto:${v}`}>{v}</a>
        }
        if (field.type === FIELD_TYPES.PHONE_NUMBER) {
            props.getValue = v => <a href={`tel:${v}`}>{v}</a>
        }
        if (field.type === FIELD_TYPES.URL) {
            props.getValue = v => <a href={v}>{v}</a>
        }
        if (field.type === FIELD_TYPES.CHECKBOX) {
            props.getValue = v => {
                if (v === 'true') return i18n.t('Yes')
                if (v === 'false' || v === '') return i18n.t('No')
                return v
            }
        }

        if (cols.length < MAX_COLUMNS_IN_TABLE) {
            cols.push({
                field: field.key,
                headerName: fieldNames[field.key] || field.label,
                styles: {
                    width: `${100 / (fields.length < MAX_COLUMNS_IN_TABLE ? fields.length : MAX_COLUMNS_IN_TABLE)}%`,
                },
                ...props,
            })
        }
    })

    cols.push({
        field: 'sessionId',
        headerName: i18n.t('More info'),
        styles: { width: '10%', textAlign: 'end', justifyContent: 'end' },
        getValue: v =>
            v && (
                <div className={styles.leadInfoModalButton} onClick={() => onSelectColumn(v)}>
                    <IconVisible />
                </div>
            ),
    })

    return cols
}

export const getCustomFieldsMap = fields =>
    fields.filter(field => field.key.startsWith('customField')).reduce((acc, v) => ({ ...acc, [v.key]: v.label }), {})
