import {
    CONTROLS,
    CONTROL_PARAMS,
    ICONS,
    TEXT_PARAMS,
    DEFAULT_FONT_FAMILY,
    DEFAULT_FONT_SIZE,
    DEFAULT_LINE_HEIGHT,
    DEFAULT_LETTER_SPACING,
} from './constants'

export const isActive = (editor, control, params = {}) => {
    if (!editor) return false
    return editor.isActive(control, params)
}

export const getButtonParentText = value => (!!value ? value.replaceAll('"', '') : null)

export const getActiveHierarchyIcon = editor => {
    if (isActive(editor, CONTROLS.PARAGRAPH)) return ICONS.PARAGRAPH
    if (isActive(editor, CONTROLS.HEADING, { [CONTROL_PARAMS.HEADING.LEVEL]: 1 })) return ICONS.HEADING.LEVEL_1
    if (isActive(editor, CONTROLS.HEADING, { [CONTROL_PARAMS.HEADING.LEVEL]: 2 })) return ICONS.HEADING.LEVEL_2
    if (isActive(editor, CONTROLS.HEADING, { [CONTROL_PARAMS.HEADING.LEVEL]: 3 })) return ICONS.HEADING.LEVEL_3

    return null
}

export const getActiveListIcon = editor => {
    if (isActive(editor, CONTROLS.BULLET_LIST)) return ICONS.BULLET_LIST
    if (isActive(editor, CONTROLS.ORDERED_LIST)) return ICONS.ORDERED_LIST

    return null
}

export const getActiveHierarchyAndListsIcon = editor => {
    const activeListIcon = getActiveListIcon(editor)
    if (activeListIcon) return activeListIcon

    const activeHierarchyIcon = getActiveHierarchyIcon(editor)
    if (activeHierarchyIcon) return activeHierarchyIcon

    return ICONS.PARAGRAPH
}

export const getActiveAlign = editor => {
    if (isActive(editor, { [TEXT_PARAMS.TEXT_ALIGN.NAME]: TEXT_PARAMS.TEXT_ALIGN.VALUES.LEFT }))
        return TEXT_PARAMS.TEXT_ALIGN.VALUES.LEFT
    if (isActive(editor, { [TEXT_PARAMS.TEXT_ALIGN.NAME]: TEXT_PARAMS.TEXT_ALIGN.VALUES.CENTER }))
        return TEXT_PARAMS.TEXT_ALIGN.VALUES.CENTER
    if (isActive(editor, { [TEXT_PARAMS.TEXT_ALIGN.NAME]: TEXT_PARAMS.TEXT_ALIGN.VALUES.RIGHT }))
        return TEXT_PARAMS.TEXT_ALIGN.VALUES.RIGHT
    if (isActive(editor, { [TEXT_PARAMS.TEXT_ALIGN.NAME]: TEXT_PARAMS.TEXT_ALIGN.VALUES.JUSTIFY }))
        return TEXT_PARAMS.TEXT_ALIGN.VALUES.JUSTIFY
    return null
}
export const getActiveAlignIcon = editor => {
    const activeAlign = getActiveAlign(editor)
    if (!activeAlign) return ICONS.ALIGN.LEFT
    if (activeAlign === TEXT_PARAMS.TEXT_ALIGN.VALUES.LEFT) return ICONS.ALIGN.LEFT
    if (activeAlign === TEXT_PARAMS.TEXT_ALIGN.VALUES.CENTER) return ICONS.ALIGN.CENTER
    if (activeAlign === TEXT_PARAMS.TEXT_ALIGN.VALUES.RIGHT) return ICONS.ALIGN.RIGHT
    if (activeAlign === TEXT_PARAMS.TEXT_ALIGN.VALUES.JUSTIFY) return ICONS.ALIGN.JUSTIFY
    return null
}

export const getActiveFontFamily = editor => editor.getAttributes(CONTROLS.TEXT_STYLE).fontFamily || DEFAULT_FONT_FAMILY
export const getActiveFontSize = editor => editor.getAttributes(CONTROLS.TEXT_STYLE).fontSize || DEFAULT_FONT_SIZE
export const getNumberedFontSize = editor => {
    const fz = getActiveFontSize(editor).replaceAll('px', '')
    return Number(fz)
}
export const getActiveLineHeight = editor => editor.getAttributes(CONTROLS.TEXT_STYLE).lineHeight || DEFAULT_LINE_HEIGHT
export const getActiveLetterSpacing = editor =>
    editor.getAttributes(CONTROLS.TEXT_STYLE).letterSpacing || DEFAULT_LETTER_SPACING
