import React, { useState } from 'react'
import classNames from 'classnames'

import BLOCKS from 'shared/constants/blocks'

import { BlockComponents } from '../constants'

import InlineControls from './components/Controls/Inline/Inline'

import { getIsMultiplayerBlock } from 'utils/multiplayer'

const BlocksController = ({ pages, isMultiplayerGame, block, appData, isSelected, isLast, isFirst, methods }) => {
    const [Component] = useState(() => BlockComponents[block.t])
    const [isHovered, setIsHovered] = useState(false)

    return (
        <div
            className={classNames('block-control', { __selected: isSelected })}
            data-id={block.id}
            onClick={evt => {
                evt.stopPropagation()
                methods.onBlockSelect(block)
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <div className="selected-border" />
            <Component
                {...block} // TODO пока оставил чтобы не ломалось, но надо перенести логику в блоках > 9 на "blockData.<KEY>"
                blockData={block}
                appData={appData}
                pagesData={pages}
                onUpdate={_data => methods.changeBlocksData(_data)}
                requestArrayElementControls={methods.requestArrayElementControls}
                selected={isSelected}
            />
            <div
                className="plus"
                onClick={evt => {
                    methods.onOpenBlockPanel(block.id)
                    evt.stopPropagation()
                }}
            />
            <InlineControls
                isHideClone={(isMultiplayerGame && getIsMultiplayerBlock(block)) || block.t === BLOCKS.authForm}
                isHideDelete={block.t === BLOCKS.authForm}
                isVisible={isSelected || isHovered}
                firstElement={isFirst}
                lastElement={isLast}
                onAction={methods.onInlineControlAction}
                blockId={block.id}
            />
        </div>
    )
}

export default BlocksController
