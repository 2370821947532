const OrderedList = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        <path d="M10 12h11"></path>
        <path d="M10 18h11"></path>
        <path d="M10 6h11"></path>
        <path d="M4 10h2"></path>
        <path d="M4 6h1v4"></path>
        <path d="M6 18H4c0-1 2-2 2-3s-1-1.5-2-1"></path>
    </svg>
)

export default OrderedList
