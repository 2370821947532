import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import UpgradeDialog from 'components/Modal/UpgradeDialog/UpgradeDialog.jsx'
import PrivacySettingsModal from './PrivacySettingsModal/PrivacySettingsModal'
import TimeIsUpModal from './TimeIsUpModal/TimeIsUpModal'
import LeadFormModal from './LeadFormModal/LeadFormModal'
import CustomTranslationsModal from './CustomTranslationsModal/CustomTranslationsModal'
import YandexMetricModal from './YandexMetricModal/YandexMetricModal'
import SelectInternalPageModal from './SelectInternalPageModal/SelectInternalPageModal'
import { MODE as leadFormMode } from './LeadFormModal/constants'

export const MODAL_NAMES = {
    privacySettingsModal: 'privacySettingsModal',
    timeIsUpModal: 'timeIsUpModal',
    leadFormModal: 'leadFormModal',
    customTranslationsModal: 'customTranslationsModal',
    selectInternalPageModal: 'selectInternalPageModal',
    UpgradeDialog: 'UpgradeDialog',
    yandexMetricModal: 'yandexMetricModal',
}
const ModalController = ({ modal, methods, data }) => {
    const history = useHistory()

    const renderedModal = useMemo(() => {
        switch (modal.name) {
            case MODAL_NAMES.privacySettingsModal: {
                return (
                    <PrivacySettingsModal
                        onClose={() => methods.closeModal(MODAL_NAMES.privacySettingsModal)}
                        onUpdate={_data => methods.changeBlocksData(_data)}
                        data={modal.payload}
                    />
                )
            }
            case MODAL_NAMES.timeIsUpModal: {
                return (
                    <TimeIsUpModal
                        onClose={() => methods.closeModal(MODAL_NAMES.timeIsUpModal)}
                        onUpdate={_data => methods.changeBlocksData(_data)}
                        data={modal.payload}
                    />
                )
            }
            case MODAL_NAMES.leadFormModal: {
                return (
                    <LeadFormModal
                        mode={leadFormMode.EMBEDDED}
                        onClose={() => methods.closeModal(MODAL_NAMES.leadFormModal)}
                        data={{
                            isEnableRating: modal.payload.isEnableRating,
                            structure: modal.payload.value,
                            backgroundColor: modal.payload.leadFormBackgroundColor,
                            fontFamily: modal.payload.leadFormFontFamily,
                            id: modal.payload.blockId,
                        }}
                        methods={{
                            closeModal: () => methods.closeModal(MODAL_NAMES.leadFormModal),
                            save: _data => methods.changeBlocksData(_data),
                        }}
                    />
                )
            }
            case MODAL_NAMES.customTranslationsModal: {
                return (
                    <CustomTranslationsModal
                        data={data}
                        onClose={() => methods.closeModal(MODAL_NAMES.customTranslationsModal)}
                        onUpdate={_data => methods.changeAppData(_data)}
                    />
                )
            }
            case MODAL_NAMES.yandexMetricModal: {
                return (
                    <YandexMetricModal
                        data={modal.payload}
                        onClose={() => methods.closeModal(MODAL_NAMES.yandexMetricModal)}
                        onUpdate={_data => methods.changeBlocksData(_data)}
                    />
                )
            }
            case MODAL_NAMES.selectInternalPageModal: {
                return (
                    <SelectInternalPageModal
                        data={{
                            pages: data.pages,
                            ...modal.payload,
                        }}
                        onClose={() => methods.closeModal(MODAL_NAMES.selectInternalPageModal)}
                        onUpdate={_data => methods.changeBlocksData(_data)}
                    />
                )
            }
            case MODAL_NAMES.UpgradeDialog: {
                return (
                    <UpgradeDialog
                        onClose={() => methods.closeModal(MODAL_NAMES.UpgradeDialog)}
                        onUpgrade={() => history.push('/billing-and-payments?tab=subscription')}
                        data={modal.payload}
                    />
                )
            }
            default: {
                return null
            }
        }
    }, [modal.isOpen])

    return modal.isOpen ? renderedModal : null
}

export default ModalController
