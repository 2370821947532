import React from 'react'

import store from 'store'

import i18n from 'i18n'

import BLOCK from 'common/constants/BlockTypes/BlocksEnum'
import { IMAGE_SIZES } from 'common/constants/image'
import { CONDITIONS, hasPermission } from 'common/utils/permissions'
import CURRENCIES from 'common/constants/currencies'

import { SCHEMA_KEY } from './schema'

import tabAuthorizationIcon from './i/controlTabs/authorization.svg'
import tabBasicIcon from './i/controlTabs/basic.svg'
import tabDesignIcon from './i/controlTabs/design.svg'
import tabAdvancedIcon from './i/controlTabs/advanced.svg'
import tabActionButtonIcon from './i/controlTabs/actionButton.svg'
import tabGamificationIcon from './i/controlTabs/gamification.svg'
import tabLeadFormIcon from './i/controlTabs/leadForm.svg'
import tabRedirectIcon from './i/controlTabs/redirect.svg'
import tabTimerIcon from './i/controlTabs/timer.svg'

import DemoContent from './Panels/Control/components/Controls/DemoWithModal/components/DemoContent'

const PRIVACY_GAMIFICATION_DEMO = [
    {
        isVisible: ({ isMultiplayerGame }) => !isMultiplayerGame && !hasPermission(CONDITIONS.CAN_USE_GAMIFICATION),
        value: SCHEMA_KEY.privacyDemo,
        tooltipText: <DemoContent isPrivacy />,
    },
    {
        isVisible: ({ isMultiplayerGame }) => !isMultiplayerGame && !hasPermission(CONDITIONS.CAN_USE_GAMIFICATION),
        value: SCHEMA_KEY.gamificationDemo,
        tooltipText: <DemoContent />,
    },
]
const PASSWORD_LIST_CONTROLS = [
    {
        isVisible: ({ isMultiplayerGame }) => !isMultiplayerGame && hasPermission(CONDITIONS.CAN_USE_GAMIFICATION),
        values: [
            {
                value: SCHEMA_KEY.passwordList,
            },
        ],
    },
]
const LEAD_FORM_CONTROLS = [
    {
        isVisible: ({ isMultiplayerGame }) =>
            !isMultiplayerGame && hasPermission(CONDITIONS.CAN_USE_LEAD_FORM_IN_BLOCKS),
        tab: {
            label: i18n.t('Lead Form'),
            isActive: ({ blockData }) => blockData[SCHEMA_KEY.isShowLeadForm],
            icon: tabLeadFormIcon,
        },
        values: [
            { value: SCHEMA_KEY.isShowLeadForm },
            {
                isVisible: ({ blockData }) => blockData[SCHEMA_KEY.isShowLeadForm],
                value: SCHEMA_KEY.leadFormStruct,
            },
            {
                isVisible: ({ blockData }) => blockData[SCHEMA_KEY.isShowLeadForm],
                value: SCHEMA_KEY.isShowLeadFormBeforeGame,
            },
            {
                isVisible: ({ blockData }) => blockData[SCHEMA_KEY.isShowLeadForm],
                value: SCHEMA_KEY.leadFormBackgroundColor,
            },
            {
                isVisible: ({ blockData }) => blockData[SCHEMA_KEY.isShowLeadForm],
                value: SCHEMA_KEY.leadFormFontFamily,
            },
        ],
    },
]
const AUTO_REDIRECT_CONTROLS = [
    {
        isVisible: ({ isMultiplayerGame }) => !isMultiplayerGame,
        tab: {
            label: i18n.t('Auto Redirect'),
            isActive: ({ blockData }) => blockData[SCHEMA_KEY.isRedirectEnabled],
            icon: tabRedirectIcon,
        },
        values: [
            { value: SCHEMA_KEY.isRedirectEnabled },
            {
                isVisible: ({ blockData }) => !!blockData[SCHEMA_KEY.isRedirectEnabled],
                value: SCHEMA_KEY.redirectTargetLink,
            },
            {
                isVisible: ({ blockData }) => !!blockData[SCHEMA_KEY.isRedirectEnabled],
                value: SCHEMA_KEY.redirectTimeout,
            },
        ],
    },
]
const INTEGRATION_METADATA_CONTROLS = [
    {
        isVisible: () => false, // sdk build is crashed, uncomment if fix
        // isVisible: () => hasPermission(CONDITIONS.CAN_USE_SDK_INTEGRATION),
        values: [{ value: SCHEMA_KEY.integrationMetadata }],
    },
]
const TIMER_CONTROLS = [
    {
        isVisible: ({ isMultiplayerGame }) => !isMultiplayerGame && !hasPermission(CONDITIONS.CAN_USE_GAMIFICATION),
        tab: {
            label: i18n.t('Timer / Countdown'),
            icon: tabTimerIcon,
        },
        values: [
            {
                value: SCHEMA_KEY.enableTimer,
            },
            {
                isVisible: ({ blockData }) => blockData[SCHEMA_KEY.enableTimer],
                value: SCHEMA_KEY.timerType,
            },
            {
                isVisible: ({ blockData }) =>
                    blockData[SCHEMA_KEY.enableTimer] &&
                    blockData[SCHEMA_KEY.timerType] &&
                    blockData[SCHEMA_KEY.timerType].value === 'countdown',
                value: SCHEMA_KEY.countdownTime,
            },
            {
                isVisible: ({ blockData }) =>
                    blockData[SCHEMA_KEY.enableTimer] &&
                    blockData[SCHEMA_KEY.timerType] &&
                    blockData[SCHEMA_KEY.timerType].value === 'countdown',
                value: SCHEMA_KEY.timeIsUpScreen,
            },
        ],
    },
]
const GAMIFICATION_CONTROLS = [
    {
        isVisible: ({ isMultiplayerGame }) => !isMultiplayerGame && hasPermission(CONDITIONS.CAN_USE_GAMIFICATION),
        tab: {
            label: i18n.t('Gamification'),
            isActive: ({ blockData }) => blockData[SCHEMA_KEY.isEnableRating] || blockData[SCHEMA_KEY.enableTimer],
            icon: tabGamificationIcon,
        },
        values: [
            {
                value: SCHEMA_KEY.isEnableRating,
            },
            {
                isVisible: ({ blockData }) => blockData[SCHEMA_KEY.isEnableRating],
                value: SCHEMA_KEY.isHideLeaderboard,
            },
            {
                isVisible: ({ blockData }) => blockData[SCHEMA_KEY.isEnableRating],
                value: SCHEMA_KEY.numberOfAttempts,
            },
            {
                isVisible: ({ blockData }) => blockData[SCHEMA_KEY.isEnableRating],
                tooltipText: `Example: <a href="https://interacty.me">Terms of use</a>`,
                value: SCHEMA_KEY.legalStatement,
            },
            {
                isVisible: ({ blockData }) => blockData[SCHEMA_KEY.isEnableRating],
                isDisabled: ({ blockData, appData }) => {
                    if (appData[SCHEMA_KEY.isUseAuthorization]) return true
                    if (blockData[SCHEMA_KEY.isShowLeadForm]) return true
                    return false
                },
                getValue: ({ blockData, appData }) => {
                    if (appData[SCHEMA_KEY.isUseAuthorization]) return true
                    if (blockData[SCHEMA_KEY.isShowLeadForm]) return true
                    return undefined
                },
                value: SCHEMA_KEY.isShowRatingEmail,
            },
            {
                isDisabled: ({ blockData }) => blockData[SCHEMA_KEY.isEnableRating],
                getValue: ({ blockData }) => {
                    if (blockData.isEnableRating) return true
                    return undefined
                },
                value: SCHEMA_KEY.enableTimer,
            },
            {
                isVisible: ({ blockData }) => blockData[SCHEMA_KEY.enableTimer],
                value: SCHEMA_KEY.timerType,
            },
            {
                isVisible: ({ blockData }) =>
                    blockData[SCHEMA_KEY.enableTimer] &&
                    blockData[SCHEMA_KEY.timerType] &&
                    blockData[SCHEMA_KEY.timerType].value === 'countdown',
                value: SCHEMA_KEY.countdownTime,
            },
            {
                isVisible: ({ blockData }) =>
                    blockData[SCHEMA_KEY.enableTimer] &&
                    blockData[SCHEMA_KEY.timerType] &&
                    blockData[SCHEMA_KEY.timerType].value === 'countdown',
                value: SCHEMA_KEY.timeIsUpScreen,
            },
            {
                isVisible: ({ blockData }) =>
                    blockData[SCHEMA_KEY.enableTimer] &&
                    blockData[SCHEMA_KEY.timerType] &&
                    blockData[SCHEMA_KEY.timerType].value === 'stopwatch',
                getValue: ({ blockData }) =>
                    !blockData[SCHEMA_KEY.enableTimer] ||
                    (blockData[SCHEMA_KEY.timerType] && blockData[SCHEMA_KEY.timerType].value !== 'stopwatch')
                        ? false
                        : undefined,
                value: SCHEMA_KEY.enableStars,
            },
            {
                isVisible: ({ blockData }) => blockData[SCHEMA_KEY.enableStars],
                descriptionText: i18n.t(`You can specify several separated by commas: 45, 30, 15`),
                value: SCHEMA_KEY.starsTimeList,
            },
        ],
    },
]
const ACTION_BUTTON_CONTROLS = [
    {
        tab: {
            label: i18n.t('Action Button'),
            isActive: ({ blockData }) => blockData[SCHEMA_KEY.isActionButton],
            icon: tabActionButtonIcon,
        },
        values: [
            { value: SCHEMA_KEY.isActionButton },
            {
                isVisible: ({ blockData }) => !!blockData[SCHEMA_KEY.isActionButton],
                value: SCHEMA_KEY.actionButtonText,
            },
            {
                isVisible: ({ blockData }) => !!blockData[SCHEMA_KEY.isActionButton],
                value: SCHEMA_KEY.actionButtonLink,
            },
        ],
    },
]
const OLD_ACTION_BUTTON_CONTROLS = [
    {
        tab: {
            label: i18n.t('Action Button'),
            isActive: ({ blockData }) => blockData[SCHEMA_KEY.callToActionEnabled],
            icon: tabActionButtonIcon,
        },
        values: [
            { value: SCHEMA_KEY.callToActionEnabled },
            {
                isVisible: ({ blockData }) => !!blockData[SCHEMA_KEY.callToActionEnabled],
                value: SCHEMA_KEY.callToActionText,
            },
            {
                isVisible: ({ blockData }) => !!blockData[SCHEMA_KEY.callToActionEnabled],
                value: SCHEMA_KEY.callToActionLink,
            },
        ],
    },
]
const PINS_CONTROLS = [
    {
        values: [{ value: SCHEMA_KEY.h }, { value: SCHEMA_KEY.d }],
    },
    {
        values: [{ value: SCHEMA_KEY.i }],
    },
    {
        values: [{ value: SCHEMA_KEY.blink }, { value: SCHEMA_KEY.btext }],
    },
]
const RESTART_CONTROLS = [
    {
        isVisible: ({ isMultiplayerGame }) => !isMultiplayerGame,
        values: [{ value: SCHEMA_KEY.isHideRestartButton }],
    },
]
const YANDEX_METRIC_CONTROLS = [
    {
        isVisible: ({ integrations }) => {
            if (!integrations?.yandexMetric?.id) return false
            const { paymentCurrency } = store.getState()
            return paymentCurrency === CURRENCIES.RUB
        },
        values: [{ value: SCHEMA_KEY.yandexMetric }],
    },
]

export const CONTROLS = {
    app: [
        {
            isVisible: ({ isMultiplayerGame }) => !isMultiplayerGame && hasPermission(CONDITIONS.CAN_USE_AUTHORIZATION),
            tab: {
                label: i18n.t('Authorization'),
                icon: tabAuthorizationIcon,
                isNew: true,
                isActive: ({ blockData }) => blockData[SCHEMA_KEY.isUseAuthorization],
            },
            values: [
                { value: SCHEMA_KEY.isUseAuthorization },
                {
                    isVisible: ({ blockData }) => blockData[SCHEMA_KEY.isUseAuthorization],
                    value: SCHEMA_KEY.authorizationType,
                },
                {
                    isVisible: ({ blockData }) => blockData[SCHEMA_KEY.isUseAuthorization],
                    value: SCHEMA_KEY.authorizationTokenLifetime,
                },
            ],
        },
        {
            isVisible: ({ isMultiplayerGame }) => !isMultiplayerGame,
            tab: {
                label: i18n.t('Basic'),
                icon: tabBasicIcon,
                isOpenByDefault: true,
            },
            values: [
                {
                    tooltipText: i18n.t(`Value in pixels (px). Applies in preview mode and in the published project`),
                    value: SCHEMA_KEY.maxWidth,
                },
                {
                    isVisible: ({ totalPagesCount }) => totalPagesCount > 1,
                    value: SCHEMA_KEY.isPresentationMode,
                },
                {
                    isVisible: ({ blockData }) => !!blockData[SCHEMA_KEY.isPresentationMode],
                    value: SCHEMA_KEY.pageProportions,
                },
            ],
        },
        {
            tab: {
                label: i18n.t('Design'),
                icon: tabDesignIcon,
            },
            values: [
                {
                    getValue: ({ blockData }) => {
                        if (blockData[SCHEMA_KEY.backgroundImage]) return true
                        return undefined
                    },
                    isVisible: ({ isMultiplayerGame, blockData }) =>
                        !isMultiplayerGame && !blockData[SCHEMA_KEY.backgroundImage],
                    tooltipText: i18n.t(`Applies in preview mode and in the published project`),
                    value: SCHEMA_KEY.isTransparentBackground,
                },
                {
                    isVisible: ({ isMultiplayerGame, blockData }) =>
                        !isMultiplayerGame && !blockData[SCHEMA_KEY.isTransparentBackground],
                    value: SCHEMA_KEY.bg,
                },
                {
                    isVisible: ({ isMultiplayerGame }) => !isMultiplayerGame,
                    value: SCHEMA_KEY.backgroundImage,
                },
                {
                    isVisible: ({ isMultiplayerGame }) => isMultiplayerGame,
                    value: SCHEMA_KEY.colorTheme,
                },
                {
                    isVisible: ({ isMultiplayerGame }) => isMultiplayerGame,
                    value: SCHEMA_KEY.multiplayerBackgroundImage,
                },
            ],
        },
        {
            tab: {
                label: i18n.t('Advanced'),
                icon: tabAdvancedIcon,
            },
            values: [
                {
                    value: SCHEMA_KEY.isOpenLinksInNewTab,
                    tooltipText: i18n.t(
                        `This setting does not allow opening new tabs during automatic redirection due to browser security policies. Only links are opened in a new tab with mouse click`,
                    ),
                },
                {
                    tooltipText: i18n.t(`Read the instructions on how it works`),
                    tooltipCallback: () => {
                        window.open(
                            'https://help.interacty.me/en/content/how-can-i-add-different-languages-into-my-project',
                        )
                    },
                    value: SCHEMA_KEY.customTranslations,
                },
                {
                    isVisible: () => hasPermission(CONDITIONS.CAN_USE_CUSTOM_PROJECT_STYLES),
                    value: SCHEMA_KEY.customStyles,
                },
            ],
        },
    ],
    blocks: {
        [BLOCK.text]: [
            {
                values: [
                    {
                        value: SCHEMA_KEY.isTransparentBackground,
                    },
                    {
                        isVisible: ({ blockData }) => !blockData[SCHEMA_KEY.isTransparentBackground],
                        value: SCHEMA_KEY.wP_bg,
                    },
                ],
            },
        ],
        [BLOCK.article]: [
            {
                values: [
                    {
                        value: SCHEMA_KEY.isTransparentBackground,
                    },
                    {
                        isVisible: ({ blockData }) => !blockData[SCHEMA_KEY.isTransparentBackground],
                        value: SCHEMA_KEY.backgroundColor,
                    },
                ],
            },
        ],
        [BLOCK.image]: [
            {
                values: [
                    { value: SCHEMA_KEY.url },
                    { value: SCHEMA_KEY.imageSize },
                    {
                        isVisible: ({ blockData }) =>
                            blockData[SCHEMA_KEY.imageSize].value === IMAGE_SIZES.custom.value,
                        value: SCHEMA_KEY.width,
                    },
                    {
                        isVisible: ({ blockData }) =>
                            blockData[SCHEMA_KEY.imageSize].value === IMAGE_SIZES.custom.value,
                        value: SCHEMA_KEY.position,
                    },
                ],
            },
            {
                label: i18n.t('Effects'),
                values: [{ value: SCHEMA_KEY.blur }],
            },
        ],
        [BLOCK.logo]: [
            {
                values: [
                    { value: SCHEMA_KEY.url },
                    { value: SCHEMA_KEY.width },
                    { value: SCHEMA_KEY.padding },
                    { value: SCHEMA_KEY.position },
                ],
            },
            {
                values: [{ value: SCHEMA_KEY.link }],
            },
        ],
        [BLOCK.embedInteractyProject]: [
            {
                values: [{ value: SCHEMA_KEY.embedCode }],
            },
        ],
        [BLOCK.flipCards]: [
            {
                values: [{ value: SCHEMA_KEY.imageProportions }],
            },
            {
                values: [{ value: SCHEMA_KEY.frontSrc }, { value: SCHEMA_KEY.backSrc }],
            },
            {
                values: [{ value: SCHEMA_KEY.frontColor }, { value: SCHEMA_KEY.backColor }],
            },
        ],
        [BLOCK.youtubeVideo]: [
            {
                values: [{ value: SCHEMA_KEY.embedCode }],
            },
        ],
        [BLOCK.button]: [
            {
                values: [
                    { value: SCHEMA_KEY.href },
                    { value: SCHEMA_KEY.brad },
                    { value: SCHEMA_KEY.bc },
                    { value: SCHEMA_KEY.pulse },
                ],
            },
        ],
        [BLOCK.interactiveImage]: [
            {
                values: [{ value: SCHEMA_KEY.bimg }],
            },
            {
                values: [
                    { value: SCHEMA_KEY.count },
                    { value: SCHEMA_KEY.psize },
                    { value: SCHEMA_KEY.pimg },
                    { value: SCHEMA_KEY.pcl },
                ],
            },
            {
                values: [{ value: SCHEMA_KEY.btcolor }],
            },
        ],
        [BLOCK.interactiveImage + '.pins']: PINS_CONTROLS,
        [BLOCK.hiddenObjects]: [
            {
                values: [{ value: SCHEMA_KEY.bimg }, ...PRIVACY_GAMIFICATION_DEMO],
            },
            {
                values: [
                    { value: SCHEMA_KEY.count },
                    { value: SCHEMA_KEY.psize },
                    { value: SCHEMA_KEY.pimg },
                    { value: SCHEMA_KEY.pcl },
                ],
            },
            {
                values: [{ value: SCHEMA_KEY.btcolor }],
            },
            {
                values: [
                    {
                        value: SCHEMA_KEY.isShowCover,
                    },
                    {
                        isVisible: ({ blockData }) => blockData[SCHEMA_KEY.isShowCover],
                        value: SCHEMA_KEY.coverHeader,
                    },
                    {
                        isVisible: ({ blockData }) => blockData[SCHEMA_KEY.isShowCover],
                        value: SCHEMA_KEY.coverBtnText,
                    },
                ],
            },
            {
                collapsible: true,
                values: [{ value: SCHEMA_KEY.suct }, { value: SCHEMA_KEY.sucd }, { value: SCHEMA_KEY.sucImg }],
            },
            {
                values: [
                    { value: SCHEMA_KEY.isActionButton },
                    {
                        isVisible: ({ blockData }) => !!blockData[SCHEMA_KEY.isActionButton],
                        value: SCHEMA_KEY.sucBtext,
                    },
                    {
                        isVisible: ({ blockData }) => !!blockData[SCHEMA_KEY.isActionButton],
                        value: SCHEMA_KEY.sucBlink,
                    },
                ],
            },
            ...RESTART_CONTROLS,
            ...TIMER_CONTROLS,
            ...PASSWORD_LIST_CONTROLS,
            ...GAMIFICATION_CONTROLS,
            ...AUTO_REDIRECT_CONTROLS,
            ...LEAD_FORM_CONTROLS,
            ...YANDEX_METRIC_CONTROLS,
        ],
        [BLOCK.hiddenObjects + '.pins']: PINS_CONTROLS,
        [BLOCK.quiz]: [
            {
                values: [{ value: SCHEMA_KEY.colorTheme }, { value: SCHEMA_KEY.fontFamily }],
            },
            {
                values: [
                    { value: SCHEMA_KEY.progressBar },
                    {
                        isVisible: ({ isMultiplayerGame }) => !isMultiplayerGame,
                        value: SCHEMA_KEY.showScores,
                    },
                ],
            },
            {
                values: [
                    {
                        isVisible: ({ blockData }) => !blockData[SCHEMA_KEY.struct].questionBank?.bankId,
                        value: SCHEMA_KEY.shuffleQuestions,
                    },
                    {
                        value: SCHEMA_KEY.shuffleAnswers,
                    },
                    {
                        value: SCHEMA_KEY.notMarkCorrectAnswers,
                        tooltipText: i18n.t(`Do not mark correct answers after the user has answered a question`),
                    },
                ],
            },
            ...RESTART_CONTROLS,
            ...AUTO_REDIRECT_CONTROLS,
            ...LEAD_FORM_CONTROLS,
            ...OLD_ACTION_BUTTON_CONTROLS,
            ...YANDEX_METRIC_CONTROLS,
        ],
        [BLOCK.thenNow]: [
            {
                values: [{ value: SCHEMA_KEY.imageProportions }],
            },
            {
                values: [{ value: SCHEMA_KEY.leftSrc }, { value: SCHEMA_KEY.rightSrc }],
            },
        ],
        [BLOCK.timeline]: [
            {
                values: [
                    {
                        value: SCHEMA_KEY.isTransparentBackground,
                    },
                    {
                        isVisible: ({ blockData }) => !blockData[SCHEMA_KEY.isTransparentBackground],
                        value: SCHEMA_KEY.wP_bg,
                    },
                    { value: SCHEMA_KEY.imageUrl },
                    { value: SCHEMA_KEY.imageDescription },
                ],
            },
        ],
        [BLOCK.memoryCards]: [
            {
                values: [
                    {
                        value: SCHEMA_KEY.colorTheme,
                    },
                ],
            },
            {
                values: [
                    {
                        value: SCHEMA_KEY.numberedCardBacks,
                    },
                    {
                        isVisible: ({ blockData }) => blockData[SCHEMA_KEY.numberedCardBacks],
                        value: SCHEMA_KEY.numberedCardBacksType,
                    },
                ],
            },
            {
                values: [
                    {
                        value: SCHEMA_KEY.showToMemorize,
                    },
                    {
                        isVisible: ({ blockData }) => blockData[SCHEMA_KEY.showToMemorize],
                        value: SCHEMA_KEY.showToMemorizeTime,
                    },
                ],
            },
            {
                values: [
                    {
                        value: SCHEMA_KEY.timeoutClosingCards,
                    },
                    ...PRIVACY_GAMIFICATION_DEMO,
                ],
            },
            ...RESTART_CONTROLS,
            ...TIMER_CONTROLS,
            ...PASSWORD_LIST_CONTROLS,
            ...GAMIFICATION_CONTROLS,
            ...ACTION_BUTTON_CONTROLS,
            ...AUTO_REDIRECT_CONTROLS,
            ...LEAD_FORM_CONTROLS,
            ...YANDEX_METRIC_CONTROLS,
            ...INTEGRATION_METADATA_CONTROLS,
        ],
        [BLOCK.cookies]: [
            {
                values: [
                    { value: SCHEMA_KEY.colorTheme },
                    { value: SCHEMA_KEY.isTransparentBackground },
                    {
                        isVisible: ({ blockData }) => !blockData[SCHEMA_KEY.isTransparentBackground],
                        value: SCHEMA_KEY.backgroundImage,
                    },
                ],
            },
            {
                values: [{ value: SCHEMA_KEY.fontFamily }],
            },
            {
                values: [{ value: SCHEMA_KEY.shuffleCards }],
            },
            ...RESTART_CONTROLS,
            ...LEAD_FORM_CONTROLS,
            ...OLD_ACTION_BUTTON_CONTROLS,
            ...AUTO_REDIRECT_CONTROLS,
            ...YANDEX_METRIC_CONTROLS,
        ],
        [BLOCK.horoscope]: [
            {
                values: [
                    { value: SCHEMA_KEY.colorTheme },
                    { value: SCHEMA_KEY.isTransparentBackground },
                    {
                        isVisible: ({ blockData }) => !blockData[SCHEMA_KEY.isTransparentBackground],
                        value: SCHEMA_KEY.backgroundImage,
                    },
                ],
            },
            {
                values: [{ value: SCHEMA_KEY.fontFamily }],
            },
            ...RESTART_CONTROLS,
            ...LEAD_FORM_CONTROLS,
            ...OLD_ACTION_BUTTON_CONTROLS,
            ...AUTO_REDIRECT_CONTROLS,
            ...YANDEX_METRIC_CONTROLS,
        ],
        [BLOCK.personalityQuiz]: [
            {
                values: [{ value: SCHEMA_KEY.colorTheme }, { value: SCHEMA_KEY.fontFamily }],
            },
            {
                values: [{ value: SCHEMA_KEY.progressBar }],
            },
            ...RESTART_CONTROLS,
            ...AUTO_REDIRECT_CONTROLS,
            ...LEAD_FORM_CONTROLS,
            ...OLD_ACTION_BUTTON_CONTROLS,
            ...YANDEX_METRIC_CONTROLS,
        ],
        [BLOCK.puzzle]: [
            {
                values: [
                    {
                        value: SCHEMA_KEY.colorTheme,
                    },
                    ...PRIVACY_GAMIFICATION_DEMO,
                ],
            },
            ...RESTART_CONTROLS,
            ...TIMER_CONTROLS,
            ...PASSWORD_LIST_CONTROLS,
            ...GAMIFICATION_CONTROLS,
            ...ACTION_BUTTON_CONTROLS,
            ...AUTO_REDIRECT_CONTROLS,
            ...LEAD_FORM_CONTROLS,
            ...YANDEX_METRIC_CONTROLS,
            ...INTEGRATION_METADATA_CONTROLS,
        ],
        [BLOCK.treasureHunt]: [
            {
                values: [
                    {
                        value: SCHEMA_KEY.colorTheme,
                    },
                ],
            },
            ...RESTART_CONTROLS,
            ...ACTION_BUTTON_CONTROLS,
            ...AUTO_REDIRECT_CONTROLS,
            ...LEAD_FORM_CONTROLS,
            ...YANDEX_METRIC_CONTROLS,
            ...INTEGRATION_METADATA_CONTROLS,
        ],
        [BLOCK.leadForm]: [
            {
                values: [
                    {
                        value: SCHEMA_KEY.backgroundColor,
                    },
                    {
                        value: SCHEMA_KEY.fontFamily,
                    },
                ],
            },
            ...ACTION_BUTTON_CONTROLS,
            ...AUTO_REDIRECT_CONTROLS,
        ],
        [BLOCK.findPair]: [
            {
                values: [
                    {
                        value: SCHEMA_KEY.colorTheme,
                    },
                    {
                        value: SCHEMA_KEY.backgroundImage,
                    },
                    ...PRIVACY_GAMIFICATION_DEMO,
                ],
            },
            ...RESTART_CONTROLS,
            ...TIMER_CONTROLS,
            ...PASSWORD_LIST_CONTROLS,
            ...GAMIFICATION_CONTROLS,
            ...LEAD_FORM_CONTROLS,
            ...ACTION_BUTTON_CONTROLS,
            ...AUTO_REDIRECT_CONTROLS,
            ...YANDEX_METRIC_CONTROLS,
            ...INTEGRATION_METADATA_CONTROLS,
        ],
        [BLOCK.slideshow]: [
            {
                values: [
                    {
                        value: SCHEMA_KEY.isTransparentBackground,
                    },
                    {
                        isVisible: ({ blockData }) => !blockData[SCHEMA_KEY.isTransparentBackground],
                        value: SCHEMA_KEY.colorTheme,
                    },
                ],
            },
            {
                values: [
                    {
                        value: SCHEMA_KEY.textColor,
                    },
                ],
            },
        ],
        [BLOCK.matching]: [
            {
                values: [
                    {
                        value: SCHEMA_KEY.colorTheme,
                    },
                    {
                        value: SCHEMA_KEY.backgroundImage,
                    },
                    ...PRIVACY_GAMIFICATION_DEMO,
                ],
            },
            {
                values: [{ value: SCHEMA_KEY.shuffleCards }],
            },
            ...RESTART_CONTROLS,
            ...TIMER_CONTROLS,
            ...PASSWORD_LIST_CONTROLS,
            ...GAMIFICATION_CONTROLS,
            ...ACTION_BUTTON_CONTROLS,
            ...AUTO_REDIRECT_CONTROLS,
            ...LEAD_FORM_CONTROLS,
            ...YANDEX_METRIC_CONTROLS,
            ...INTEGRATION_METADATA_CONTROLS,
        ],
        [BLOCK.audio]: [
            {
                values: [
                    { value: SCHEMA_KEY.url },
                    {
                        value: SCHEMA_KEY.isLoop,
                    },
                    {
                        value: SCHEMA_KEY.isAutoPlay,
                    },
                ],
            },
        ],
        [BLOCK.embedIframe]: [
            {
                values: [{ value: SCHEMA_KEY.embedCode }, { value: SCHEMA_KEY.height }],
            },
        ],
        [BLOCK.question]: [
            {
                values: [{ value: SCHEMA_KEY.colorTheme }, { value: SCHEMA_KEY.fontFamily }],
            },
        ],
        [BLOCK.wheelSpin]: [
            {
                values: [
                    { value: SCHEMA_KEY.colorTheme },
                    { value: SCHEMA_KEY.colorScheme },
                    { value: SCHEMA_KEY.animation },
                ],
            },
            {
                values: [{ value: SCHEMA_KEY.fontFamily }],
            },
            ...RESTART_CONTROLS,
            ...LEAD_FORM_CONTROLS,
            ...ACTION_BUTTON_CONTROLS,
            ...AUTO_REDIRECT_CONTROLS,
            ...YANDEX_METRIC_CONTROLS,
            ...INTEGRATION_METADATA_CONTROLS,
        ],
        [BLOCK.crossword]: [
            {
                values: [{ value: SCHEMA_KEY.colorTheme }, ...PRIVACY_GAMIFICATION_DEMO],
            },
            { values: [{ value: SCHEMA_KEY.fontFamily }, { value: SCHEMA_KEY.letterColor }] },
            {
                values: [
                    { value: SCHEMA_KEY.isMarkCorrectLetters },
                    {
                        isDisabled: ({ blockData }) => blockData[SCHEMA_KEY.isMarkCorrectLetters],
                        getValue: ({ blockData }) => {
                            if (blockData[SCHEMA_KEY.isMarkCorrectLetters]) return true
                            return undefined
                        },
                        value: SCHEMA_KEY.isMarkCorrectWords,
                    },
                    {
                        isVisible: ({ blockData }) =>
                            blockData[SCHEMA_KEY.isMarkCorrectLetters] || blockData[SCHEMA_KEY.isMarkCorrectWords],
                        value: SCHEMA_KEY.correctLetterColor,
                    },
                ],
            },
            ...RESTART_CONTROLS,
            ...TIMER_CONTROLS,
            ...PASSWORD_LIST_CONTROLS,
            ...GAMIFICATION_CONTROLS,
            ...LEAD_FORM_CONTROLS,
            ...ACTION_BUTTON_CONTROLS,
            ...AUTO_REDIRECT_CONTROLS,
            ...YANDEX_METRIC_CONTROLS,
            ...INTEGRATION_METADATA_CONTROLS,
        ],
        [BLOCK.slidingPuzzle]: [
            {
                values: [
                    { value: SCHEMA_KEY.colorTheme },
                    { value: SCHEMA_KEY.fontFamily },
                    ...PRIVACY_GAMIFICATION_DEMO,
                ],
            },
            {
                values: [
                    {
                        value: SCHEMA_KEY.isHighlightCorrect,
                    },
                    {
                        isVisible: ({ blockData }) => blockData[SCHEMA_KEY.isHighlightCorrect],
                        value: SCHEMA_KEY.correctColor,
                    },
                ],
            },
            {
                values: [{ value: SCHEMA_KEY.isShowShuffleButton }],
            },
            ...RESTART_CONTROLS,
            ...TIMER_CONTROLS,
            ...PASSWORD_LIST_CONTROLS,
            ...GAMIFICATION_CONTROLS,
            ...LEAD_FORM_CONTROLS,
            ...ACTION_BUTTON_CONTROLS,
            ...AUTO_REDIRECT_CONTROLS,
            ...YANDEX_METRIC_CONTROLS,
            ...INTEGRATION_METADATA_CONTROLS,
        ],
        [BLOCK.rankBattle]: [
            {
                values: [{ value: SCHEMA_KEY.colorTheme }, { value: SCHEMA_KEY.fontFamily }],
            },
            {
                values: [{ value: SCHEMA_KEY.isOneTimeVote }, { value: SCHEMA_KEY.isHideScoreBeforeVote }],
            },
            ...AUTO_REDIRECT_CONTROLS,
            ...YANDEX_METRIC_CONTROLS,
        ],
        [BLOCK.cover]: [
            {
                values: [
                    { value: SCHEMA_KEY.imageProportions },
                    { value: SCHEMA_KEY.imageUrl },
                    { value: SCHEMA_KEY.darkenBackground },
                ],
            },
            {
                values: [
                    { value: SCHEMA_KEY.isShowLogotype },
                    {
                        isVisible: ({ blockData }) => blockData[SCHEMA_KEY.isShowLogotype],
                        value: SCHEMA_KEY.logotypeUrl,
                    },
                    {
                        isVisible: ({ blockData }) => blockData[SCHEMA_KEY.isShowLogotype],
                        value: SCHEMA_KEY.logotypeScale,
                    },
                    {
                        isVisible: ({ blockData }) => blockData[SCHEMA_KEY.isShowLogotype],
                        value: SCHEMA_KEY.logotypePosition,
                    },
                ],
            },
            {
                values: [
                    { value: SCHEMA_KEY.isShowButton },
                    {
                        isVisible: ({ blockData }) => blockData[SCHEMA_KEY.isShowButton],
                        value: SCHEMA_KEY.buttonUrl,
                    },
                    {
                        isVisible: ({ blockData }) => blockData[SCHEMA_KEY.isShowButton],
                        value: SCHEMA_KEY.buttonBackgroundColor,
                    },
                    {
                        isVisible: ({ blockData }) => blockData[SCHEMA_KEY.isShowButton],
                        value: SCHEMA_KEY.buttonBorderRadius,
                    },
                    {
                        isVisible: ({ blockData }) => blockData[SCHEMA_KEY.isShowButton],
                        value: SCHEMA_KEY.buttonPosition,
                    },
                ],
            },
        ],
        [BLOCK.textAndImage]: [
            {
                values: [
                    {
                        value: SCHEMA_KEY.isTransparentBackground,
                    },
                    {
                        isVisible: ({ blockData }) => !blockData[SCHEMA_KEY.isTransparentBackground],
                        value: SCHEMA_KEY.backgroundColor,
                    },
                ],
            },
            {
                values: [{ value: SCHEMA_KEY.imageUrl }, { value: SCHEMA_KEY.textPosition }],
            },
            {
                values: [
                    { value: SCHEMA_KEY.isShowButton },
                    {
                        isVisible: ({ blockData }) => blockData[SCHEMA_KEY.isShowButton],
                        value: SCHEMA_KEY.buttonUrl,
                    },
                    {
                        isVisible: ({ blockData }) => blockData[SCHEMA_KEY.isShowButton],
                        value: SCHEMA_KEY.buttonBackgroundColor,
                    },
                    {
                        isVisible: ({ blockData }) => blockData[SCHEMA_KEY.isShowButton],
                        value: SCHEMA_KEY.buttonBorderRadius,
                    },
                ],
            },
        ],
        [BLOCK.authForm]: [
            {
                values: [
                    {
                        value: SCHEMA_KEY.backgroundColor,
                    },
                    {
                        value: SCHEMA_KEY.fontFamily,
                    },
                ],
            },
        ],
    },
}
