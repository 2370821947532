import React, {useState} from "react"
import classNames from "classnames"

// import arrow from './i/arrow.svg'

import styles from './Group.module.scss'

const Group = ({ group, children }) => {
    const [isShowControls, setIsShowControls] = useState(true)
    // const [isShowControls, setIsShowControls] = useState(group.tab?.isOpenByDefault || false)

    return (
        <li className={classNames(styles.groupBlock, {[styles.isDisabledGroupBlock]: group.isDisabled, [styles.isHasTabGroupBlock]: !!group.tab})}>
            {group.tab ? (
                <div className={styles.groupTab}>
                    <div className={styles.head} onClick={() => setIsShowControls(!isShowControls)}>
                        <div className={styles.headContent}>
                            {!!group.tab.icon && <img className={styles.headIcon} src={group.tab.icon} />}
                            <div className={styles.headLabel}>
                                {group.tab.label}
                            </div>
                            {group.tab.isNew && <div className={styles.headMark}>new</div>}
                        </div>
                        {/*<img className={classNames(styles.headArrow, { [styles.isRotatedArrow]: isShowControls  })} src={arrow} alt="arrow" />*/}
                    </div>
                    {/*{group.tab.isActive && <div className={styles.headActiveMark} />}*/}
                    <div className={classNames(styles.controls, { [styles.isVisibleControls]: isShowControls })}>{children}</div>
                </div>
            ) : children}
        </li>
    )
}

export default Group