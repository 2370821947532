import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import copy from 'copy-to-clipboard'
import { cloneDeep } from 'lodash'
import { useSelector } from 'react-redux'
import isValidJSON from 'validator/lib/isJSON'

import BLOCKS from 'shared/constants/blocks'

import { decodeStringsInObject, encodeStringsInObject } from 'common/utils/objectStringsAction'

import { getFullUrlToAdmin } from 'utils/router'
import { getTextFromClipboard } from 'utils/clipboard'

import Toast from 'components/Toast/Toast'

import { MIN_ITEMS_COUNT } from '../../Blocks/components/Modals/CrosswordModal/constants'
import { generateCrosswordLayout } from '../../Blocks/components/Modals/CrosswordModal/utils'
import { Item as CrosswordItemModel } from '../../Blocks/components/Modals/CrosswordModal/crosswordService'

import ArrowIcon from './svg/Arrow'
import NewTabIcon from './svg/NewTab'

import { findBlockInPagesById } from './utils'

import styles from './Devtools.module.scss'

const JSON_TYPES = {
    BLOCK: 'BLOCK',
    PROJECT: 'PROJECT',
}

const ItemLink = ({ url }) => (
    <a className={styles.infoItemLink} href={url} target="_blank" title="Open in new tab">
        <NewTabIcon />
    </a>
)

const Devtools = ({ selectedBlockJson, tmpProject, projectJson, onChangeProject, onAddBlock }) => {
    const [isOpened, setIsOpened] = useState(false)

    const { isDevMode } = useSelector(state => state)

    useEffect(() => {
        window.getProjectStructure = () => projectJson
        window.setProjectStructure = (newStructure, path = '') => onChangeProject(newStructure, path || '')

        window.updateCrosswordItems = (blockId, items, seed = 0) => {
            if (!blockId) return new Error(`"blockId" must be specified`)

            if (!items) return new Error(`"items" blockId must be specified`)
            if (items.length < MIN_ITEMS_COUNT) return new Error(`"items" count must be >= ${MIN_ITEMS_COUNT}`)
            if (!items.every(item => !!item.answer && !!item.question))
                return new Error(`Bad item format. Example: [{ answer: 'Word', question: 'Question for word' }, ...]`)

            const searchedBlock = findBlockInPagesById(projectJson, blockId)
            if (!searchedBlock) return new Error(`Cannot find block by specified "blockId"`)
            if (searchedBlock.block.t !== BLOCKS.crossword) return new Error(`Block must be crossword`)

            const newItems = []
            items.forEach(item => {
                newItems.push(new CrosswordItemModel({ answer: item.answer, question: item.question }))
            })

            const generatedItems = generateCrosswordLayout(newItems, { seed, isSkipBadWords: true })
            if (generatedItems.length < MIN_ITEMS_COUNT)
                return new Error(`"items" count after layout generation must be >= ${MIN_ITEMS_COUNT}`)

            const updatedBlock = {
                ...searchedBlock.block,
                struct: {
                    ...searchedBlock.block.struct,
                    items: generatedItems,
                },
            }

            onChangeProject(updatedBlock, `pages[${searchedBlock.pageIndex}].blocks[${searchedBlock.blockIndex}]`)
        }

        return () => {
            window.getProjectStructure = undefined
            window.setProjectStructure = undefined

            window.updateCrosswordItems = undefined
        }
    }, [projectJson])

    const validateBlock = text => {
        try {
            const isJson = isValidJSON(text)
            if (!isJson) return null
            const json = JSON.parse(text)
            if (json.hasOwnProperty('t')) return json
            return null
        } catch (err) {
            return null
        }
    }

    const onCopyJSON = type => {
        switch (type) {
            case JSON_TYPES.BLOCK: {
                copy(JSON.stringify(encodeStringsInObject(cloneDeep(selectedBlockJson))))
                Toast('success', { title: 'Block JSON copied to clipboard!' })
                break
            }
            case JSON_TYPES.PROJECT: {
                copy(JSON.stringify(encodeStringsInObject(cloneDeep(projectJson))))
                Toast('success', { title: 'Project JSON copied to clipboard!' })
                break
            }
            default:
                break
        }
    }
    const onAddFromClipboard = async () => {
        const clipboardData = await getTextFromClipboard()
        if (!clipboardData.isSuccess) {
            Toast('error', { title: 'Error', message: 'Clipboard read permission denied. Check browser settings.' })
            return
        }
        const validBlock = validateBlock(clipboardData.text)
        if (!validBlock) {
            Toast('error', { title: 'Error', message: 'Invalid block JSON' })
            return
        }
        if ([BLOCKS.authForm].includes(validBlock.t)) {
            Toast('error', { title: 'Error', message: `Block with type ID "${validBlock.t}" cannot be add` })
            return
        }
        onAddBlock(validBlock.t, null, decodeStringsInObject(validBlock))
        Toast('success', { title: 'New block added from clipboard!' })
    }

    if (!isDevMode) return null

    return (
        <div className={classNames(styles.devtools, { [styles.isOpenedDevtools]: isOpened })}>
            <div className={styles.tongue} onClick={() => setIsOpened(prevState => !prevState)}>
                <ArrowIcon />
            </div>
            <div className={styles.content}>
                <div className={styles.contentBox}>
                    <ul className={styles.info}>
                        <li className={styles.infoItem}>
                            <span className={styles.infoItemTitle}>Author:</span> {tmpProject.author.email}{' '}
                            <ItemLink url={getFullUrlToAdmin(`/users/${tmpProject.author.userId}`)} />
                        </li>
                        <li className={styles.infoItem}>
                            <span className={styles.infoItemTitle}>Organization ID:</span> {tmpProject.organizationId}{' '}
                            <ItemLink url={getFullUrlToAdmin(`/organizations/${tmpProject.organizationId}`)} />
                        </li>
                        <li className={styles.infoItem}>
                            <span className={styles.infoItemTitle}>Template ID:</span> {tmpProject.templateId}{' '}
                            <ItemLink
                                url={getFullUrlToAdmin(
                                    `/template-gallery/interacty-templates?id=${tmpProject.templateId}`,
                                )}
                            />
                        </li>
                        <li className={styles.infoItem}>
                            <span className={styles.infoItemTitle}>Project ID:</span> {tmpProject.id}{' '}
                            <ItemLink url={getFullUrlToAdmin(`/projects?id=${tmpProject.id}`)} />
                        </li>
                        <li className={styles.infoItem}>
                            <span className={styles.infoItemTitle}>Project mode:</span> {tmpProject.mode}
                        </li>
                    </ul>
                    <div className={styles.actions}>
                        <div className={styles.actionGroup}>
                            <div className={styles.actionGroupTitle}>Block</div>
                            <div className={styles.actionGroupContent}>
                                <div
                                    onClick={() => onCopyJSON(JSON_TYPES.BLOCK)}
                                    className={classNames(styles.action, {
                                        [styles.isDisabledAction]: !selectedBlockJson,
                                    })}
                                >
                                    Copy JSON
                                </div>
                                <div onClick={() => onAddFromClipboard()} className={classNames(styles.action)}>
                                    Add from clipboard
                                </div>
                            </div>
                        </div>
                        <div className={styles.actionGroup}>
                            <div className={styles.actionGroupTitle}>Project</div>
                            <div className={styles.actionGroupContent}>
                                <div onClick={() => onCopyJSON(JSON_TYPES.PROJECT)} className={styles.action}>
                                    Copy JSON
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Devtools
