import React from 'react'
import ReactSelect from 'react-select'

import Tooltip from 'components/Tooltip/Tooltip'

class Select extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    changeValue = value => {
        const { onUpdate, name, id } = this.props

        onUpdate(id, { [name]: value })
    }

    render() {
        const {
            tooltipText,
            value,
            schema: { label = '', payload },
        } = this.props

        const styles = {
            control: (provided, state) => ({
                height: '32px',
                borderRadius: '4px',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: state.isFocused ? '#69B1FC' : '#D8D8D8',
                backgroundColor: '#fff',
                boxShadow: state.isFocused ? 'inset 0 0 0 1px #69b1fc' : 'none',
                display: 'flex',
                alignItems: 'center',
                boxSizing: 'border-box',
                justifyContent: 'space-between',
                cursor: 'pointer',
            }),
            valueContainer: () => ({
                padding: 0,
                width: '100%',
                boxSizing: 'border-box',
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '8px',
                overflow: 'hidden',
            }),
            singleValue: (provided, state) => {
                const useValueFontFamily = payload.useValueAsFontFamily && state.hasValue

                return {
                    fontFamily: useValueFontFamily ? `${state.data.value}, sans-serif` : "'Roboto', sans-serif",
                    fontSize: '14px',
                    color: '#3C3C3C',
                    maxWidth: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }
            },
            indicatorsContainer: () => ({
                flexShrink: 0,
                padding: '0 5px',
            }),
            indicatorSeparator: () => ({
                display: 'none',
            }),
            dropdownIndicator: () => ({
                padding: 0,
            }),

            menuList: provided => ({
                ...provided,
                maxHeight: 200,
                overflow: 'auto',
            }),
            menu: provided => ({
                ...provided,
                backgroundColor: '#fff',
                boxShadow: '0 2px 20px -4px rgba(0,0,0,.25)',
                borderRadius: '4px',
                top: '100%',
                left: 0,
                margin: '4px 0 0 0',
            }),
            option: (provided, state) => {
                const useValueFontFamily = payload.useValueAsFontFamily && state.hasValue

                const styles = {
                    ...provided,
                    cursor: 'pointer',
                    backgroundColor: '#fff',
                    padding: '8px 7px',
                    fontFamily: useValueFontFamily ? `${state.data.value}, sans-serif` : "'Roboto', sans-serif",
                    fontSize: '14px',
                    color: '#3C3C3C',
                    '&:hover': {
                        backgroundColor: '#eee',
                    },
                }

                if (state.isSelected) {
                    styles.pointerEvents = 'none'
                    styles.backgroundColor = '#69B1FC'
                    styles.color = '#fff'
                }

                return styles
            },
        }

        const _value = !!payload.getOptionFromValue ? payload.getOptionFromValue(value) : value

        return (
            <div className="control-box">
                <p className="label">
                    {label}
                    {<Tooltip tooltipText={tooltipText} />}
                </p>
                <div className="content select">
                    <ReactSelect
                        isClearable={false}
                        isSearchable={false}
                        maxMenuHeight={200}
                        options={payload.items}
                        value={_value}
                        styles={styles}
                        onChange={option => {
                            if (!!payload.setValueFromOption) this.changeValue(payload.setValueFromOption(option))
                            else this.changeValue(option)
                        }}
                    />
                </div>
            </div>
        )
    }
}

export default Select
