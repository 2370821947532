import React from 'react'

import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'
import Image from 'components/Form/Image'
import TextArea from 'components/Form/TextArea/TextArea'
import Toggle from 'components/Form/Toggle/Toggle'
import TextInput from 'components/Form/TextInput/TextInput'
import UploadImage from 'components/Form/UploadImage/UploadImage'
import ElementsGroup from 'components/Form/ElementsGroup/ElementsGroup'

import invertColor from '../../../../../../util/invertColor'
import { QuizCover } from '../../../quizModels'

import './StartView.scss'

const StartView = ({ t, colorTheme, validationResults = {}, tmpStructure, methods, fontFamily }) => (
    <div className="personality-start-screen">
        <div className="personality-start-screen__workplace-wrapper">
            <ScrollableBlock>
                <div className="personality-start-screen__workplace">
                    <h3 className="personality-start-screen__workplace-title">
                        {t('Start screen')}
                        <div className="personality-start-screen__workplace-title-toggle">
                            <Toggle
                                value={tmpStructure.cover.isShowCover}
                                onUpdate={value =>
                                    methods.onUpdateCover({
                                        [QuizCover.isShowCover]: value,
                                    })
                                }
                            />
                        </div>
                    </h3>
                    <div className="personality-start-screen__workplace-content">
                        <TextInput
                            label={t('Header')}
                            isRequired
                            isDisabled={!tmpStructure.cover.isShowCover}
                            value={tmpStructure.cover.header}
                            errorMessages={validationResults.header}
                            onUpdate={value =>
                                methods.onUpdateCover({
                                    [QuizCover.header]: value,
                                })
                            }
                        />
                        <TextArea
                            label={t('Description')}
                            value={tmpStructure.cover.description}
                            isDisabled={!tmpStructure.cover.isShowCover}
                            errorMessages={validationResults.description}
                            onUpdate={value =>
                                methods.onUpdateCover({
                                    [QuizCover.description]: value,
                                })
                            }
                        />
                        <ElementsGroup>
                            <UploadImage
                                label={t('Cover image')}
                                value={tmpStructure.cover.image}
                                isDisabled={!tmpStructure.cover.isShowCover}
                                onUpdate={value =>
                                    methods.onUpdateCover({
                                        [QuizCover.image]: value,
                                    })
                                }
                            />
                            <TextInput
                                isRequired
                                label={t('Button text')}
                                isDisabled={!tmpStructure.cover.isShowCover}
                                value={tmpStructure.cover.buttonText}
                                errorMessages={validationResults.buttonText}
                                onUpdate={value =>
                                    methods.onUpdateCover({
                                        [QuizCover.buttonText]: value,
                                    })
                                }
                            />
                        </ElementsGroup>

                        <TextInput
                            label={t('Image disclaimer')}
                            isDisabled={!tmpStructure.cover.isShowCover}
                            isOptional
                            value={tmpStructure.cover.imageDisclaimer}
                            errorMessages={validationResults.imageDisclaimer}
                            onUpdate={value => methods.onUpdateCover({ [QuizCover.imageDisclaimer]: value })}
                        />
                    </div>
                </div>
            </ScrollableBlock>
        </div>
        <div className="personality-start-screen__preview-wrapper">
            <ScrollableBlock>
                <div className="personality-start-screen__preview">
                    <p className="personality-start-screen__preview-title">{t('Approximate preview')}</p>
                    {tmpStructure.cover.image ||
                    tmpStructure.cover.header.length ||
                    tmpStructure.cover.description.length ||
                    tmpStructure.cover.imageDisclaimer.length ||
                    tmpStructure.cover.buttonText.length ? (
                        <div className="personality-start-screen__preview-content">
                            {tmpStructure.cover.image && (
                                <Image
                                    className="personality-start-screen__preview-image"
                                    src={tmpStructure.cover.image}
                                    alt="img"
                                />
                            )}
                            <div className="personality-start-screen__preview-header" style={{ fontFamily }}>
                                {tmpStructure.cover.header}
                            </div>
                            <div className="personality-start-screen__preview-description" style={{ fontFamily }}>
                                {tmpStructure.cover.description}
                            </div>
                            {!!tmpStructure.cover.buttonText.length && (
                                <button
                                    className="personality-start-screen__preview-btn"
                                    style={{
                                        backgroundColor: colorTheme,
                                        color: invertColor(colorTheme, true),
                                        fontFamily,
                                    }}
                                >
                                    {tmpStructure.cover.buttonText}
                                </button>
                            )}
                            <div className="personality-start-screen__preview-image-disclaimer" style={{ fontFamily }}>
                                {tmpStructure.cover.imageDisclaimer}
                            </div>
                        </div>
                    ) : (
                        <p className="personality-start-screen__preview-empty">
                            {t('You have not entered any data yet')}
                        </p>
                    )}
                </div>
            </ScrollableBlock>
        </div>
    </div>
)

export default StartView
