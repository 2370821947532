import { cloneDeep } from 'lodash'

import { API__WEBHOOKS } from 'api'
import store from 'store'

import { moveInArray } from "utils/common"

import { default as PageModel } from '../models/PageModel'

const addItem = pages => {
    const newPages = cloneDeep(pages)
    const newPage = new PageModel()
    newPages.push(newPage)
    return { newPages, newPage }
}

const moveItem = (pages, currentIndex, newIndex) => {
    const newPages = cloneDeep(pages)

    return {
        newPages: moveInArray(newPages, currentIndex, newIndex),
    }
}

const deleteItem = async (pages, pageId, projectId) => {
    const {
        organizations: { selectedOrganizationId },
    } = store.getState()
    const index = pages.findIndex(p => p.id === pageId)
    const newPages = cloneDeep(pages)
    const deletingPage = newPages[index]
    newPages.splice(index, 1)
    const pagesWithoutLink = JSON.parse(JSON.stringify(newPages).replace(new RegExp(`#${pageId}`, 'g'), ''))

    const blockIds = deletingPage.blocks.map(block => block.id)
    await API__WEBHOOKS.DELETE_BLOCKS_WEBHOOKS({ organizationId: selectedOrganizationId, blockIds, projectId })
    return { newPages: pagesWithoutLink, index }
}

const cloneItem = async (pages, pageId) => {
    const pageIndex = pages.findIndex(el => el.id === pageId)
    const _arr = cloneDeep(pages)
    const sourcePage = _arr[pageIndex]
    const clonedItem = new PageModel({ ...sourcePage })
    _arr.splice(pageIndex + 1, 0, clonedItem)
    return { newPages: _arr, clonedPage: clonedItem }
}

function getPageById(pages, pageId) {
    const resultPage = pages.find(page => page.id === pageId)
    if (!resultPage) throw Error('Page not found with id: ' + pageId)
    return resultPage
}

export default {
    addItem,
    moveItem,
    deleteItem,
    cloneItem,
    getPageById,
}
