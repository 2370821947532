import {useEffect, useState} from "react"

const useClickOutside = (containerRef, callback = () => {}) => {
    const [mouseDownTarget, setMouseDownTarget] = useState(null)
    const [mouseUpTarget, setMouseUpTarget] = useState(null)

    const handleMouseDown = evt => {
        setMouseDownTarget(evt.target)
    }
    const handleMouseUp = evt => {
        setMouseUpTarget(evt.target)
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleMouseDown)
        document.addEventListener('mouseup', handleMouseUp)

        return () => {
            document.removeEventListener('mousedown', handleMouseDown)
            document.removeEventListener('mouseup', handleMouseUp)
        }
    }, [])

    useEffect(() => {
        if (!containerRef?.current) return

        if (!containerRef.current.contains(mouseDownTarget) && !containerRef.current.contains(mouseUpTarget)) {
            callback()
        }
    }, [mouseUpTarget])

    return null
}

export default useClickOutside