import React, {useState, useCallback, useContext, useEffect} from 'react'
import { useTranslation } from 'react-i18next'
import { cloneDeep } from 'lodash'

import Modal from 'components/Modal/Modal'
import Header from 'components/Modal/components/Header/Header'
import Body from 'components/Modal/components/Body/Body'

import { ProjectContext } from 'pages/Editor/contexts/projectContext'

import { compareArrays } from 'utils/common'

import StopPanels, { useStopPanes, VALIDATION_WARNING_TYPES } from '../components/StopPanels/StopPanels'

import Items from './views/Items/Items'
import Final from './views/Final/Final'

import InvalidWordsModal from './components/InvalidWordsModal/InvalidWordsModal'

import { TABS, TABS_ARRAY } from './constants'
import { createCrosswordBlock, BLOCK_KEYS, BLOCK_KEYS_BY_TABS, Item, Item as ItemModel } from './crosswordService'
import { validateTabs } from './validation'

import { generateCrosswordLayout } from './utils'

import styles from './CrosswordModal.module.scss'

const CrosswordModal = ({ methods, data: { structure, colorTheme, id } }) => {
    const { t } = useTranslation()
    const [tmpStructure, setTmpStructure] = useState(
        Object.keys(structure).length === 0 ? createCrosswordBlock() : cloneDeep(structure),
    )
    const [activeTab, setActiveTab] = useState(TABS.ITEMS)
    const [errors, setErrors] = useState({})

    const [scrollableItem, setScrollableItem] = useState(null)
    const [invalidItems, setInvalidItems] = useState([])
    const [isOpenedInvalidWordsModal, setIsOpenedInvalidWordsModal] = useState(false)

    const [lastRecalculatedItemsAnswers, setLastRecalculatedItemsAnswers] = useState(() => tmpStructure[BLOCK_KEYS.ITEMS].map(item => item.answer))
    const [isNeedToRecalculateItems, setIsNeedToRecalculateItems] = useState(false)

    const {
        isShowQuitAccept,
        onShowQuitAccept,
        validationWarningType,
        setValidationWarningType,
        onClosePanels,
    } = useStopPanes()

    const { isMultiplayerGame } = useContext(ProjectContext)

    useEffect(() => {
        const answersArray = tmpStructure[BLOCK_KEYS.ITEMS].map(item => item.answer)
        setIsNeedToRecalculateItems(!compareArrays(answersArray, lastRecalculatedItemsAnswers))
    }, [tmpStructure[BLOCK_KEYS.ITEMS]])

    const getUpdatedStructure = async () => {
        try {
            if (!isNeedToRecalculateItems) return tmpStructure
            const newTmpStructure = cloneDeep(tmpStructure)
            newTmpStructure[BLOCK_KEYS.ITEMS] = getRecalculatedItems()
            setTmpStructure(newTmpStructure)
            return newTmpStructure
        } catch (err) {
            throw err
        }
    }

    const getRecalculatedItems = () => {
        const newItems = generateCrosswordLayout(tmpStructure[BLOCK_KEYS.ITEMS])

        setLastRecalculatedItemsAnswers(newItems.map(item => item.answer))
        return newItems
    }

    const onSave = async () => {
        try {
            const updatedStructure = await getUpdatedStructure()
            const validationResult = validateTabs(updatedStructure)
            if (!validationResult.isHasError) {
                const isValidWords = validateWords(updatedStructure)
                if (!isValidWords) return
                await methods.save({ [id]: { data: { struct: updatedStructure } } })
                methods.closeModal()
            } else {
                setErrors(validationResult.errors)
                setValidationWarningType(VALIDATION_WARNING_TYPES.COMMON)
            }
        } catch (err) {
            console.error(err)
        }
    }

    const onUpdateFinal = data => {
        if (errors[BLOCK_KEYS.FINAL]) {
            const _errors = {}
            Object.keys(data).forEach(key => (_errors[key] = []))
            setErrors(prevState => ({
                ...prevState,
                [BLOCK_KEYS.FINAL]: {
                    ...prevState[BLOCK_KEYS.FINAL],
                    ..._errors,
                },
            }))
        }
        setTmpStructure(prevState => ({
            ...prevState,
            [BLOCK_KEYS.FINAL]: {
                ...prevState[BLOCK_KEYS.FINAL],
                ...data,
            },
        }))
    }
    const onUpdateItem = (itemIndex, itemId, data) => {
        if (errors[BLOCK_KEYS.ITEMS]) {
            const _errors = {}
            Object.keys(data).forEach(key => (_errors[key] = []))
            const itemsErrors = cloneDeep(errors[BLOCK_KEYS.ITEMS])
            itemsErrors.set(itemId, {
                ...itemsErrors.get(itemId),
                ..._errors,
            })
            setErrors(prevState => ({
                ...prevState,
                [BLOCK_KEYS.ITEMS]: itemsErrors,
            }))
        }

        const items = cloneDeep(tmpStructure[BLOCK_KEYS.ITEMS])
        items[itemIndex] = {
            ...items[itemIndex],
            ...data,
        }

        setTmpStructure(prevState => ({
            ...prevState,
            [BLOCK_KEYS.ITEMS]: items,
        }))
    }
    const onUpdateFromAI = data => {
        const newItems = []
        data.forEach(({ question, answer }) => {
            newItems.push(new Item({ question, answer }))
        })

        setTmpStructure(prevState => ({
            ...prevState,
            [BLOCK_KEYS.ITEMS]: newItems,
        }))
    }

    const onAddItem = () => {
        setTmpStructure(prevState => ({
            ...prevState,
            [BLOCK_KEYS.ITEMS]: [...prevState[BLOCK_KEYS.ITEMS], new Item()],
        }))
    }
    const onRemoveItem = itemId => {
        setTmpStructure(prevState => ({
            ...prevState,
            [BLOCK_KEYS.ITEMS]: prevState[BLOCK_KEYS.ITEMS].filter(item => item.id !== itemId),
        }))
    }

    const onCloseInvalidWordsModal = () => {
        setIsOpenedInvalidWordsModal(false)
        setScrollableItem(invalidItems[0])
    }

    const validateWords = updatedStructure => {
        const excludedItems = updatedStructure[BLOCK_KEYS.ITEMS].filter(item => !item[ItemModel.isIncluded])
        if (excludedItems.length) {
            setInvalidItems(excludedItems)
            setIsOpenedInvalidWordsModal(true)
            const newErrors = new Map()
            excludedItems.forEach(item => {
                newErrors.set(item.id, { [ItemModel.answer]: [' '] })
            })
            setErrors({ [BLOCK_KEYS.ITEMS]: newErrors })
            return false
        }
        return true
    }

    const onChangeTab = useCallback(
        async tabId => {
            let updatedStructure
            if (tabId === TABS.ITEMS) updatedStructure = cloneDeep(tmpStructure)
            else updatedStructure = await getUpdatedStructure()
            const validationResult = validateTabs(updatedStructure, BLOCK_KEYS_BY_TABS[activeTab])
            if (!validationResult.isHasError) {
                const isValidWords = validateWords(updatedStructure)
                if (!isValidWords) return
                setActiveTab(tabId)
            } else {
                setErrors(validationResult.errors)
                setValidationWarningType(VALIDATION_WARNING_TYPES.ACTIVE_TAB)
            }
        },
        [tmpStructure, activeTab, isNeedToRecalculateItems],
    )

    return (
        <Modal closeOnOverlayClick={false} closeOnEsc={false}>
            <Header title={t('Crossword')} onClose={onShowQuitAccept} />
            <Body
                sideNav={{
                    items: TABS_ARRAY.filter(tab => (isMultiplayerGame ? !tab.isHideFromMultiplayer : true)),
                    activeItemId: activeTab,
                    onChangeItem: onChangeTab,
                    analyticsBlockName: 'CROSSWORD',
                }}
                onSave={onSave}
            >
                <div className={styles.crosswordModal}>
                    <div className={styles.body}>
                        {activeTab === TABS.ITEMS && (
                            <Items
                                errors={errors?.[BLOCK_KEYS.ITEMS]}
                                colorTheme={colorTheme}
                                structure={tmpStructure[BLOCK_KEYS.ITEMS]}
                                onUpdate={onUpdateItem}
                                onRemove={onRemoveItem}
                                onAdd={onAddItem}
                                scrollableItem={scrollableItem}
                                onAccept={data => onUpdateFromAI(data)}
                            />
                        )}
                        {activeTab === TABS.FINAL && (
                            <Final
                                errors={errors?.[BLOCK_KEYS.FINAL]}
                                colorTheme={colorTheme}
                                structure={tmpStructure[BLOCK_KEYS.FINAL]}
                                onUpdate={onUpdateFinal}
                            />
                        )}
                    </div>
                </div>
            </Body>

            {isOpenedInvalidWordsModal && (
                <InvalidWordsModal
                    onClose={() => onCloseInvalidWordsModal()}
                    words={invalidItems.map(item => item[ItemModel.answer])}
                />
            )}

            <StopPanels
                isShowQuitAccept={isShowQuitAccept}
                validationWarningType={validationWarningType}
                onBack={onClosePanels}
                onQuit={() => methods.closeModal()}
            />
        </Modal>
    )
}

export default CrosswordModal
