export const getTextFromClipboard = async () => {
    try {
        const text = await navigator.clipboard.readText()
        return {
            isSuccess: true,
            text,
        }
    } catch (err) {
        return {
            isSuccess: false,
            text: '',
        }
    }
}
