import i18n from 'i18n'
import { IconVisible } from 'svg/index.js'
import { FIELD_TYPES } from 'common/constants/fields.js'
import styles from '../../..//LeadFormTable/components/LeadInfoModal.module.scss'

export const ratingDataFields = {
    placeNumber: {
        field: 'placeNumber',
        headerName: i18n.t('Position'),
        styles: { width: '15%' },
    },
    name: {
        field: 'name',
        headerName: i18n.t('Nickname'),
        styles: { width: '20%' },
    },
    email: {
        field: 'email',
        headerName: i18n.t('Email'),
        type: FIELD_TYPES.EMAIL,
        styles: { width: '25%' },
    },
    numberOfGames: {
        field: 'numberOfGames',
        headerName: i18n.t('Number of tries'),
        styles: { width: '15%' },
    },
    numberOfInteractions: {
        field: 'numberOfInteractions',
        headerName: i18n.t('Number of Interactions'),
        styles: { width: '25%' },
    },
    passingTime: {
        field: 'passingTime',
        headerName: i18n.t('Best time'),
        styles: { width: '15%' },
    },
    gameFinished: {
        field: 'gameFinished',
        headerName: i18n.t('Game finished'),
        type: FIELD_TYPES.CHECKBOX,
        styles: { width: '0', fontSize: '0' },
    },
    isWin: {
        field: 'isWin',
        headerName: i18n.t('Is win'),
        type: FIELD_TYPES.CHECKBOX,
        styles: { width: '0', fontSize: '0' },
    },
}

export const getColumns = onSelectColumn => {
    const cols = Object.values(ratingDataFields)

    if (onSelectColumn) {
        cols.push({
            field: 'sessionId',
            headerName: i18n.t('More info'),
            styles: { width: '10%', textAlign: 'end', justifyContent: 'end' },
            getValue: v =>
                v && (
                    <div className={styles.leadInfoModalButton} onClick={() => onSelectColumn(v)}>
                        <IconVisible />
                    </div>
                ),
        })
    }

    return cols
}
