import React, { useState, useEffect } from "react"

import styles from './MultiplayerBackground.module.scss'

const MultiplayerBackground = ({ isMultiplayerGame, app }) => {
    const [additionalStyles, setAdditionalStyles] = useState({})

    useEffect(() => {
        let backgroundImage = 'none',
            backgroundColor = '#fff'
        if (app.multiplayerBackgroundImage) backgroundImage = `url(${app.multiplayerBackgroundImage})`
        if (app.colorTheme) backgroundColor = app.colorTheme

        setAdditionalStyles({
            backgroundColor,
            backgroundImage,
        })
    }, [app])

    if (!isMultiplayerGame) return null

    return (
        <div className={styles.multiplayerBackground} style={additionalStyles} />
    )
}

export default MultiplayerBackground