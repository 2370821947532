import qs from 'qs'

import store from '../store'

const DEFAULT_LOCALE_CODE = 'en'

export const getFullUrlToSsr = (path = '') => {
    const { language } = store.getState()
    const prefix = language === DEFAULT_LOCALE_CODE ? '' : `/${language}`
    return process.env.REACT_APP_SSR_URL + prefix + path
}

export const getFullUrlToAdmin = (path = '') => {
    return process.env.REACT_APP_BACKEND_URL.replace('api', 'admin') + path
}

export const addRouterQueryParam = (key, value, obj) => {
    const searchParams = qs.parse(obj.location.search, { ignoreQueryPrefix: true })
    obj.history.push({
        pathname: obj.location.pathname,
        search: qs.stringify({ ...searchParams, [key]: value }),
    })
}

export const removeRouterQueryParam = (key, obj) => {
    const searchParams = qs.parse(obj.location.search, { ignoreQueryPrefix: true })
    delete searchParams[key]
    obj.history.push({
        pathname: obj.location.pathname,
        search: qs.stringify(searchParams),
    })
}
