import { useContext } from 'react'
import classNames from 'classnames'

import styles from './ListItem.module.scss'

import { PopoverContext } from '../../Popover'

const ListItem = ({ icon, isActive, onClick = () => {}, children }) => {
    const { onClosePopover } = useContext(PopoverContext)

    return (
        <div
            className={classNames(styles.listItem, { [styles.isActiveListItem]: isActive })}
            onClick={() => {
                onClick()
                onClosePopover()
            }}
        >
            {!!icon && <div className={styles.icon}>{icon}</div>}
            {children}
        </div>
    )
}

export default ListItem
