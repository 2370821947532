import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { DIRECTIONS } from './constants'

import styles from './MobileTongue.module.scss'

const MobileTongue = ({ isOpened, direction = DIRECTIONS.RIGHT, onChange, openText, closeText }) => {
    const { t } = useTranslation()

    return (
        <div
            className={classNames(styles.mobileTongue, {
                [styles.isLeftMobileTongue]: direction === DIRECTIONS.LEFT,
                [styles.isRightMobileTongue]: direction === DIRECTIONS.RIGHT,
            })}
        >
            <div className={styles.tongue} onClick={() => onChange(!isOpened)}>
                {isOpened ? closeText || t('Close settings') : openText || t('Open settings')}
            </div>
            {isOpened && <div className={styles.layout} onClick={() => onChange(false)} />}
        </div>
    )
}

export default MobileTongue
