import React from 'react'
import { cloneDeep, isEqual, set } from 'lodash'
import { withTranslation } from 'react-i18next'

import VideoTips from 'components/Tips/VideoTips'
import ConfirmationDialog from 'components/Modal/ConfirmationDialog/ConfirmationDialog'
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import { MailChimpProvider } from 'components/MailChimp/MailChimpContext'
import { WebhooksProvider } from 'components/Webhooks/WebhooksContext'
import ProviderComposer, { provider } from 'components/ProviderComposer/ProviderComposer'
import Toast from 'components/Toast/Toast'

import pagesService from 'common/services/PagesService'
import roundsService from 'common/services/RoundsService'
import blockService from 'common/services/BlockService'

import { getMultiplayerBlockInPage } from 'utils/multiplayer'

import Devtools from './components/Devtools/Devtools'
import PlaygroundContainer from './components/PlaygroundContainer/PlaygroundContainer'
import MultiplayerBackground from './components/MultiplayerBackground/MultiplayerBackground'

import HowTrialWorksModal from './Modals/HowTrialWorksModal/HowTrialWorksModal'
import ModalController from './Modals/ModalController'

import { BLOCK_DICTIONARY, BLOCK_SCHEMA } from './schema'
import BlocksController from './Blocks/BlocksController'
import Add from './Blocks/components/Add/Add'

import BlocksPanel from './Panels/Blocks/BlocksPanel'
import ControlPanel from './Panels/Control'
import InternalPages from './Panels/InternalPages/InternalPages'
import InternalRounds from './Panels/InternalRounds/InternalRounds'

import Normalizer from './util/normalizer'
import DataSchema from './util/schema'
import { PROJECT_DATA_TYPES, NAVIGATION_TYPES, CONTROL_KEYS, CONFIRMATION_DIALOG_TYPES } from './constants'
import { APP_SCHEMA } from './schema'

import './index.scss'
import './Blocks/Blocks.scss'

class Editor extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,

            navigationType: NAVIGATION_TYPES.PAGES,

            selectedPage: this.props.projectStructureJson.pages[0],
            selectedBlock: null,
            selectedBlockArrayProperty: null,

            isOpenBlockPanel: false,
            insertBeforeId: null,

            confirmationDialog: {
                isOpen: false,
                type: null,
                payload: {},
            },

            modal: {
                name: null,
                isOpen: false,
                payload: {},
            },
        }
    }

    updateStateData = async (type, _data) => {
        const { onChangeProject, projectStructureJson } = this.props

        if (type === PROJECT_DATA_TYPES.APP) {
            await onChangeProject('projectStructureJson.app', {
                ...projectStructureJson.app,
                ..._data,
            })
        }
        if (type === PROJECT_DATA_TYPES.AUTH) {
            await onChangeProject('projectStructureJson.auth', {
                ...projectStructureJson.auth,
                ..._data,
            })
        }
        if (type === PROJECT_DATA_TYPES.PAGES) {
            await onChangeProject('projectStructureJson.pages', _data)
        }
    }

    onOpenBlockPanel = id => {
        this.setState({
            selectedBlock: null,
            selectedBlockArrayProperty: null,
            isOpenBlockPanel: true,
            insertBeforeId: id,
        })
    }

    onInlineControlAction = async ({ action, blockId }, force = false) => {
        switch (action) {
            case 'clone': {
                await this.cloneBlock(blockId)
                break
            }
            case 'delete': {
                if (force) {
                    await this.deleteBlock(blockId)
                } else {
                    this.showConfirmationDialog(CONFIRMATION_DIALOG_TYPES.BLOCK_REMOVE, { id: blockId })
                }
                break
            }
            case 'up': {
                this.moveBlockUp(blockId)
                break
            }
            case 'down': {
                this.moveBlockDown(blockId)
                break
            }
            default:
                break
        }
    }

    moveBlockDown = async blockId => {
        const {
            projectStructureJson: { pages, auth },
        } = this.props
        const { selectedPage, navigationType } = this.state

        if (navigationType === NAVIGATION_TYPES.PAGES) {
            const newData = blockService.moveDown({ navigationType, pages, pageId: selectedPage.id, blockId })

            await this.updateStateData(PROJECT_DATA_TYPES.PAGES, newData.newPages)
            this.setState({
                selectedPage: newData.updatedPage,
                selectedBlock: newData.movedBlock,
            })
        }
        if (navigationType === NAVIGATION_TYPES.AUTH) {
            const newData = blockService.moveDown({ navigationType, blocks: auth.blocks, blockId })

            await this.updateStateData(PROJECT_DATA_TYPES.AUTH, { blocks: newData.newBlocks })
            this.setState({
                selectedBlock: newData.movedBlock,
            })
        }
    }
    moveBlockUp = async blockId => {
        const {
            projectStructureJson: { pages, auth },
        } = this.props
        const { selectedPage, navigationType } = this.state

        if (navigationType === NAVIGATION_TYPES.PAGES) {
            const newData = blockService.moveUp({ navigationType, pages, pageId: selectedPage.id, blockId })

            await this.updateStateData(PROJECT_DATA_TYPES.PAGES, newData.newPages)
            await this.setState({
                selectedPage: newData.updatedPage,
                selectedBlock: newData.movedBlock,
            })
        }
        if (navigationType === NAVIGATION_TYPES.AUTH) {
            const newData = blockService.moveUp({ navigationType, blocks: auth.blocks, blockId })

            await this.updateStateData(PROJECT_DATA_TYPES.AUTH, { blocks: newData.newBlocks })
            await this.setState({
                selectedBlock: newData.movedBlock,
            })
        }
    }
    onAddBlock = async (blockType, insertBeforeId = null, blockData = {}, blockId = null) => {
        const {
            projectStructureJson: { pages, auth },
        } = this.props
        const { selectedPage, navigationType } = this.state

        if (navigationType === NAVIGATION_TYPES.PAGES) {
            const newData = blockService.addItem({
                navigationType,
                pages,
                pageId: selectedPage.id,
                insertBeforeId,
                blockType,
                blockData,
                blockId,
            })

            await this.updateStateData(PROJECT_DATA_TYPES.PAGES, newData.newPages)

            this.onBlockSelect(newData.newBlock)
            this.setState({
                selectedPage: newData.updatedPage,
                insertBeforeId: null,
                isOpenBlockPanel: false,
            })
        }
        if (navigationType === NAVIGATION_TYPES.AUTH) {
            const newData = blockService.addItem({
                navigationType,
                blocks: auth.blocks,
                insertBeforeId,
                blockType,
                blockData,
                blockId,
            })

            await this.updateStateData(PROJECT_DATA_TYPES.AUTH, { blocks: newData.newBlocks })

            this.onBlockSelect(newData.newBlock)
            this.setState({
                insertBeforeId: null,
                isOpenBlockPanel: false,
            })
        }
    }
    cloneBlock = async blockId => {
        const {
            projectStructureJson: { pages, auth },
        } = this.props
        const { selectedPage, navigationType } = this.state
        this.setState({ isLoading: true })
        try {
            if (navigationType === NAVIGATION_TYPES.PAGES) {
                const newData = await blockService.cloneItem({
                    navigationType,
                    pages,
                    pageId: selectedPage.id,
                    blockId,
                })

                await this.updateStateData(PROJECT_DATA_TYPES.PAGES, newData.newPages)

                this.onBlockSelect(newData.clonedBlock)
                this.setState({
                    selectedPage: newData.updatedPage,
                    insertBeforeId: null,
                })
            }
            if (navigationType === NAVIGATION_TYPES.AUTH) {
                const newData = await blockService.cloneItem({ navigationType, blocks: auth.blocks, blockId })

                await this.updateStateData(PROJECT_DATA_TYPES.AUTH, { blocks: newData.newBlocks })

                this.onBlockSelect(newData.clonedBlock)
                this.setState({
                    insertBeforeId: null,
                })
            }
        } finally {
            this.setState({ isLoading: false })
        }
    }
    deleteBlock = async blockId => {
        const {
            projectId,
            projectStructureJson: { pages, auth },
        } = this.props
        const { selectedPage, navigationType } = this.state
        this.setState({ isLoading: false })
        try {
            if (navigationType === NAVIGATION_TYPES.PAGES) {
                const newData = await blockService.deleteItem({
                    navigationType,
                    pages,
                    pageId: selectedPage.id,
                    blockId,
                    projectId,
                })

                await this.updateStateData(PROJECT_DATA_TYPES.PAGES, newData.newPages)
                await this.setState({
                    selectedPage: newData.updatedPage,
                })
                this.onBlockSelect(null)
            }
            if (navigationType === NAVIGATION_TYPES.AUTH) {
                const newData = await blockService.deleteItem({
                    navigationType,
                    blocks: auth.blocks,
                    blockId,
                    projectId,
                })

                await this.updateStateData(PROJECT_DATA_TYPES.AUTH, { blocks: newData.newBlocks })
                this.onBlockSelect(null)
            }
        } catch (error) {
            console.error(error)
            Toast('error', {
                title: 'Delete error',
                message: '',
            })
        } finally {
            this.setState({ isLoading: true })
        }
    }

    showConfirmationDialog = (type, payload) => {
        this.setState({
            confirmationDialog: {
                isOpen: true,
                type,
                payload,
            },
        })
    }

    closeConfirmationDialog = () => {
        this.setState({
            confirmationDialog: {
                isOpen: false,
                type: null,
                payload: {},
            },
        })
    }

    openModalByName = (name, payload = {}) => {
        this.setState({
            modal: {
                name,
                isOpen: true,
                payload,
            },
        })
    }

    closeModal = () => {
        this.setState({
            modal: {
                name: null,
                isOpen: false,
                payload: null,
            },
        })
    }

    onPageSelect = page => {
        this.setState({
            navigationType: NAVIGATION_TYPES.PAGES,
            selectedPage: page,
            selectedBlock: null,
            selectedBlockArrayProperty: null,
        })
    }
    onRoundSelect = round => {
        this.setState({
            navigationType: NAVIGATION_TYPES.PAGES,
            selectedPage: round,
            selectedBlock: null,
            selectedBlockArrayProperty: null,
        })
    }

    onBlockSelect = block => {
        const { selectedBlock, selectedBlockArrayProperty } = this.state

        if (selectedBlock && block && selectedBlock.id === block.id) {
            if (selectedBlockArrayProperty) {
                this.setState({
                    selectedBlockArrayProperty: null,
                })
            }
            return
        }

        this.setState({
            selectedBlock: block,
            selectedBlockArrayProperty: null,
        })
    }

    onResetSelectedBlock = () => {
        const { selectedBlock } = this.state
        if (selectedBlock) {
            this.setState({
                selectedBlock: null,
                selectedBlockArrayProperty: null,
            })
        }
    }

    changeAppData = async payload => {
        const {
            projectStructureJson: { app },
        } = this.props

        const _app = {
            ...cloneDeep(app),
            ...payload,
        }

        const normalizedBlock = new Normalizer(new DataSchema(APP_SCHEMA)).process(_app)

        if (isEqual(app, normalizedBlock)) return

        await this.updateStateData(PROJECT_DATA_TYPES.APP, _app)
    }

    changeBlocksData = async payload => {
        const {
            projectStructureJson: { pages, auth },
        } = this.props
        const { navigationType } = this.state

        if (navigationType === NAVIGATION_TYPES.PAGES) {
            const { selectedPage, selectedBlock } = this.state
            const _blocks = cloneDeep(selectedPage.blocks)
            // TODO need refactor. just inline code from "changeAppData" method and remove case option by PROJECT_DATA_TYPES.PAGES
            for (const [id, value] of Object.entries(payload)) {
                const blockIndex = _blocks.findIndex(b => b.id === id)
                if (blockIndex === -1) {
                    return
                }
                let block = cloneDeep(_blocks[blockIndex])

                if (value.payload && value.payload.selectedBlockArrayProperty) {
                    // это запрос на редактирование элемента дочернего массива блока
                    const { arrName, index, schema } = value.payload.selectedBlockArrayProperty

                    block[arrName][index] = new Normalizer(new DataSchema(schema)).process({
                        ...block[arrName][index],
                        ...value.data,
                    })
                } else {
                    block = { ...block, ...value.data }
                }

                const normalizedBlock = new Normalizer(new DataSchema(BLOCK_SCHEMA[block.t])).process(block)

                if (!isEqual(_blocks[blockIndex], normalizedBlock)) {
                    _blocks[blockIndex] = normalizedBlock
                    const newPages = pages.map(page =>
                        page.id === selectedPage.id ? { ...page, blocks: _blocks } : page,
                    )
                    const newSelectPage = newPages.find(page => page.id === selectedPage.id)
                    let newSelectedBlock = selectedBlock
                    if (selectedBlock) {
                        newSelectedBlock = newSelectPage.blocks.find(_block => _block.id === selectedBlock.id)
                    }
                    await this.setState({
                        selectedPage: newSelectPage,
                        selectedBlock: newSelectedBlock,
                    })
                    await this.updateStateData(PROJECT_DATA_TYPES.PAGES, newPages)
                }
            }
        }
        if (navigationType === NAVIGATION_TYPES.AUTH) {
            const _blocks = cloneDeep(auth.blocks)
            // TODO need refactor. just inline code from "changeAppData" method and remove case option by PROJECT_DATA_TYPES.PAGES
            for (const [id, value] of Object.entries(payload)) {
                const blockIndex = _blocks.findIndex(b => b.id === id)
                if (blockIndex === -1) {
                    return
                }
                let block = cloneDeep(_blocks[blockIndex])

                if (value.payload && value.payload.selectedBlockArrayProperty) {
                    // это запрос на редактирование элемента дочернего массива блока
                    const { arrName, index, schema } = value.payload.selectedBlockArrayProperty

                    block[arrName][index] = new Normalizer(new DataSchema(schema)).process({
                        ...block[arrName][index],
                        ...value.data,
                    })
                } else {
                    block = { ...block, ...value.data }
                }

                const normalizedBlock = new Normalizer(new DataSchema(BLOCK_SCHEMA[block.t])).process(block)

                if (!isEqual(_blocks[blockIndex], normalizedBlock)) {
                    _blocks[blockIndex] = normalizedBlock
                    await this.updateStateData(PROJECT_DATA_TYPES.AUTH, { blocks: _blocks })
                    await this.setState({
                        selectedBlock: normalizedBlock,
                    })
                }
            }
        }
    }

    addPage = async () => {
        const {
            projectStructureJson: { pages },
        } = this.props

        const { newPages, newPage } = pagesService.addItem(pages)

        await this.updateStateData(PROJECT_DATA_TYPES.PAGES, newPages)
        this.onPageSelect(newPage)
    }
    clonePage = async pageId => {
        const {
            projectStructureJson: { pages },
        } = this.props

        this.setState({ isLoading: true })
        try {
            const { newPages, clonedPage } = await pagesService.cloneItem(pages, pageId)
            await this.updateStateData(PROJECT_DATA_TYPES.PAGES, newPages)
            this.onPageSelect(clonedPage)
        } finally {
            this.setState({ isLoading: false })
        }
    }
    deletePage = async deletingPageId => {
        const {
            projectId,
            projectStructureJson: { pages },
        } = this.props

        this.setState({ isLoading: true })
        try {
            const { newPages, index } = await pagesService.deleteItem(pages, deletingPageId, projectId)
            await this.updateStateData(PROJECT_DATA_TYPES.PAGES, newPages)
            if (index === 0) this.onPageSelect(newPages[index])
            else this.onPageSelect(newPages[index - 1])
        } catch (error) {
            console.error(error)
            Toast('error', {
                title: 'Delete error',
                message: '',
            })
        } finally {
            this.setState({ isLoading: false })
        }
    }
    movePage = async (currentIndex, newIndex) => {
        const {
            projectStructureJson: { pages },
        } = this.props

        const { newPages } = pagesService.moveItem(pages, currentIndex, newIndex)
        await this.updateStateData(PROJECT_DATA_TYPES.PAGES, newPages)
    }
    renamePage = async (id, name) => {
        const {
            projectStructureJson: { pages },
        } = this.props

        const newPages = pages.map(page => (page.id !== id ? page : { ...page, name }))
        await this.updateStateData(PROJECT_DATA_TYPES.PAGES, newPages)
    }

    addRound = async () => {
        const {
            projectStructureJson: { pages },
        } = this.props

        const { newRounds, newRound } = roundsService.addItem(pages)

        await this.updateStateData(PROJECT_DATA_TYPES.PAGES, newRounds)
        this.onPageSelect(newRound)
    }
    cloneRound = async pageId => {
        const {
            projectStructureJson: { pages },
        } = this.props

        this.setState({ isLoading: true })
        try {
            const { newRounds, clonedRound } = await roundsService.cloneItem(pages, pageId)
            await this.updateStateData(PROJECT_DATA_TYPES.PAGES, newRounds)
            this.onPageSelect(clonedRound)
        } finally {
            this.setState({ isLoading: false })
        }
    }
    deleteRound = async deletingRoundId => {
        const {
            projectId,
            projectStructureJson: { pages },
        } = this.props

        this.setState({ isLoading: true })
        try {
            const { newRounds, index } = await roundsService.deleteItem(pages, deletingRoundId, projectId)
            await this.updateStateData(PROJECT_DATA_TYPES.PAGES, newRounds)
            this.onPageSelect(newRounds[index === 0 ? 0 : index - 1])
        } catch (error) {
            console.error(error)
            Toast('error', {
                title: 'Delete error',
                message: '',
            })
        } finally {
            this.setState({ isLoading: false })
        }
    }
    moveRound = async (currentIndex, newIndex) => {
        const {
            projectStructureJson: { pages },
        } = this.props

        const { newRounds } = roundsService.moveItem(pages, currentIndex, newIndex)
        await this.updateStateData(PROJECT_DATA_TYPES.PAGES, newRounds)
    }
    renameRound = async (id, name) => {
        const {
            projectStructureJson: { pages },
        } = this.props

        const newRounds = pages.map(page => (page.id !== id ? page : { ...page, name }))
        await this.updateStateData(PROJECT_DATA_TYPES.PAGES, newRounds)
    }

    onAuthSelect = async () => {
        this.setState({
            navigationType: NAVIGATION_TYPES.AUTH,
            selectedBlock: null,
            selectedBlockArrayProperty: null,
        })
    }
    onAuthDelete = async () => {
        this.setState({
            navigationType: NAVIGATION_TYPES.PAGES,
            selectedBlock: null,
            selectedBlockArrayProperty: null,
        })
        await this.changeAppData({ isUseAuthorization: false })
    }

    /**
     * Запросить контролы для элемента в массиве
     * В блоке может быть массив, например массив точек в зум-карте.
     * Это возможность для каждой точки показать контролы при клике на нее
     *
     * @param {*} id идентификатор блока
     * @param {*} arrName имя свойства
     * @param {*} index
     */
    requestArrayElementControls = (id, arrName, index) => {
        const { navigationType } = this.state

        if (navigationType === NAVIGATION_TYPES.PAGES) {
            const { selectedPage } = this.state

            const block = selectedPage.blocks.find(b => b.id === id),
                schema = BLOCK_SCHEMA[`${block.t}.${arrName}`]

            if (schema) {
                this.setState({
                    selectedBlock: block,
                    selectedBlockArrayProperty: { arrName, index, schema },
                })
            } else {
                this.setState({
                    selectedBlock: null,
                    selectedBlockArrayProperty: null,
                })
                console.error(`Schema not found for ${block.t}.${arrName}`)
            }
        }
        if (navigationType === NAVIGATION_TYPES.AUTH) {
            const {
                projectStructureJson: { auth },
            } = this.props

            const block = auth.blocks.find(b => b.id === id),
                schema = BLOCK_SCHEMA[`${block.t}.${arrName}`]

            if (schema) {
                this.setState({
                    selectedBlock: block,
                    selectedBlockArrayProperty: { arrName, index, schema },
                })
            } else {
                this.setState({
                    selectedBlock: null,
                    selectedBlockArrayProperty: null,
                })
                console.error(`Schema not found for ${block.t}.${arrName}`)
            }
        }
    }

    render() {
        const {
            i18n,
            tmpProject,
            projectStructureJson: { app, auth, pages, integrations },
            isMultiplayerGame,
        } = this.props
        const {
            selectedPage,
            selectedBlock,
            selectedBlockArrayProperty,
            isOpenBlockPanel,
            insertBeforeId,
            confirmationDialog,
            modal,
            navigationType,
        } = this.state

        return (
            <ProviderComposer
                providers={[
                    provider(MailChimpProvider, {
                        projectId: this.props.projectId,
                        blockId: selectedBlock && selectedBlock.id,
                    }),
                    provider(WebhooksProvider, {
                        projectId: this.props.projectId,
                        blockId: selectedBlock && selectedBlock.id,
                    }),
                ]}
            >
                <div className="artboard">
                    <div className="overlay" onClick={this.onResetSelectedBlock} />

                    {isMultiplayerGame ? (
                        <InternalRounds
                            rounds={pages}
                            selectedRound={selectedPage}
                            onAddRound={this.addRound}
                            onCloneRound={this.cloneRound}
                            onDeleteRound={this.deleteRound}
                            onRenameRound={this.renameRound}
                            onSelectRound={this.onRoundSelect}
                            onChangePosition={this.moveRound}
                        />
                    ) : (
                        <InternalPages
                            app={app}
                            pages={pages}
                            selectedPage={selectedPage}
                            onAddPage={this.addPage}
                            onClonePage={this.clonePage}
                            onDeletePage={this.deletePage}
                            onRenamePage={this.renamePage}
                            onSelectPage={this.onPageSelect}
                            onChangePosition={this.movePage}
                            onSelectAuth={this.onAuthSelect}
                            onDeleteAuth={this.onAuthDelete}
                            isAuthSelected={navigationType === NAVIGATION_TYPES.AUTH}
                        />
                    )}
                    {!!selectedPage && <VideoTips isMultiplayerGame={isMultiplayerGame} blocks={selectedPage.blocks} />}
                    <BlocksPanel
                        navigationType={navigationType}
                        isMultiplayerGame={isMultiplayerGame}
                        selectedPage={selectedPage}
                        open={isOpenBlockPanel}
                        onAdd={this.onAddBlock}
                        insertBeforeId={insertBeforeId}
                        onClose={() => this.setState({ isOpenBlockPanel: false })}
                    />

                    <MultiplayerBackground isMultiplayerGame={isMultiplayerGame} app={app} />

                    <PlaygroundContainer
                        onReset={this.onResetSelectedBlock}
                        app={app}
                        isMultiplayerGame={isMultiplayerGame}
                    >
                        <>
                            {navigationType === NAVIGATION_TYPES.PAGES && (
                                <>
                                    {selectedPage.blocks.map((block, index) => (
                                        <ErrorBoundary key={block.id}>
                                            <BlocksController
                                                pages={pages}
                                                isMultiplayerGame={isMultiplayerGame}
                                                block={block}
                                                appData={app}
                                                isSelected={selectedBlock && selectedBlock.id === block.id}
                                                isLast={index === selectedPage.blocks.length - 1}
                                                isFirst={index === 0}
                                                methods={{
                                                    onBlockSelect: this.onBlockSelect,
                                                    changeBlocksData: this.changeBlocksData,
                                                    requestArrayElementControls: this.requestArrayElementControls,
                                                    onOpenBlockPanel: this.onOpenBlockPanel,
                                                    onInlineControlAction: this.onInlineControlAction,
                                                }}
                                            />
                                        </ErrorBoundary>
                                    ))}
                                    <Add
                                        onAdd={this.onAddBlock}
                                        onOpenBlockPanel={this.onOpenBlockPanel}
                                        isHasBlocks={!!selectedPage.blocks.length}
                                        isHasMultiplayerBlocks={!!getMultiplayerBlockInPage(selectedPage)}
                                        isMultiplayerGame={isMultiplayerGame}
                                    />
                                </>
                            )}
                            {navigationType === NAVIGATION_TYPES.AUTH && (
                                <>
                                    {auth.blocks.map((block, index) => (
                                        <ErrorBoundary key={block.id}>
                                            <BlocksController
                                                pages={pages}
                                                isMultiplayerGame={isMultiplayerGame}
                                                block={block}
                                                appData={app}
                                                isSelected={selectedBlock && selectedBlock.id === block.id}
                                                isLast={index === auth.blocks.length - 1}
                                                isFirst={index === 0}
                                                methods={{
                                                    onBlockSelect: this.onBlockSelect,
                                                    changeBlocksData: this.changeBlocksData,
                                                    requestArrayElementControls: this.requestArrayElementControls,
                                                    onOpenBlockPanel: this.onOpenBlockPanel,
                                                    onInlineControlAction: this.onInlineControlAction,
                                                }}
                                            />
                                        </ErrorBoundary>
                                    ))}
                                    <Add
                                        onAdd={this.onAddBlock}
                                        onOpenBlockPanel={this.onOpenBlockPanel}
                                        isHasBlocks={!!auth.blocks.length}
                                        isHasMultiplayerBlocks={!!getMultiplayerBlockInPage(auth)}
                                        isMultiplayerGame={isMultiplayerGame}
                                    />
                                </>
                            )}
                        </>
                    </PlaygroundContainer>

                    {/* APP */}
                    <ControlPanel
                        isOpacity={!!selectedBlock}
                        methods={{
                            openModalByName: this.openModalByName,
                            showConfirmationDialog: this.showConfirmationDialog,
                        }}
                        blockName={i18n.t('Project settings')}
                        schema={APP_SCHEMA}
                        blockType={null}
                        isMultiplayerGame={isMultiplayerGame}
                        controlKey={CONTROL_KEYS.APP}
                        totalPagesCount={pages.length}
                        blockData={app}
                        appData={app}
                        onUpdate={async _data => await this.changeAppData(_data)}
                        integrations={integrations}
                    />
                    {/* BLOCKS */}
                    {!!selectedBlock && (
                        <ControlPanel
                            isSecondary
                            onClose={this.onResetSelectedBlock}
                            methods={{
                                openModalByName: this.openModalByName,
                                showConfirmationDialog: this.showConfirmationDialog,
                            }}
                            blockName={BLOCK_DICTIONARY[selectedBlock.t]?.labelForRightPanel || ''}
                            schema={BLOCK_SCHEMA[selectedBlock.t]}
                            blockType={selectedBlock.t}
                            isMultiplayerGame={isMultiplayerGame}
                            controlKey={CONTROL_KEYS.BLOCKS}
                            totalPagesCount={pages.length}
                            blockData={selectedBlock}
                            appData={app}
                            selectedBlockArrayProperty={selectedBlockArrayProperty}
                            onUpdate={async _data => await this.changeBlocksData(_data)}
                            integrations={integrations}
                        />
                    )}
                </div>
                {confirmationDialog.isOpen && (
                    <>
                        {confirmationDialog.type === CONFIRMATION_DIALOG_TYPES.BLOCK_REMOVE && (
                            <ConfirmationDialog
                                onClose={() => this.closeConfirmationDialog()}
                                onAction={() =>
                                    this.onInlineControlAction(
                                        {
                                            action: 'delete',
                                            blockId: confirmationDialog.payload.id,
                                        },
                                        true,
                                    ) && this.closeConfirmationDialog()
                                }
                                data={{
                                    headText: i18n.t('Are you sure you want to delete this block?'),
                                    noteText: i18n.t('The content of the block will also be deleted.'),
                                    actionVariant: 'primary-danger',
                                    actionText: i18n.t('Delete'),
                                    cancelText: i18n.t('Cancel'),
                                }}
                            />
                        )}
                        {confirmationDialog.type === CONFIRMATION_DIALOG_TYPES.AUTH_DELETE && (
                            <ConfirmationDialog
                                onClose={() => this.closeConfirmationDialog()}
                                onAction={async () => {
                                    await this.onAuthDelete()
                                    this.closeConfirmationDialog()
                                }}
                                data={{
                                    headText: i18n.t('Are you sure you want to delete login page?'),
                                    noteText: i18n.t(
                                        'By deleting this page, authorization in the project will be disabled and user data will not be collected.',
                                    ),
                                    actionVariant: 'primary-danger',
                                    actionText: i18n.t('Delete'),
                                    cancelText: i18n.t('Cancel'),
                                }}
                            />
                        )}
                    </>
                )}
                <Devtools
                    onAddBlock={this.onAddBlock}
                    tmpProject={tmpProject}
                    selectedBlockJson={selectedBlock}
                    projectJson={this.props.projectStructureJson}
                    onChangeProject={async (value, path) => {
                        const { projectStructureJson, onChangeProject } = this.props

                        const tmpProject = cloneDeep(projectStructureJson)

                        set(tmpProject, path, value)

                        tmpProject.app = new Normalizer(new DataSchema(APP_SCHEMA)).process(tmpProject.app)
                        tmpProject.pages = tmpProject.pages.map(page => ({
                            ...page,
                            blocks: page.blocks.map(block =>
                                new Normalizer(new DataSchema(BLOCK_SCHEMA[block.t])).process(block),
                            ),
                        }))

                        await onChangeProject('projectStructureJson', tmpProject)

                        const { selectedPage } = this.state
                        if (selectedPage) {
                            const { pages } = this.props.projectStructureJson
                            const newPageIndex = pages.findIndex(page => page.id === selectedPage.id)
                            if (newPageIndex !== -1)
                                this.setState({
                                    selectedPage: pages[newPageIndex],
                                    selectedBlock: null,
                                    selectedBlockArrayProperty: null,
                                })
                            else
                                this.setState({
                                    selectedPage: pages[0],
                                    selectedBlock: null,
                                    selectedBlockArrayProperty: null,
                                })
                        }
                    }}
                />
                <HowTrialWorksModal />
                <ModalController
                    modal={modal}
                    data={{ app, pages, auth }}
                    methods={{
                        closeModal: this.closeModal,
                        changeAppData: this.changeAppData,
                        changeBlocksData: this.changeBlocksData,
                    }}
                />
            </ProviderComposer>
        )
    }
}

export default withTranslation('translations')(Editor)
