import { cloneDeep } from 'lodash'

import { API__WEBHOOKS } from 'api'
import store from 'store'

import { moveInArray } from "utils/common"

import { default as RoundModel } from '../models/RoundModel'

const addItem = rounds => {
    const newRounds = cloneDeep(rounds)
    const newRound = new RoundModel()
    newRounds.push(newRound)
    return { newRounds, newRound }
}

const moveItem = (rounds, currentIndex, newIndex) => {
    const newRounds = cloneDeep(rounds)

    return {
        newRounds: moveInArray(newRounds, currentIndex, newIndex),
    }
}

const deleteItem = async (rounds, roundId, projectId) => {
    const {
        organizations: { selectedOrganizationId },
    } = store.getState()
    const index = rounds.findIndex(p => p.id === roundId)
    const newRounds = cloneDeep(rounds)
    const deletingRound = newRounds[index]
    newRounds.splice(index, 1)
    const roundsWithoutLink = JSON.parse(JSON.stringify(newRounds).replace(new RegExp(`#${roundId}`, 'g'), ''))

    const blockIds = deletingRound.blocks.map(block => block.id)
    await API__WEBHOOKS.DELETE_BLOCKS_WEBHOOKS({ organizationId: selectedOrganizationId, blockIds, projectId })
    return { newRounds: roundsWithoutLink, index }
}

const cloneItem = async (rounds, roundId) => {
    const roundIndex = rounds.findIndex(el => el.id === roundId)
    const _arr = cloneDeep(rounds)
    const sourceRound = _arr[roundIndex]
    const clonedItem = new RoundModel({ ...sourceRound })
    _arr.splice(roundIndex + 1, 0, clonedItem)
    return { newRounds: _arr, clonedRound: clonedItem }
}

export default {
    addItem,
    moveItem,
    deleteItem,
    cloneItem,
}
