import { cloneDeep } from 'lodash'

import { createCollector, createValidator } from 'shared/validation'

import { FIELD_TYPES } from 'common/constants/fields'

import {getUniqueId} from "common/utils/getUniqueId"

import i18n from 'i18n'

import {
    DEFAULT_FORM_FIELDS,
    EMBEDDED_VALIDATE_RULES_FIELDS,
    FIELD_KEYS,
    FIELD_LABELS,
    INDEPENDENT_VALIDATE_RULES_FIELDS,
    MODE,
    TABS_ENUM,
    TAB_TO_FIELD_MAP,
    FORM_KEYS,
    FINAL_KEYS,
} from './constants'

export const selectCustomFields = fields => fields.filter(field => field.key.includes(FIELD_KEYS.CUSTOM_FIELD))
export const selectBasicFields = fields => fields.filter(field => !field.key.includes(FIELD_KEYS.CUSTOM_FIELD))

export function getInitialValueByType(type) {
    switch (type) {
        case FIELD_TYPES.TEXT_AREA:
        case FIELD_TYPES.TEXT_INPUT:
        case FIELD_TYPES.URL:
        case FIELD_TYPES.PHONE_NUMBER:
        case FIELD_TYPES.EMAIL:
            return ''
        case FIELD_TYPES.CHECKBOX:
        case FIELD_TYPES.CONSENT:
            return false
        default:
            throw new Error('Undefined field type: ' + type)
    }
}

const createField = ({ key, label, isRequired = true, type, isReadonly = false }) => ({
    key,
    label,
    isRequired,
    initialValue: getInitialValueByType(type),
    type,
    isReadonly,
})

export const createDefaultField = (key, isReadonly = false) =>
    createField({
        key,
        label: FIELD_LABELS[key],
        type: DEFAULT_FORM_FIELDS[key].type,
        isReadonly,
    })

export function createCustomField(customFields) {
    const key = `${FIELD_KEYS.CUSTOM_FIELD}_${getUniqueId()}`
    return createField({
        key: key,
        label: i18n.t('New field'),
        type: FIELD_TYPES.TEXT_INPUT,
    })
}

export const getInitialLeadFormStructureForIndependentMode = () => ({
    isHasFinalScreen: true,
    form: {
        [FORM_KEYS.fields]: [
            createDefaultField(FIELD_KEYS.FIRST_NAME),
            createDefaultField(FIELD_KEYS.LAST_NAME),
            createDefaultField(FIELD_KEYS.EMAIL),
            createDefaultField(FIELD_KEYS.PHONE_NUMBER),
        ],
        [FORM_KEYS.headerText]: i18n.t('Form'),
        [FORM_KEYS.descriptionText]: i18n.t('Please fill in the form fields'),
        [FORM_KEYS.linkPrivacyPolicyLink]: '',
        [FORM_KEYS.buttonText]: i18n.t('Submit'),
        [FORM_KEYS.logoImage]: '',
        [FORM_KEYS.additionalNotes]: '',
    },
    final: {
        [FINAL_KEYS.headerText]: i18n.t('Thank you') + '!',
        [FINAL_KEYS.descriptionText]: '',
        [FINAL_KEYS.imageSrc]: '',
        [FINAL_KEYS.imageDisclaimerText]: '',
    },
})

export const getInitialLeadFormStructureForEmbeddedMode = () => ({
    isHasFinalScreen: false,
    form: {
        [FORM_KEYS.fields]: [createDefaultField(FIELD_KEYS.FIRST_NAME), createDefaultField(FIELD_KEYS.EMAIL)],
        [FORM_KEYS.headerText]: i18n.t('Form'),
        [FORM_KEYS.descriptionText]: i18n.t('Please fill in the form fields'),
        [FORM_KEYS.linkPrivacyPolicyLink]: '',
        [FORM_KEYS.buttonText]: i18n.t('Submit'),
        [FORM_KEYS.logoImage]: '',
    },
})

export const getInitialAuthFormStructure = () => ({
    isHasFinalScreen: false,
    form: {
        [FORM_KEYS.fields]: [createDefaultField(FIELD_KEYS.EMAIL, true)],
        [FORM_KEYS.headerText]: i18n.t('Login'),
        [FORM_KEYS.descriptionText]: i18n.t('Please enter your login details'),
        [FORM_KEYS.linkPrivacyPolicyLink]: '',
        [FORM_KEYS.buttonText]: i18n.t('Next'),
        [FORM_KEYS.logoImage]: '',
    },
})

export const SERVICE_FUNC_BY_MODE = {
    [MODE.INDEPENDENT]: {
        isValidFields: createValidator(INDEPENDENT_VALIDATE_RULES_FIELDS),
        collectFieldsErrors: createCollector(INDEPENDENT_VALIDATE_RULES_FIELDS),
        preFormat: tmpStructure => {
            const _tmpStructure = cloneDeep(tmpStructure)
            for (const [key, value] of Object.entries(_tmpStructure[TAB_TO_FIELD_MAP[TABS_ENUM.form]])) {
                if (typeof value === 'string') _tmpStructure[TAB_TO_FIELD_MAP[TABS_ENUM.form]][key] = value.trim()
            }
            return _tmpStructure
        },
    },
    [MODE.EMBEDDED]: {
        isValidFields: createValidator(EMBEDDED_VALIDATE_RULES_FIELDS),
        collectFieldsErrors: createCollector(EMBEDDED_VALIDATE_RULES_FIELDS),
    },
    [MODE.AUTH]: {
        isValidFields: createValidator(EMBEDDED_VALIDATE_RULES_FIELDS),
        collectFieldsErrors: createCollector(EMBEDDED_VALIDATE_RULES_FIELDS),
    },
}

export const validateTabs = (tmpStructure, mode, activeTab = null) => {
    const functions = SERVICE_FUNC_BY_MODE[mode]

    let structure = cloneDeep(tmpStructure)
    if (!!functions.preFormat) structure = functions.preFormat(tmpStructure)

    return {
        tmpStructure: structure,
        errors: functions.collectFieldsErrors(structure, activeTab),
        isHasError: !functions.isValidFields(structure, activeTab),
    }
}
