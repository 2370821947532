import React from 'react'
import { useTranslation } from 'react-i18next'

import ElementsGroup from 'components/Form/ElementsGroup/ElementsGroup'
import Image from 'components/Form/Image'
import TextArea from 'components/Form/TextArea/TextArea'
import TextInput from 'components/Form/TextInput/TextInput'
import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'
import Toggle from 'components/Form/Toggle/Toggle'
import UploadImage from 'components/Form/UploadImage/UploadImage'

import invertColor from '../../../../../util/invertColor'
import { QuizCover as CoverModel } from '../../quizModels'

function StartView({ tmpStructure, errors, colorTheme, fontFamily, onUpdateStructure }) {
    const { t } = useTranslation()

    const onUpdateCover = newFields => {
        onUpdateStructure({
            cover: { ...tmpStructure.cover, ...newFields },
        })
    }

    return (
        <div className="body">
            <div className="tab-wrapper tab-wrapper--1">
                <div className="workplace">
                    <ScrollableBlock>
                        <div className="scrollbar-inner">
                            <h3 className="tab-title">
                                <span>
                                    {t('Start screen')}
                                    <Toggle
                                        value={tmpStructure.cover.isShowCover}
                                        onUpdate={value => onUpdateCover({ [CoverModel.isShowCover]: value })}
                                    />
                                </span>
                            </h3>
                            <div className={`content${!tmpStructure.cover.isShowCover ? ' is-hidden' : ''}`}>
                                <TextArea
                                    isRequired
                                    label={t('Header')}
                                    rows={1}
                                    errorMessages={errors.cover?.header}
                                    value={tmpStructure.cover.header}
                                    onUpdate={value => onUpdateCover({ [CoverModel.header]: value })}
                                />
                                <TextArea
                                    label={t('Description')}
                                    rows={4}
                                    errorMessages={errors.cover?.description}
                                    value={tmpStructure.cover.description}
                                    onUpdate={value => onUpdateCover({ [CoverModel.description]: value })}
                                />
                                <ElementsGroup>
                                    <UploadImage
                                        label={t('Cover image')}
                                        value={tmpStructure.cover.image}
                                        onUpdate={value => onUpdateCover({ [CoverModel.image]: value })}
                                    />
                                    <TextInput
                                        isRequired
                                        label={t('Button text')}
                                        errorMessages={errors.cover?.buttonText}
                                        value={tmpStructure.cover.buttonText}
                                        onUpdate={value => onUpdateCover({ [CoverModel.buttonText]: value })}
                                    />
                                </ElementsGroup>
                                {tmpStructure.cover.image && (
                                    <TextInput
                                        label={t('Image disclaimer')}
                                        isOptional
                                        value={tmpStructure.cover.imageDisclaimer}
                                        onUpdate={value => onUpdateCover({ [CoverModel.imageDisclaimer]: value })}
                                    />
                                )}
                            </div>
                        </div>
                    </ScrollableBlock>
                </div>
                <div className="preview">
                    <ScrollableBlock>
                        <div className="scrollbar-inner">
                            <p className="title">{t('Approximate preview')}</p>
                            <div className={`content${!tmpStructure.cover.isShowCover ? ' is-hidden' : ''}`}>
                                {tmpStructure.cover.image ||
                                tmpStructure.cover.header.length ||
                                tmpStructure.cover.description.length ||
                                tmpStructure.cover.buttonText.length ? (
                                    <>
                                        {!!tmpStructure.cover.image && (
                                            <div className="image">
                                                <Image src={tmpStructure.cover.image} alt="img" />
                                            </div>
                                        )}
                                        {!!tmpStructure.cover.header.length && (
                                            <div className="header" style={{ fontFamily }}>
                                                {tmpStructure.cover.header.trim()}
                                            </div>
                                        )}
                                        {!!tmpStructure.cover.description.length && (
                                            <div className="description" style={{ fontFamily }}>
                                                {tmpStructure.cover.description.trim()}
                                            </div>
                                        )}
                                        {!!tmpStructure.cover.buttonText.length && (
                                            <div
                                                className="button"
                                                style={{
                                                    backgroundColor: colorTheme,
                                                    color: invertColor(colorTheme, true),
                                                    fontFamily,
                                                }}
                                            >
                                                {tmpStructure.cover.buttonText}
                                            </div>
                                        )}
                                        {tmpStructure.cover.image && !!tmpStructure.cover.imageDisclaimer.length && (
                                            <div className="image-disclaimer" style={{ fontFamily }}>
                                                {tmpStructure.cover.imageDisclaimer.trim()}
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <p className="empty">{t('You have not entered any data yet')}</p>
                                )}
                            </div>
                        </div>
                    </ScrollableBlock>
                </div>
            </div>
        </div>
    )
}

export default StartView
