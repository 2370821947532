import React, {useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import TextArea from 'components/Form/TextArea/TextArea'

import useHovered from "hooks/useHovered"

import ActionsDropdown, { TYPES } from '../../../components/ActionsDropdown/ActionsDropdown'

import './Page.scss'


const Page = ({ page, isSelected, index, arrayLength, methods, draggable }) => {
    const { t } = useTranslation()
    const [name, setName] = useState(page.name || '')
    const [isShowInput, setIsShowInput] = useState(false)
    const [isHovered, onMouseOver, onMouseLeave] = useHovered()

    const onClickRename = () => {
        setIsShowInput(true)
    }

    const onApplyRename = () => {
        const newName = name.trim()
        methods.onRenamePage(page.id, newName)
        setName(newName)
        setIsShowInput(false)
    }

    return (
        <div className="internal-pages__wrapper" {...draggable}>
            <div
                className={classNames('internal-pages__page', {'select': isSelected})}
                onClick={() => methods.onSelectPage(page)}
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
            >
                <div className="internal-pages__page-label">
                    {isShowInput ? (
                        <>
                            <div className="internal-pages__page-input-wrapper" onClick={onApplyRename}/>
                            <TextArea
                                className="internal-pages__page-input"
                                value={name}
                                onUpdate={val => setName(val)}
                                rows={2}
                                maxLength={18}
                                autoFocus
                                onFocus={evt => evt.target.select()}
                            />
                        </>
                    ) : (
                        page.name || `${t('Page')} ${index + 1}`
                    )}
                </div>

                <div className={classNames('internal-pages__page-img', {'is-has-blocks': !!page.blocks.length})}/>

                <ActionsDropdown
                    index={index}
                    type={TYPES.PAGE}
                    arrayLength={arrayLength}
                    isVisible={isHovered || isSelected}
                    methods={{
                        onClickRename,
                        onRemove: () => methods.onRemove(page.id),
                        onClone: () => methods.onClone(page.id),
                        onChangePosition: methods.onChangePosition,
                    }}
                />
            </div>
        </div>
    )
}

export default Page
