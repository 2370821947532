import {
    PROJECT_MODES,
    CONSTRUCTOR_PARAMS,
    CONSTRUCTOR_VALIDATION_ERROR_TYPES,
    DEFAULT_LOCALE_CODE,
    SSR_SUPPORTED_LOCALES,
} from './constants'

import translations from './translations'

const isElementIsBody = el => el === document.body

const getElementClientRect = el => el.getBoundingClientRect()

export const httpRequest = async (resource, options) => {
    const { timeout = 30000 } = options
    const controller = new AbortController()
    const id = setTimeout(() => controller.abort(), timeout)
    const response = await fetch(resource, {
        ...options,
        signal: controller.signal,
        credentials: 'include',
    })
    clearTimeout(id)

    if (!response.ok) {
        throw new Error('Request is failed')
    }

    return response
}

export const validator = {
    isValue: value => {
        return value !== void 0 && value !== null
    },
    isString: value => {
        return typeof value === 'string'
    },
    isJsonString: value => {
        try {
            return JSON.parse(value) && !!value
        } catch (err) {
            return false
        }
    },
    isURL: value => {
        try {
            const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
            return regexp.test(value)
        } catch (err) {
            return false
        }
    },
    isNumber: value => {
        return typeof value === 'number' && isFinite(value)
    },
    isObject: value => {
        return typeof value === 'object'
    },
    isArray: value => {
        return Array.isArray(value)
    },
    isFunction: value => {
        return typeof value === 'function'
    },
}

export const throttle = (func, delay = 0, isEnsure = true) => {
    let lastCall = Number.NEGATIVE_INFINITY,
        wait,
        handle
    return (...args) => {
        wait = lastCall + delay - Date.now()
        clearTimeout(handle)
        if (wait <= 0 || isEnsure) {
            handle = setTimeout(() => {
                func(...args)
                lastCall = Date.now()
            }, wait)
        }
    }
}

export const getElementYOffset = el => (isElementIsBody(el) ? window.pageYOffset : el.scrollTop)

export const getElementInnerHeight = el => (isElementIsBody(el) ? window.innerHeight : el.clientHeight)

export const getScrollReadyElement = el => (isElementIsBody(el) ? window : el)

export const getElementPositions = (el, iFrame) => {
    const positions = {
        top: undefined,
        left: undefined,
        height: undefined,
        bottom: undefined,
    }
    const iFrameRect = getElementClientRect(iFrame)
    if (isElementIsBody(el)) {
        positions.top = iFrameRect.top
        positions.left = iFrameRect.left
        positions.height = iFrameRect.height
        positions.bottom = iFrameRect.bottom
    } else {
        positions.top = iFrameRect.top - getElementClientRect(el).top
        positions.left = el.scrollLeft
        positions.height = el.scrollHeight
        positions.bottom = positions.top + positions.height
    }
    return positions
}

export const getScrollableParent = el => {
    const div = document.createElement('div')
    div.style.height = `${2 * window.innerHeight}px`
    el.appendChild(div)

    const isScrollable = _el => {
        const hasScrollableContent = _el.scrollHeight > _el.clientHeight
        if (!hasScrollableContent) return false

        const overflowYStyle = window.getComputedStyle(_el).overflowY
        const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1

        return hasScrollableContent && !isOverflowHidden
    }
    const checkElement = _el => {
        if (!_el || _el === document.body) return document.body

        return isScrollable(_el) ? _el : checkElement(_el.parentNode)
    }

    const scrollableParent = checkElement(el)
    while (el.firstChild) {
        el.removeChild(el.lastChild)
    }
    return scrollableParent
}

export const throwExceptionManually = (initiator, data) => {
    let errorMessage = '[RemixLoader] Unhandled exception'

    if (initiator === 'CV') {
        const errorPrefix = '[RemixLoader | CONSTRUCTOR VALIDATOR]'
        switch (data.type) {
            case CONSTRUCTOR_VALIDATION_ERROR_TYPES.UNDEFINED: {
                errorMessage = `${errorPrefix} Field "${data.key}" is required. Received value: "${data.value}"`
                break
            }
            case CONSTRUCTOR_VALIDATION_ERROR_TYPES.UNKNOWN: {
                errorMessage = `${errorPrefix} Unknown field: "${data.key}"`
                break
            }
            case CONSTRUCTOR_VALIDATION_ERROR_TYPES.FORMAT: {
                errorMessage = `${errorPrefix} Invalid field "${data.key}" format! Expected type: "${
                    data.expected
                }". Received type: "${typeof data.value}", value: "${data.value}"`
                break
            }
            case CONSTRUCTOR_VALIDATION_ERROR_TYPES.VALUE: {
                errorMessage = `${errorPrefix} Invalid field "${
                    data.key
                }" value! Expected values: "${data.expected.join(', ')}". Received value: "${data.value}"`
                break
            }
            case CONSTRUCTOR_VALIDATION_ERROR_TYPES.INTERNAL: {
                errorMessage = `${errorPrefix} Internal error! Validating field: "${data.key}". Received value: "${data.value}"`
                break
            }
            default:
                break
        }
    }

    const error = new Error(errorMessage)
    error.name = 'ManuallyException'
    throw error
}

export const validateConstructorParam = (key, value, required = true, defaultValue) => {
    try {
        // check value and return (formatted or error)
        if (validator.isValue(value)) {
            switch (key) {
                case CONSTRUCTOR_PARAMS.MODE:
                case CONSTRUCTOR_PARAMS.HASH:
                case CONSTRUCTOR_PARAMS.PROJECT_ID:
                case CONSTRUCTOR_PARAMS.SUBSCRIPTION_PRODUCT_CODE:
                case CONSTRUCTOR_PARAMS.LANGUAGE: {
                    if (validator.isString(value)) return value
                    return throwExceptionManually('CV', {
                        type: CONSTRUCTOR_VALIDATION_ERROR_TYPES.FORMAT,
                        key,
                        value,
                        expected: 'String',
                    })
                }
                case CONSTRUCTOR_PARAMS.PROJECT_MODE: {
                    if (!validator.isString(value)) {
                        return throwExceptionManually('CV', {
                            type: CONSTRUCTOR_VALIDATION_ERROR_TYPES.FORMAT,
                            key,
                            value,
                            expected: 'String',
                        })
                    }
                    if (Object.values(PROJECT_MODES).includes(value)) return value
                    return throwExceptionManually('CV', {
                        type: CONSTRUCTOR_VALIDATION_ERROR_TYPES.VALUE,
                        key,
                        value,
                        expected: Object.values(PROJECT_MODES),
                    })
                }
                case CONSTRUCTOR_PARAMS.NODE_ELEMENT: {
                    if (value instanceof Element || value instanceof HTMLDocument) return value
                    return throwExceptionManually('CV', {
                        type: CONSTRUCTOR_VALIDATION_ERROR_TYPES.FORMAT,
                        key,
                        value,
                        expected: 'HTMLElement',
                    })
                }
                case CONSTRUCTOR_PARAMS.REMIX_URL: {
                    if (validator.isURL(value)) return value
                    return throwExceptionManually('CV', {
                        type: CONSTRUCTOR_VALIDATION_ERROR_TYPES.FORMAT,
                        key,
                        value,
                        expected: 'String (URL)',
                    })
                }
                case CONSTRUCTOR_PARAMS.FEATURES: {
                    if (Array.isArray(value)) return value
                    return throwExceptionManually('CV', {
                        type: CONSTRUCTOR_VALIDATION_ERROR_TYPES.FORMAT,
                        key,
                        value,
                        expected: 'Array',
                    })
                }
                case CONSTRUCTOR_PARAMS.PROJECT_STRUCTURE: {
                    if (validator.isJsonString(value)) return JSON.parse(value)
                    return throwExceptionManually('CV', {
                        type: CONSTRUCTOR_VALIDATION_ERROR_TYPES.FORMAT,
                        key,
                        value,
                        expected: 'String (JSON)',
                    })
                }
                case CONSTRUCTOR_PARAMS.PLAYER_INFO: {
                    if (validator.isObject(value)) return value
                    return throwExceptionManually('CV', {
                        type: CONSTRUCTOR_VALIDATION_ERROR_TYPES.FORMAT,
                        key,
                        value,
                        expected: 'Object',
                    })
                }
                case CONSTRUCTOR_PARAMS.INITIAL_WIDTH:
                case CONSTRUCTOR_PARAMS.INITIAL_HEIGHT:
                case CONSTRUCTOR_PARAMS.PROJECT_NUMERIC_ID:
                case CONSTRUCTOR_PARAMS.ADDITIONAL_TOP_OFFSET: {
                    if (validator.isNumber(value)) return value
                    return throwExceptionManually('CV', {
                        type: CONSTRUCTOR_VALIDATION_ERROR_TYPES.FORMAT,
                        key,
                        value,
                        expected: 'Number',
                    })
                }
                case CONSTRUCTOR_PARAMS.ON_EVENT: {
                    if (validator.isFunction(value)) return value
                    return throwExceptionManually('CV', {
                        type: CONSTRUCTOR_VALIDATION_ERROR_TYPES.FORMAT,
                        key,
                        value,
                        expected: 'Function',
                    })
                }
                default:
                    return throwExceptionManually('CV', { type: CONSTRUCTOR_VALIDATION_ERROR_TYPES.UNKNOWN, key })
            }
        }
        // value required and not defined - throw error
        if (required) {
            return throwExceptionManually('CV', { type: CONSTRUCTOR_VALIDATION_ERROR_TYPES.UNDEFINED, key, value })
        }
        // return default value
        return defaultValue
    } catch (err) {
        if (err.name === 'ManuallyException') {
            throw err
        } else {
            return throwExceptionManually('CV', { type: CONSTRUCTOR_VALIDATION_ERROR_TYPES.INTERNAL, key, err })
        }
    }
}

// Get language from window.navigator
export const getWindowLanguage = () => {
    try {
        const language = window.navigator
            ? window.navigator.language || window.navigator.systemLanguage || window.navigator.userLanguage
            : null
        return language ? language.slice(0, 2).toLowerCase() : null
    } catch (err) {
        return null
    }
}

export const getWindowLocation = () => ({
    host: window.location.host,
    hostname: window.location.hostname,
    href: window.location.href,
    origin: window.location.origin,
    pathname: window.location.pathname,
    port: window.location.port,
    protocol: window.location.protocol,
})
export const getWindowQueryParams = () => Object.fromEntries(new URLSearchParams(window.location.search).entries())

export const onSendApiEvent = (event, payload = {}, meta = {}) => {
    try {
        if (!event) return
        if (!!window.onRemixEvent) window.onRemixEvent(event, payload, meta)
        if (!!window.onInteractyEvent) window.onInteractyEvent(event, payload, meta)
    } catch (err) {
        console.error(err)
    }
}

export const getFullUrlToSsr = (locale, path = '') => {
    let prefix = ''
    if (SSR_SUPPORTED_LOCALES.includes(locale) && locale !== DEFAULT_LOCALE_CODE) prefix = `/${locale}`
    return process.env.REACT_APP_SSR_URL + prefix + path
}

export const loadFonts = (hrefs = []) => {
    try {
        const link1 = document.createElement('link')
        link1.setAttribute('rel', 'preconnect')
        link1.setAttribute('href', 'https://fonts.googleapis.com')
        document.head.appendChild(link1)

        const link2 = document.createElement('link')
        link2.setAttribute('rel', 'preconnect')
        link2.setAttribute('href', 'https://fonts.gstatic.com')
        link2.setAttribute('crossorigin', 'true')
        document.head.appendChild(link2)

        hrefs.forEach(href => {
            const link = document.createElement('link')
            link.setAttribute('rel', 'stylesheet')
            link.setAttribute('href', href)
            document.head.appendChild(link)
        })
    } catch (err) {
        console.error(err)
    }
}

export const getTranslation = (lng, key) => {
    if (lng && lng === DEFAULT_LOCALE_CODE) return key

    if (key in translations && lng in translations[key]) return translations[key][lng]

    return null
}

export const openFullscreen = element => {
    if (element.requestFullscreen) {
        element.requestFullscreen()
    } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen()
    } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen()
    }
}
export const closeFullscreen = () => {
    document.exitFullscreen()
}

export const isTouchDevice = () =>
    'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
