import { cloneDeep } from 'lodash'
import { API__WEBHOOKS } from 'api'
import pagesService from 'common/services/PagesService'
import store from 'store'

import BLOCKS from "shared/constants/blocks"

import { BLOCK_SCHEMA } from 'pages/Editor/Tabs/Editor/schema'
import Normalizer from 'pages/Editor/Tabs/Editor/util/normalizer'
import DataSchema from 'pages/Editor/Tabs/Editor/util/schema'
import { NAVIGATION_TYPES } from "pages/Editor/Tabs/Editor/constants"

import { getUniqueId } from '../utils/getUniqueId'

function addItem({ navigationType, pages, blocks, pageId, insertBeforeId, blockType, blockData, blockId = null }) {
    if (!Object.keys(NAVIGATION_TYPES).includes(navigationType)) {
        throw new Error('Bad property: "navigationType"')
    }

    if (navigationType === NAVIGATION_TYPES.PAGES) {
        if (!pages || !pageId) throw new Error('Cannot find required params: "pages, pageId"')

        const newPages = pages.map(page => (page.id === pageId ? cloneDeep(page) : page))
        const updatedPage = pagesService.getPageById(newPages, pageId)

        let insertBeforeIndex = updatedPage.blocks.length
        if (insertBeforeId) {
            insertBeforeIndex = _getBlockIndexById(updatedPage.blocks, insertBeforeId)
            if (insertBeforeIndex === -1) throw Error('Not found block with id: ' + insertBeforeId)
        }

        const id = blockId || getUniqueId()
        const newBlock = _createItem(blockType, blockData, id)
        updatedPage.blocks.splice(insertBeforeIndex, 0, newBlock)
        return { newBlock, newPages, updatedPage }
    }

    if (navigationType === NAVIGATION_TYPES.AUTH) {
        if (!blocks) throw new Error('Cannot find required params: "blocks"')

        const newBlocks = cloneDeep(blocks)

        let insertBeforeIndex = newBlocks.length
        if (insertBeforeId) {
            insertBeforeIndex = _getBlockIndexById(newBlocks, insertBeforeId)
            if (insertBeforeIndex === -1) throw Error('Not found block with id: ' + insertBeforeId)
        }

        const id = blockId || getUniqueId()
        const newBlock = _createItem(blockType, blockData, id)
        newBlocks.splice(insertBeforeIndex, 0, newBlock)
        return { newBlocks, newBlock }
    }
}

async function cloneItem({ navigationType, pages, pageId, blocks, blockId }) {
    if (!Object.keys(NAVIGATION_TYPES).includes(navigationType)) {
        throw new Error('Bad property: "navigationType"')
    }

    if (navigationType === NAVIGATION_TYPES.PAGES) {
        if (!pages || !pageId) throw new Error('Cannot find required params: "pages, pageId"')

        const newPages = pages.map(page => (page.id === pageId ? cloneDeep(page) : page))
        const updatedPage = pagesService.getPageById(newPages, pageId)
        const blockIndex = _getBlockIndexById(updatedPage.blocks, blockId)

        const sourceBlock = updatedPage.blocks[blockIndex]
        const clonedBlock = _createItem(sourceBlock.t, sourceBlock, getUniqueId())
        updatedPage.blocks.splice(blockIndex + 1, 0, clonedBlock)

        return { newPages, updatedPage, clonedBlock }
    }
    if (navigationType === NAVIGATION_TYPES.AUTH) {
        if (!blocks) throw new Error('Cannot find required params: "blocks"')

        const newBlocks = cloneDeep(blocks)

        const blockIndex = _getBlockIndexById(newBlocks, blockId)

        const sourceBlock = newBlocks[blockIndex]
        const clonedBlock = _createItem(sourceBlock.t, sourceBlock, getUniqueId())
        newBlocks.splice(blockIndex + 1, 0, clonedBlock)

        return { newBlocks, clonedBlock }
    }
}

async function deleteItem({ navigationType, pages, pageId, blocks, blockId, projectId }) {
    if (!Object.keys(NAVIGATION_TYPES).includes(navigationType)) {
        throw new Error('Bad property: "navigationType"')
    }

    const {organizations: { selectedOrganizationId }} = store.getState()

    if (navigationType === NAVIGATION_TYPES.PAGES) {
        if (!pages || !pageId) throw new Error('Cannot find required params: "pages, pageId"')

        const newPages = pages.map(page => (page.id === pageId ? cloneDeep(page) : page))
        const updatedPage = pagesService.getPageById(newPages, pageId)
        const blockIndex = _getBlockIndexById(updatedPage.blocks, blockId)
        updatedPage.blocks.splice(blockIndex, 1)

        await API__WEBHOOKS.DELETE_BLOCKS_WEBHOOKS({
            organizationId: selectedOrganizationId,
            blockIds: [blockId],
            projectId,
        })
        return { newPages, updatedPage }
    }
    if (navigationType === NAVIGATION_TYPES.AUTH) {
        if (!blocks) throw new Error('Cannot find required params: "blocks"')

        const newBlocks = cloneDeep(blocks)

        const blockIndex = _getBlockIndexById(newBlocks, blockId)
        newBlocks.splice(blockIndex, 1)

        await API__WEBHOOKS.DELETE_BLOCKS_WEBHOOKS({
            organizationId: selectedOrganizationId,
            blockIds: [blockId],
            projectId,
        })
        return { newBlocks }
    }
}

function moveUp({ navigationType, pages, pageId, blocks, blockId }) {
    if (!Object.keys(NAVIGATION_TYPES).includes(navigationType)) {
        throw new Error('Bad property: "navigationType"')
    }

    if (navigationType === NAVIGATION_TYPES.PAGES) {
        if (!pages || !pageId) throw new Error('Cannot find required params: "pages, pageId"')

        const newPages = pages.map(page => (page.id === pageId ? cloneDeep(page) : page))
        const updatedPage = pagesService.getPageById(newPages, pageId)

        const blockIndex = _getBlockIndexById(updatedPage.blocks, blockId)

        const movedBlock = updatedPage.blocks[blockIndex - 1]
        updatedPage.blocks[blockIndex - 1] = updatedPage.blocks[blockIndex]
        updatedPage.blocks[blockIndex] = movedBlock

        return { newPages, updatedPage, movedBlock }
    }
    if (navigationType === NAVIGATION_TYPES.AUTH) {
        if (!blocks) throw new Error('Cannot find required params: "blocks"')

        const newBlocks = cloneDeep(blocks)

        const blockIndex = _getBlockIndexById(newBlocks, blockId)

        const movedBlock = newBlocks[blockIndex - 1]
        newBlocks[blockIndex - 1] = newBlocks[blockIndex]
        newBlocks[blockIndex] = movedBlock

        return { newBlocks, movedBlock }
    }
}

function moveDown({ navigationType, pages, pageId, blocks, blockId }) {
    if (!Object.keys(NAVIGATION_TYPES).includes(navigationType)) {
        throw new Error('Bad property: "navigationType"')
    }

    if (navigationType === NAVIGATION_TYPES.PAGES) {
        if (!pages || !pageId) throw new Error('Cannot find required params: "pages, pageId"')

        const newPages = pages.map(page => (page.id === pageId ? cloneDeep(page) : page))
        const updatedPage = pagesService.getPageById(newPages, pageId)

        const index = _getBlockIndexById(updatedPage.blocks, blockId)

        const movedBlock = updatedPage.blocks[index + 1]
        updatedPage.blocks[index + 1] = updatedPage.blocks[index]
        updatedPage.blocks[index] = movedBlock
        return { newPages, updatedPage, movedBlock }
    }
    if (navigationType === NAVIGATION_TYPES.AUTH) {
        if (!blocks) throw new Error('Cannot find required params: "blocks"')

        const newBlocks = cloneDeep(blocks)

        const blockIndex = _getBlockIndexById(newBlocks, blockId)

        const movedBlock = newBlocks[blockIndex + 1]
        newBlocks[blockIndex + 1] = newBlocks[blockIndex]
        newBlocks[blockIndex] = movedBlock

        return { newBlocks, movedBlock }
    }
}

function _createItem(blockType, data, blockId) {
    blockType = parseInt(blockType)

    return _normalizeBlock({
        ...data,
        id: blockId,
        t: blockType,
    })
}
function _normalizeBlock(block) {
    for (const p in block) {
        if (Array.isArray(block[p])) {
            const pSchema = BLOCK_SCHEMA[`${block.t}.${p}`]
            if (pSchema) {
                // Если задана схема типа propName.subProp для массива, то нормализуем по ней все элементы массива
                const pNorm = new Normalizer(new DataSchema(pSchema))
                block[p] = block[p].map(e => pNorm.process(e))
            }
        }
    }
    const s = BLOCK_SCHEMA[block.t]
    const n = new Normalizer(new DataSchema(s))
    return n.process(block)
}
function _getBlockIndexById(blocks, blockId) {
    const blockIndex = blocks.findIndex(el => el.id === blockId)
    if (blockIndex === -1) throw Error('Invalid block id for clone operation: ' + blockId)
    return blockIndex
}

export const createDefaultBlock = blockTypeId => {
    if (blockTypeId === BLOCKS.authForm) {
        return _createItem(BLOCKS.authForm, {}, getUniqueId())
    }

    return new Error('Unsupported block ID:', blockTypeId)
}

export default {
    addItem,
    cloneItem,
    deleteItem,
    moveUp,
    moveDown,
}
