import React, {useRef, useState, useCallback} from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import ConfirmationDialog from 'components/Modal/ConfirmationDialog/ConfirmationDialog'
import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'

import storage from 'common/utils/storage'

import { CONDITIONS, hasPermission } from "common/utils/permissions"

import MobileTongue from '../components/MobileTongue/MobileTongue'
import { DIRECTIONS } from '../components/MobileTongue/constants'
import PanelHeader from '../components/PanelHeader/PanelHeader'

import AddInternalPageModal from '../../Modals/AddInternalPageModal/AddInternalPageModal'

import Page from './components/Page/Page'
import AuthPage from './components/Page/AuthPage'

import './InternalPages.scss'

const InternalPages = ({
    app,
    pages,
    isAuthSelected = false,
    selectedPage,
    onSelectPage,
    onAddPage,
    onRenamePage,
    onClonePage,
    onDeletePage,
    onChangePosition,
    onDeleteAuth,
    onSelectAuth,
}) => {
    const { t } = useTranslation()
    const [isOpenAddInternalPageModal, setIsOpenAddInternalPageModal] = useState(false)
    const [clonedPageId, setClonedPageId] = useState(null)
    const [isOpenPageRemoveConfirmationDialog, setIsOpenPageRemoveConfirmationDialog] = useState(false)
    const [isOpenAuthRemoveConfirmationDialog, setIsOpenAuthRemoveConfirmationDialog] = useState(false)
    const [deletedPageId, setDeletedPageId] = useState(null)
    const panelScrollRef = useRef()
    const [isMobileOpened, setIsOpenedMobile] = useState(false)
    const [isDragging, setIsDragging] = useState(false)

    const onClickAddInternalPageButton = () => {
        setClonedPageId(null)
        if (pages.length === 1) {
            onOpenAddInternalPageModal()
            return
        }
        onAddPage()
    }
    const onClickClonePage = id => {
        if (pages.length === 1) {
            setClonedPageId(id)
            onOpenAddInternalPageModal()
            return
        }
        onClonePage(id)
    }

    const onOpenAddInternalPageModal = () => {
        const isSkipModal = !!storage.getValue('DONT_SHOW_HOW_ADD_INTERNAL_PAGE')
        if (isSkipModal) addPage()
        else setIsOpenAddInternalPageModal(true)
    }

    const onOpenPageRemoveConfirmationDialog = id => {
        setDeletedPageId(id)
        setIsOpenPageRemoveConfirmationDialog(true)
    }

    const deletePage = () => {
        onDeletePage(deletedPageId)
        setIsOpenPageRemoveConfirmationDialog(false)
    }
    const addPage = () => {
        if (clonedPageId) onClonePage(clonedPageId)
        else onAddPage()
    }

    const handleOnDragStart = useCallback(() => {
        setIsDragging(true)
    }, [])
    const handleOnDragEnd = useCallback(result => {
        setIsDragging(false)
        if (!result.destination) return
        onChangePosition(result.source.index, result.destination.index)
    }, [])

    return (
        <div className={classNames('internal-pages', { 'internal-pages--opened': isMobileOpened })}>
            <MobileTongue
                isOpened={isMobileOpened}
                onChange={value => setIsOpenedMobile(value)}
                direction={DIRECTIONS.LEFT}
                closeText={t('Close pages')}
                openText={t('Open pages')}
            />
            <PanelHeader className="internal-pages__title" blockName={t('Pages')} scrollRef={panelScrollRef} />
            <ScrollableBlock styles={{ height: 'calc(100% - 56px)', width: '100%' }} scrollableNodeRef={panelScrollRef}>
                <DragDropContext onDragStart={handleOnDragStart} onDragEnd={handleOnDragEnd}>
                    <Droppable key={'droppable-1'} droppableId={'droppable-1'}>
                        {providedDroppable => (
                            <div
                                ref={providedDroppable.innerRef}
                                {...providedDroppable.droppableProps}
                                className="internal-pages__page-list"
                            >
                                {app.isUseAuthorization && hasPermission(CONDITIONS.CAN_USE_AUTHORIZATION) && (
                                    <AuthPage
                                        isSelected={isAuthSelected}
                                        methods={{
                                            onSelect: onSelectAuth,
                                            onRemove: () => setIsOpenAuthRemoveConfirmationDialog(true),
                                        }}
                                    />
                                )}
                                {pages.map((page, index) => (
                                    <Draggable key={page.id} draggableId={page.id} index={index}>
                                        {providedDraggable => (
                                            <Page
                                                draggable={{
                                                    ref: providedDraggable.innerRef,
                                                    ...providedDraggable.draggableProps,
                                                    ...providedDraggable.dragHandleProps,
                                                }}
                                                key={page.id}
                                                page={page}
                                                isSelected={!isAuthSelected && selectedPage.id === page.id}
                                                index={index}
                                                arrayLength={pages.length}
                                                methods={{
                                                    onSelectPage,
                                                    onRenamePage,
                                                    onRemove: onOpenPageRemoveConfirmationDialog,
                                                    onClone: onClickClonePage,
                                                    onChangePosition,
                                                }}
                                            />
                                        )}
                                    </Draggable>
                                ))}
                                {providedDroppable.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <div
                    className={classNames("internal-pages__add-button", {"internal-pages__add-button--is-disabled": isDragging})}
                    onClick={onClickAddInternalPageButton}
                >
                    {t('Add page')}
                </div>
            </ScrollableBlock>
            {isOpenPageRemoveConfirmationDialog && (
                <ConfirmationDialog
                    onClose={() => setIsOpenPageRemoveConfirmationDialog(false)}
                    onAction={deletePage}
                    data={{
                        headText: t('Are you sure you want to delete this page?'),
                        noteText: t('All blocks on this page will also be deleted.'),
                        actionVariant: 'primary-danger',
                        actionText: t('Delete'),
                        cancelText: t('Cancel'),
                    }}
                />
            )}
            {isOpenAuthRemoveConfirmationDialog && (
                <ConfirmationDialog
                    onClose={() => setIsOpenAuthRemoveConfirmationDialog(false)}
                    onAction={() => {
                        onDeleteAuth()
                        setIsOpenAuthRemoveConfirmationDialog(false)
                    }}
                    data={{
                        headText: t('Удалить страницу входа?'),
                        noteText: t('При удалении этой страницы авторизация в проекте будет отключена, и данные о пользователях собираться не будут.'),
                        actionVariant: 'primary-danger',
                        actionText: t('Delete'),
                        cancelText: t('Cancel'),
                    }}
                />
            )}

            <AddInternalPageModal
                isOpen={isOpenAddInternalPageModal}
                onAddPage={addPage}
                onClose={() => setIsOpenAddInternalPageModal(false)}
            />
        </div>
    )
}

export default InternalPages
