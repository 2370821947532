import React, { useState } from 'react'
import classNames from 'classnames'

import Toast from 'components/Toast/Toast'

import ChooseImageBox from 'components/Modal/ChooseImageBox/ChooseImageBox'

import { USER_PUBLIC_PROFILE_COVER_URLS } from 'common/constants/user'

import styles from './Card.module.scss'

export default ({
    title,
    icon,
    cover,
    isWide = false,
    description,
    mark,
    onChangeCover,
    isDisabled = false,
    badgeIcon,
    isOnlyContent = false,
    children,
}) => {
    const [isLoadingCover, setIsLoadingCover] = useState(false)

    return (
        <div className={classNames(styles.card, { [styles.isWideCard]: isWide })}>
            <div className={classNames(styles.box, { [styles.isCoverBox]: !!cover, [styles.isWideBox]: isWide })}>
                {!isOnlyContent && (
                    <div
                        className={classNames(styles.head, {
                            [styles.isWideHead]: isWide,
                            [styles.isCoverHead]: !!cover,
                        })}
                        style={{backgroundImage: cover ? `url(${cover})` : 'none'}}
                    >
                        <h2 className={classNames(styles.title, {[styles.isCoverTitle]: !!cover})}>
                            {icon && <img src={icon} alt="icon"/>}
                            {title}
                            {!!badgeIcon && (
                                <div className={styles.badgeIcon}>
                                    <img src={badgeIcon} alt=""/>
                                </div>
                            )}
                        </h2>
                        {!!mark && <div className={styles.mark}>{mark}</div>}
                        {!!cover && (
                            <ChooseImageBox
                                isLoading={isLoadingCover}
                                selectedImageUrl={cover}
                                urlsArray={USER_PUBLIC_PROFILE_COVER_URLS}
                                onChange={async url => {
                                    setIsLoadingCover(true)
                                    try {
                                        await onChangeCover(url)
                                    } catch (err) {
                                        console.error(err)
                                        Toast('error', {})
                                    } finally {
                                        setIsLoadingCover(false)
                                    }
                                }}
                            />
                        )}
                    </div>
                )}
                <div className={classNames(styles.content, {[styles.isDisabledContent]: isDisabled})}>
                    {!!description && (
                        <div className={classNames(styles.description, {[styles.isCoverDescription]: !!cover})}>
                            {description}
                        </div>
                    )}
                    {children}
                </div>
            </div>
        </div>
    )
}
