import { useEffect, useState } from 'react'
import { Popover as ReactTinyPopover } from 'react-tiny-popover'
import useKeypress from 'react-use-keypress'
import { useTranslation } from 'react-i18next'

import { REMIX_SUPPORTED_FONTS } from 'shared/constants/fonts'

import useIsMount from 'hooks/useIsMount'
import useClickOutside from 'hooks/useClickOutside'

import { TEXT_EDITOR_MODES } from '../../TextEditor'

import Popover from './components/Popover/Popover'
import List from './components/Popover/components/List/List'
import ListGroup from './components/Popover/components/ListGroup/ListGroup'
import ListItem from './components/Popover/components/ListItem/ListItem'
import ColorPicker from './components/Popover/components/ColorPicker/ColorPicker'
import LinkEditor from './components/Popover/components/LinkEditor/LinkEditor'

import Button from './components/Button/Button'
import Separator from './components/Separator/Separator'

import { CONTROLS, ICONS, CONTROL_PARAMS, TEXT_PARAMS, FONT_SIZES, LINE_HEIGHTS, LETTER_SPACINGS } from './constants'
import {
    getActiveHierarchyAndListsIcon,
    getActiveListIcon,
    isActive,
    getButtonParentText,
    getActiveAlignIcon,
    getActiveAlign,
    getActiveFontFamily,
    getActiveFontSize,
    getActiveLineHeight,
    getActiveLetterSpacing,
    getNumberedFontSize,
} from './utils'

import styles from './ClassicToolbar.module.scss'

const ClassicToolbar = ({ editor, mode, toolbarRef, padding, children }) => {
    const { t } = useTranslation()
    const [isVisible, setIsVisible] = useState(false)
    const isMount = useIsMount()
    const [isHeadingFocused, setIsHeadingFocused] = useState(false)

    useKeypress('Tab', evt => {
        if (editor.isFocused) evt.preventDefault()
    })
    useEffect(() => {
        setIsHeadingFocused(editor.isFocused && isActive(editor, CONTROLS.HEADING))
    }, [editor.isFocused, isActive(editor, CONTROLS.HEADING)])

    useEffect(() => {
        if (isMount) return
        if (!isHeadingFocused && editor.isFocused && editor.state.selection.empty) {
            editor.chain().focus().setFontSize('16px').run()
            editor.chain().focus().unsetBold().run()
        }
    }, [isHeadingFocused])

    useClickOutside(toolbarRef, () => {
        if (!editor.isFocused) setIsVisible(false)
    })

    useEffect(() => {
        if (editor.isFocused) setIsVisible(true)
    }, [editor.isFocused])

    if (!editor) return null

    return (
        <ReactTinyPopover
            isOpen={isVisible}
            positions={['top']}
            containerClassName={styles.popover}
            padding={padding}
            content={
                <div ref={toolbarRef} className={styles.classicToolbar}>
                    {mode !== TEXT_EDITOR_MODES.BUTTON && (
                        <Popover
                            parentRef={toolbarRef}
                            trigger={
                                <Button label={t('Styles')} isDropdown icon={getActiveHierarchyAndListsIcon(editor)} />
                            }
                        >
                            <List>
                                <ListGroup title={t('Hierarchy')}>
                                    <ListItem
                                        icon={ICONS.PARAGRAPH}
                                        isActive={isActive(editor, CONTROLS.PARAGRAPH) && !getActiveListIcon(editor)}
                                        onClick={() => {
                                            editor.commands.setParagraph()
                                            editor.chain().focus().setFontSize('16px').run()
                                            editor.chain().focus().unsetBold().run()
                                        }}
                                    >
                                        {t('Paragraph')}
                                    </ListItem>
                                    <ListItem
                                        icon={ICONS.HEADING.LEVEL_1}
                                        isActive={isActive(editor, CONTROLS.HEADING, {
                                            [CONTROL_PARAMS.HEADING.LEVEL]: 1,
                                        })}
                                        onClick={() => {
                                            editor.commands.setHeading({ [CONTROL_PARAMS.HEADING.LEVEL]: 1 })
                                            editor.chain().focus().setFontSize('30px').run()
                                            editor.chain().focus().setBold().run()
                                        }}
                                    >
                                        {t('Heading')} 1
                                    </ListItem>
                                    <ListItem
                                        icon={ICONS.HEADING.LEVEL_2}
                                        isActive={isActive(editor, CONTROLS.HEADING, {
                                            [CONTROL_PARAMS.HEADING.LEVEL]: 2,
                                        })}
                                        onClick={() => {
                                            editor.commands.setHeading({ [CONTROL_PARAMS.HEADING.LEVEL]: 2 })
                                            editor.chain().focus().setFontSize('24px').run()
                                            editor.chain().focus().setBold().run()
                                        }}
                                    >
                                        {t('Heading')} 2
                                    </ListItem>
                                    <ListItem
                                        icon={ICONS.HEADING.LEVEL_3}
                                        isActive={isActive(editor, CONTROLS.HEADING, {
                                            [CONTROL_PARAMS.HEADING.LEVEL]: 3,
                                        })}
                                        onClick={() => {
                                            editor.commands.setHeading({ [CONTROL_PARAMS.HEADING.LEVEL]: 3 })
                                            editor.chain().focus().setFontSize('20px').run()
                                            editor.chain().focus().setBold().run()
                                        }}
                                    >
                                        {t('Heading')} 3
                                    </ListItem>
                                </ListGroup>
                                <ListGroup title={t('Lists')}>
                                    <ListItem
                                        icon={ICONS.BULLET_LIST}
                                        isActive={isActive(editor, CONTROLS.BULLET_LIST)}
                                        onClick={() => editor.commands.toggleBulletList()}
                                    >
                                        {t('Bullet list')}
                                    </ListItem>
                                    <ListItem
                                        icon={ICONS.ORDERED_LIST}
                                        isActive={isActive(editor, CONTROLS.ORDERED_LIST)}
                                        onClick={() => editor.commands.toggleOrderedList()}
                                    >
                                        {t('Numbered list')}
                                    </ListItem>
                                </ListGroup>
                            </List>
                        </Popover>
                    )}

                    <Popover
                        parentRef={toolbarRef}
                        trigger={
                            <Button
                                label={t('Font family')}
                                isDropdown
                                placeholder={t('Font family')}
                                style={{
                                    fontFamily: getActiveFontFamily(editor),
                                }}
                                width={80}
                            >
                                {getButtonParentText(getActiveFontFamily(editor))}
                            </Button>
                        }
                    >
                        <List>
                            <ListGroup>
                                {REMIX_SUPPORTED_FONTS.map(fontFamily => (
                                    <ListItem
                                        key={fontFamily}
                                        isActive={getActiveFontFamily(editor) === fontFamily}
                                        onClick={() => editor.chain().focus().setFontFamily(fontFamily).run()}
                                    >
                                        <span style={{ fontFamily }}>{fontFamily}</span>
                                    </ListItem>
                                ))}
                            </ListGroup>
                        </List>
                    </Popover>
                    <Popover
                        parentRef={toolbarRef}
                        trigger={
                            <Button
                                label={t('Font size')}
                                isDropdown
                                placeholder={t('Font size')}
                                width={32}
                                isCentered
                                onMinis={() =>
                                    editor
                                        .chain()
                                        .focus()
                                        .setFontSize(`${getNumberedFontSize(editor) - 1}px`)
                                        .run()
                                }
                                onPlus={() =>
                                    editor
                                        .chain()
                                        .focus()
                                        .setFontSize(`${getNumberedFontSize(editor) + 1}px`)
                                        .run()
                                }
                                isDisabledMinus={getNumberedFontSize(editor) <= 1}
                                isDisabledPlus={getNumberedFontSize(editor) >= 1000}
                            >
                                {getButtonParentText(getActiveFontSize(editor))}
                            </Button>
                        }
                    >
                        <List>
                            <ListGroup>
                                {FONT_SIZES.map(fontSize => (
                                    <ListItem
                                        key={fontSize}
                                        isActive={getActiveFontSize(editor) === fontSize}
                                        onClick={() => editor.chain().focus().setFontSize(fontSize).run()}
                                    >
                                        <span style={{ fontSize }}>{fontSize}</span>
                                    </ListItem>
                                ))}
                            </ListGroup>
                        </List>
                    </Popover>

                    <Separator />

                    {mode !== TEXT_EDITOR_MODES.BUTTON && (
                        <>
                            <Popover
                                parentRef={toolbarRef}
                                trigger={
                                    <Button label={t('Text align')} isDropdown icon={getActiveAlignIcon(editor)} />
                                }
                            >
                                <List>
                                    <ListGroup>
                                        <ListItem
                                            icon={ICONS.ALIGN.LEFT}
                                            isActive={
                                                getActiveAlign(editor) === TEXT_PARAMS.TEXT_ALIGN.VALUES.LEFT ||
                                                !getActiveAlign(editor)
                                            }
                                            onClick={() =>
                                                editor
                                                    .chain()
                                                    .focus()
                                                    .setTextAlign(TEXT_PARAMS.TEXT_ALIGN.VALUES.LEFT)
                                                    .run()
                                            }
                                        >
                                            {t('Align left')}
                                        </ListItem>
                                        <ListItem
                                            icon={ICONS.ALIGN.CENTER}
                                            isActive={isActive(editor, {
                                                [TEXT_PARAMS.TEXT_ALIGN.NAME]: TEXT_PARAMS.TEXT_ALIGN.VALUES.CENTER,
                                            })}
                                            onClick={() =>
                                                editor
                                                    .chain()
                                                    .focus()
                                                    .setTextAlign(TEXT_PARAMS.TEXT_ALIGN.VALUES.CENTER)
                                                    .run()
                                            }
                                        >
                                            {t('Align center')}
                                        </ListItem>
                                        <ListItem
                                            icon={ICONS.ALIGN.RIGHT}
                                            isActive={isActive(editor, {
                                                [TEXT_PARAMS.TEXT_ALIGN.NAME]: TEXT_PARAMS.TEXT_ALIGN.VALUES.RIGHT,
                                            })}
                                            onClick={() =>
                                                editor
                                                    .chain()
                                                    .focus()
                                                    .setTextAlign(TEXT_PARAMS.TEXT_ALIGN.VALUES.RIGHT)
                                                    .run()
                                            }
                                        >
                                            {t('Align right')}
                                        </ListItem>
                                        <ListItem
                                            icon={ICONS.ALIGN.JUSTIFY}
                                            isActive={isActive(editor, {
                                                [TEXT_PARAMS.TEXT_ALIGN.NAME]: TEXT_PARAMS.TEXT_ALIGN.VALUES.JUSTIFY,
                                            })}
                                            onClick={() =>
                                                editor
                                                    .chain()
                                                    .focus()
                                                    .setTextAlign(TEXT_PARAMS.TEXT_ALIGN.VALUES.JUSTIFY)
                                                    .run()
                                            }
                                        >
                                            {t('Justify')}
                                        </ListItem>
                                    </ListGroup>
                                </List>
                            </Popover>
                            <Popover
                                parentRef={toolbarRef}
                                trigger={<Button label={t('Line height')} isDropdown icon={ICONS.LINE_HEIGHT} />}
                            >
                                <List>
                                    <ListGroup>
                                        {LINE_HEIGHTS.map(lineHeight => (
                                            <ListItem
                                                key={lineHeight}
                                                isActive={getActiveLineHeight(editor) === lineHeight}
                                                onClick={() => editor.chain().focus().setLineHeight(lineHeight).run()}
                                            >
                                                <span>{lineHeight}</span>
                                            </ListItem>
                                        ))}
                                    </ListGroup>
                                </List>
                            </Popover>
                        </>
                    )}

                    <Popover
                        parentRef={toolbarRef}
                        trigger={<Button label={t('Letter spacing')} isDropdown icon={ICONS.LETTER_SPACING} />}
                    >
                        <List>
                            <ListGroup>
                                {LETTER_SPACINGS.map(letterSpacing => (
                                    <ListItem
                                        key={letterSpacing}
                                        isActive={getActiveLetterSpacing(editor) === letterSpacing}
                                        onClick={() => editor.chain().focus().setLetterSpacing(letterSpacing).run()}
                                    >
                                        <span>{Number(letterSpacing.replace('em', '')) * 10}</span>
                                    </ListItem>
                                ))}
                            </ListGroup>
                        </List>
                    </Popover>
                    <Separator />

                    <Button
                        label={t('Bold')}
                        onClick={() => editor.chain().focus().toggleBold().run()}
                        icon={ICONS.BOLD}
                        isActive={isActive(editor, CONTROLS.BOLD)}
                    />
                    <Button
                        label={t('Italic')}
                        onClick={() => editor.chain().focus().toggleItalic().run()}
                        icon={ICONS.ITALIC}
                        isActive={isActive(editor, CONTROLS.ITALIC)}
                    />
                    <Button
                        label={t('Underline')}
                        onClick={() => editor.chain().focus().toggleUnderline().run()}
                        icon={ICONS.UNDERLINE}
                        isActive={isActive(editor, CONTROLS.UNDERLINE)}
                    />
                    <Button
                        label={t('Strike')}
                        onClick={() => editor.chain().focus().toggleStrike().run()}
                        icon={ICONS.STRIKE}
                        isActive={isActive(editor, CONTROLS.STRIKE)}
                    />

                    <Separator />

                    {mode !== TEXT_EDITOR_MODES.BUTTON && (
                        <>
                            <Button
                                label={t('Blockquote')}
                                onClick={() => editor.chain().focus().toggleBlockquote().run()}
                                icon={ICONS.BLOCKQUOTE}
                                isActive={isActive(editor, CONTROLS.BLOCKQUOTE)}
                            />

                            <Popover
                                parentRef={toolbarRef}
                                trigger={
                                    <Button label={t('Link')} icon={ICONS.LINK} isActive={isActive(editor, 'link')} />
                                }
                            >
                                <LinkEditor
                                    attributes={editor.getAttributes('link')}
                                    onSet={(href, target) =>
                                        editor
                                            .chain()
                                            .focus()
                                            .extendMarkRange('link')
                                            .setLink({
                                                href,
                                                target,
                                            })
                                            .run()
                                    }
                                    onReset={() => editor.chain().focus().unsetLink().run()}
                                />
                            </Popover>
                            <Separator />
                        </>
                    )}

                    {mode !== TEXT_EDITOR_MODES.BUTTON && (
                        <Popover
                            parentRef={toolbarRef}
                            trigger={
                                <Button
                                    label={t('Highlight text')}
                                    icon={ICONS.HIGHLIGHT}
                                    isActive={editor.isActive('highlight')}
                                />
                            }
                        >
                            <ColorPicker
                                value={editor.getAttributes('highlight').color}
                                onChange={color => editor.chain().focus().setHighlight({ color }).run()}
                                onReset={() => editor.chain().focus().unsetHighlight().run()}
                            />
                        </Popover>
                    )}

                    <Popover
                        parentRef={toolbarRef}
                        trigger={
                            <Button
                                label={t('Text color')}
                                icon={ICONS.PALETTE}
                                isActive={!!editor.getAttributes('textStyle').color}
                            />
                        }
                    >
                        <ColorPicker
                            value={editor.getAttributes('textStyle').color}
                            onChange={color => editor.chain().focus().setColor(color).run()}
                            onReset={() => editor.chain().focus().unsetColor().run()}
                        />
                    </Popover>

                    <Separator />

                    <Button
                        label={t('Clear formatting')}
                        onClick={() => editor.chain().focus().clearNodes().unsetAllMarks().run()}
                        icon={ICONS.CLEAR_FORMATTING}
                    />
                </div>
            }
        >
            {children}
        </ReactTinyPopover>
    )
}

export default ClassicToolbar
