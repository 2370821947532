import { useRef } from 'react'
import classNames from 'classnames'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import TextStyle from '@tiptap/extension-text-style'
import Link from '@tiptap/extension-link'
import TextAlign from '@tiptap/extension-text-align'
import { Color } from '@tiptap/extension-color'
import Highlight from '@tiptap/extension-highlight'

import FontSize from './extensions/fontSize'
import LineHeight from './extensions/lineHeight'
import FontFamily from './extensions/fontFamily'
import LetterSpacing from './extensions/letterSpacing'

import ClassicToolbar from './components/Toolbar/ClassicToolbar'

import { stripHtml } from './utils'

import styles from './TextEditor.module.scss'

import './TextEditor.scss'

export const TEXT_EDITOR_MODES = {
    BASIC: 'BASIC',
    SEPARATE: 'SEPARATE',
    BUTTON: 'BUTTON',
}
const PADDING_BY_MODE = {
    [TEXT_EDITOR_MODES.BASIC]: 4,
    [TEXT_EDITOR_MODES.SEPARATE]: -4,
    [TEXT_EDITOR_MODES.BUTTON]: 10,
}

const TextEditor = ({ content, mode = TEXT_EDITOR_MODES.BASIC, onChange }) => {
    const classicToolbarRef = useRef()

    const editor = useEditor({
        extensions: [
            StarterKit.configure({
                heading: {
                    levels: [1, 2, 3],
                },
                dropcursor: {
                    width: 0,
                    color: 'transparent',
                },
            }),
            Underline,
            TextStyle,
            FontFamily,
            Link.extend({
                inclusive: false,
            }).configure({
                openOnClick: false,
            }),
            TextAlign.configure({
                types: ['heading', 'paragraph'],
            }),
            Color,
            FontSize,
            LineHeight,
            Highlight.configure({
                multicolor: true,
            }),
            LetterSpacing,
        ],
        content,
        onUpdate: ({ editor }) => onChange(editor.getHTML()),
        editorProps: {
            transformPastedHTML(html, view) {
                if (!!html?.includes('data-pm-slice')) return html
                view.pasteText(stripHtml(html))
                return ''
            },
            drop(view, evt) {
                evt.preventDefault()
            },
        },
    })

    return (
        <div className={styles.textEditor}>
            <ClassicToolbar
                editor={editor}
                toolbarRef={classicToolbarRef}
                mode={mode}
                padding={PADDING_BY_MODE[mode] || 0}
            >
                <EditorContent
                    className={classNames('textEditorContent', `textEditorContent__${mode}`, styles.textEditorContent)}
                    editor={editor}
                />
            </ClassicToolbar>
        </div>
    )
}

export default TextEditor
