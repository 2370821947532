import {cloneDeep} from "lodash"

import CrosswordLayout from "./layoutGenerator"

import {Item as ItemModel} from "./crosswordService"

export const generateCrosswordLayout = (items, { seed = 0, isSkipBadWords = false } = {}) => {
    const newItems = cloneDeep(items)

    const answers = newItems.map(item => item[ItemModel.answer])
    const questions = newItems.map(item => item[ItemModel.question])

    const crosswordLayout = new CrosswordLayout(answers, questions, seed)
    const grid = crosswordLayout.getGrid(10)

    if (!grid) {
        if (isSkipBadWords) {
            const badWords = crosswordLayout.getBadWords()
            const badItemsIndexes = badWords.map(word => word.index)
            badItemsIndexes.forEach(badItemIndex => {
                newItems.splice(badItemIndex, 1)
            })
            return generateCrosswordLayout(newItems, { seed, isSkipBadWords })
        } else {
            const badWords = crosswordLayout.getBadWords()
            const badWordsIndexes = badWords.map(word => word.index)
            newItems.forEach((item, index) => {
                newItems[index] = {
                    ...item,
                    [ItemModel.isIncluded]: !badWordsIndexes.includes(index),
                    [ItemModel.startX]: null,
                    [ItemModel.startY]: null,
                    [ItemModel.orientation]: null,
                    [ItemModel.position]: null,
                }
            })
        }
    } else {
        const legend = crosswordLayout.getLegend(grid)
        newItems.forEach((item, index) => {
            const result = legend[index]
            newItems[index] = {
                ...item,
                [ItemModel.isIncluded]: true,
                [ItemModel.startX]: result.col + 1,
                [ItemModel.startY]: result.row + 1,
                [ItemModel.orientation]: result.orientation.toUpperCase(),
                [ItemModel.position]: result.position,
            }
        })
    }

    return newItems
}