import CURRENCIES from 'common/constants/currencies'

import { ASSET_TABS } from 'components/AssetManager/utils/constants'

export const initialState = {
    isDevMode: false,

    loadingProcesses: [],

    language: 'en',

    clientCountry: null,

    paymentCurrency: CURRENCIES.USD,

    user_details: null,
    user_subscription: null,
    user_subscription_constraints: null,
    user_subscriptions: null,
    userPublicProfile: null,

    projectSubscription: null,

    _service: {
        nav_menu_width: 0,
        styles: {
            window_width: 0,
            window_height: 0,
            header_width: 0,
            header_height: 0,
        },
    },

    assetManager: {
        managerTab: ASSET_TABS.TAB_MY_LIBRARY,
        library: {
            search: '',
            selectedItemId: null,
            isLoadList: false,
            isLoadError: false,
            offset: 0,
            list: { content: [], pageable: { pageSize: 20, pageNumber: 0 }, totalPages: 0, mock: true },
        },
        uploadMedia: {},
    },

    modalManager: {
        name: null,
        payload: {},
    },

    organizations: {
        organizationList: [],
        selectedOrganizationId: null,
        memberList: [],
        selectedMember: {},
        selectedSubscription: {},
        selectedFolderId: null,
    },
}
