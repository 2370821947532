import { useState, createContext, useRef } from 'react'
import { Popover as ReactTinyPopover } from 'react-tiny-popover'

import useClickOutside from 'hooks/useClickOutside'

import styles from './Popover.module.scss'

export const PopoverContext = createContext({})

const Popover = ({ parentRef, trigger, children }) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)
    const contentRef = useRef()

    useClickOutside(contentRef, () => setIsPopoverOpen(false))

    return (
        <ReactTinyPopover
            parentElement={parentRef?.current || undefined}
            boundaryElement={document.body}
            containerClassName={styles.popover}
            isOpen={isPopoverOpen}
            positions={['bottom', 'top', 'left', 'right']}
            padding={2}
            content={
                <PopoverContext.Provider value={{ onClosePopover: () => setIsPopoverOpen(false) }}>
                    <div ref={contentRef} className={styles.popoverContent}>
                        {children}
                    </div>
                </PopoverContext.Provider>
            }
        >
            <div className={styles.parent} onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                {trigger}
            </div>
        </ReactTinyPopover>
    )
}

export default Popover
