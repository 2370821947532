import Toast from 'components/Toast/Toast'
import { cloneDeep, isEqual, set } from 'lodash'
import React from 'react'
import { withTranslation } from 'react-i18next'

import Header from 'components/Modal/components/Header/Header'
import Body from 'components/Modal/components/Body/Body'
import StopPanel from 'components/Modal/components/StopPanel/StopPanel'
import Modal from 'components/Modal/Modal'

import { ProjectContext } from 'pages/Editor/contexts/projectContext'

import iconGirl from '../components/StopPanels/i/girl.svg'

import { TRIVIA_TABS, TRIVIA_TABS_ARRAY } from './constants'

import './TriviaQuizModal.scss'
import { createTriviaBlock } from './triviaService'

import { calculateResultDistribution, validateTabs } from './utilities'
import QuestionView from './views/QuestionView'
import ResultView from './views/ResultView'
import StartScreenView from './views/StartView'

class TriviaQuizModal extends React.Component {
    static contextType = ProjectContext

    constructor(props) {
        super(props)

        let tmpStructure = cloneDeep(this.props.data.structure)
        if (Object.keys(tmpStructure).length === 0) {
            tmpStructure = createTriviaBlock()
        }
        this.state = {
            ready: true,
            saving: false,
            activeTab: 1,
            showStopPanel: false,
            onlyActiveTab: false,
            errors: {},
            quitAcceptIsOpen: false,
            tmpStructure,
        }
    }

    onSave = async () => {
        const { tmpStructure } = this.state
        const validationResult = validateTabs(tmpStructure)
        if (validationResult.hasError) {
            this.setState({
                showStopPanel: true,
                errors: validationResult.errors,
            })
            return
        }

        const {
            methods,
            data: { id },
        } = this.props

        this.setState({ saving: true })
        try {
            await methods.save({
                [id]: {
                    data: {
                        struct: validationResult.tmpStructure,
                    },
                },
            })
        } catch (err) {
            console.error(err)

            Toast('error', {})
        } finally {
            this.setState({ saving: false })
        }
        methods.closeModal()
    }

    onClose = async () => {
        const {
            methods: { closeModal },
            data: { structure },
        } = this.props
        const { tmpStructure } = this.state
        // TODO add normalizations
        if (!isEqual(structure, tmpStructure)) {
            await this.setState({ quitAcceptIsOpen: true })
        } else {
            closeModal()
        }
    }

    onExited = async () => {}

    onChangeTab = async tabIndex => {
        const { activeTab, tmpStructure } = this.state
        const validationResult = validateTabs(tmpStructure, activeTab)
        if (validationResult.hasError) {
            await this.setState({
                showStopPanel: true,
                onlyActiveTab: true,
                errors: validationResult.errors,
            })
            return
        }

        await this.setState({ activeTab: tabIndex })
    }

    getQuestionsCount = structure => {
        const { questionBank, questions } = structure
        if (!questionBank || !questionBank.bankId) return questions.length
        return Number(questionBank.questionsCount)
    }

    onUpdateStructure = (newFields, callback) => {
        this.setState(prevState => {
            const newState = {
                ...prevState,
                tmpStructure: {
                    ...prevState.tmpStructure,
                    ...newFields,
                },
            }

            const questionsLen = this.getQuestionsCount(newState.tmpStructure)
            const resultsLen = newState.tmpStructure.results.length
            const maxNumberOfOptions = questionsLen + 1
            const resultDistribution = calculateResultDistribution(maxNumberOfOptions, resultsLen)
            set(newState, 'tmpStructure.resultDistribution', resultDistribution)
            return newState
        }, callback)
    }

    render() {
        const {
            saving,
            ready,
            tmpStructure,
            activeTab,
            showStopPanel,
            onlyActiveTab,
            errors,
            quitAcceptIsOpen,
        } = this.state
        const {
            methods,
            data: { colorTheme, pages, fontFamily },
            t,
        } = this.props
        const { isMultiplayerGame } = this.context

        let tabContent = null
        switch (activeTab) {
            case TRIVIA_TABS.startView: {
                tabContent = (
                    <StartScreenView
                        colorTheme={colorTheme}
                        fontFamily={fontFamily}
                        tmpStructure={tmpStructure}
                        errors={errors}
                        onUpdateStructure={this.onUpdateStructure}
                    />
                )
                break
            }
            case TRIVIA_TABS.questionView: {
                tabContent = (
                    <QuestionView
                        colorTheme={colorTheme}
                        tmpStructure={tmpStructure}
                        errors={errors}
                        onUpdateStructure={this.onUpdateStructure}
                    />
                )
                break
            }
            case TRIVIA_TABS.resultView: {
                tabContent = (
                    <ResultView
                        pages={pages}
                        tmpStructure={tmpStructure}
                        errors={errors}
                        onUpdateStructure={this.onUpdateStructure}
                        getQuestionsCount={this.getQuestionsCount}
                        onCalculateResultDistribution={this.onCalculateResultDistribution}
                    />
                )
                break
            }
        }

        const stopPanelHeadText = onlyActiveTab ? t('Oh! Need more information') : t('Oh, no!')
        const stopPanelDescriptionText = onlyActiveTab
            ? t('Please fill all required fields on this tab for the quiz to work correctly.')
            : t('Please fill all required fields for the quiz to work correctly.')

        return (
            <>
                {ready && (
                    <Modal closeOnOverlayClick={false} closeOnEsc={false}>
                        <Header title={t('Quiz options')} onClose={() => this.onClose()} />
                        <Body
                            sideNav={{
                                items: TRIVIA_TABS_ARRAY.filter(tab =>
                                    isMultiplayerGame ? !tab.isHideFromMultiplayer : true,
                                ),
                                activeItemId: activeTab,
                                onChangeItem: this.onChangeTab,
                                analyticsBlockName: 'TRIVIA_QUIZ',
                            }}
                            onSave={this.onSave}
                        >
                            <div className="quiz-modal-wrapper">{tabContent}</div>
                        </Body>

                        {showStopPanel && (
                            <StopPanel
                                t={t}
                                headText={stopPanelHeadText}
                                descriptionText={stopPanelDescriptionText}
                                image={iconGirl}
                                onBack={() => this.setState({ showStopPanel: false })}
                                isShowQuit={false}
                            />
                        )}
                        {quitAcceptIsOpen && (
                            <StopPanel
                                t={t}
                                isLoading={saving}
                                onBack={() => this.setState({ quitAcceptIsOpen: false })}
                                onQuit={methods.closeModal}
                            />
                        )}
                    </Modal>
                )}
            </>
        )
    }
}

export default withTranslation('translations')(TriviaQuizModal)
