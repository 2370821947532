import React, { useState, useRef, useMemo, useEffect } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import ReactQuill, { Quill } from 'react-quill'
import classNames from 'classnames'

import { getRemixFontsList } from 'common/constants/fonts'

import useRect from 'hooks/useRect'

import 'react-quill/dist/quill.snow.css'

import ColorPicker from './components/ColorPicker/ColorPicker'

import { BASE_COLORS } from './constants'
import { createPortal } from 'react-dom'

const Font = Quill.import('formats/font')
const Link = Quill.import('formats/link')
const Parchment = Quill.import('parchment')

// line-height
const lineHeightConfig = {
    scope: Parchment.Scope.INLINE,
    whitelist: ['1', '1.2', '1.5', '1.6', '1.8', '2', '2.4', '2.8'],
}
const lineHeightClass = new Parchment.Attributor.Class('lineheight', 'ql-line-height', lineHeightConfig)
const lineHeightStyle = new Parchment.Attributor.Style('lineheight', 'line-height', lineHeightConfig)
Parchment.register(lineHeightClass)
Parchment.register(lineHeightStyle)
// font-size
const fontSizeConfig = {
    scope: Parchment.Scope.INLINE,
    whitelist: [
        '8px',
        '9px',
        '10px',
        '11px',
        '12px',
        '14px',
        '16px',
        '18px',
        '20px',
        '24px',
        '30px',
        '36px',
        '48px',
        '60px',
        '72px',
        '96px',
    ],
}
const fontSizeClass = new Parchment.Attributor.Class('fontsize', 'ql-font-size', fontSizeConfig)
const fontSizeStyle = new Parchment.Attributor.Style('fontsize', 'font-size', fontSizeConfig)
Parchment.register(fontSizeClass)
Parchment.register(fontSizeStyle)

Font.whitelist = getRemixFontsList().map(f => f.replace(/ /g, ''))
Quill.register(Font, true)

class MyLink extends Link {
    static create(value) {
        const node = Link.create(value)
        value = Link.sanitize(value)
        node.setAttribute('href', value)
        node.removeAttribute('target')
        return node
    }

    format(name, value) {
        super.format(name, value)
        this['domNode'].removeAttribute('target')
    }
}
Quill.register(MyLink)

const toolbar = [
    [{ font: Font.whitelist }, { fontsize: fontSizeConfig.whitelist }],
    [
        'bold',
        'italic',
        'underline',
        { color: [...BASE_COLORS, 'custom-color'] },
        { background: [...BASE_COLORS, 'custom-color'] },
    ],
    [{ align: [] }, { lineheight: lineHeightConfig.whitelist }],
    [{ list: 'ordered' }, { list: 'bullet' }],
]

const Text = ({ text, onUpdate, id, propName, isHideLink = false }) => {
    const [isFocused, setIsFocused] = useState(false)
    const reactQuillRef = useRef()
    const wrapperRef = useRef()

    const [isShowColorPicker, setIsShowColorPicker] = useState(false)
    const [isShowBackgroundPicker, setIsShowBackgroundPicker] = useState(false)

    const [textZoneRect] = useRect(wrapperRef, 500)

    useEffect(() => {
        const editor = getEditor()
        if (!editor) return
        const toolbar = editor.getModule('toolbar')
        toolbar.addHandler('color', value => {
            if (value === 'custom-color') {
                setIsShowColorPicker(true)
                return
            }
            editor.format('color', value)
        })
        toolbar.addHandler('background', value => {
            if (value === 'custom-color') {
                setIsShowBackgroundPicker(true)
                return
            }
            editor.format('background', value)
        })
    }, [])

    const onSetColor = (option, value) => {
        const editor = getEditor()
        if (!editor) return
        editor.format(option, value)
    }

    const getEditor = () => {
        if (!reactQuillRef.current) return
        return reactQuillRef.current.getEditor()
    }

    const onFocus = async () => {
        const editor = getEditor()
        if (!editor) return

        if (!isFocused) {
            setIsFocused(true)
            setTimeout(() => {
                editor.focus()
                recalculatePosition()
            })
        }
    }

    const onBlur = () => setIsFocused(false)

    const handleChange = html => {
        try {
            const editor = getEditor()
            if (!editor) return

            onUpdate({
                [id]: {
                    data: { [propName || 'text']: html },
                },
            })
        } catch (err) {
            console.error(err)
        }
    }

    const modules = useMemo(
        () => ({
            toolbar: [...toolbar, isHideLink ? ['clean'] : ['link', 'clean']],
        }),
        [isHideLink],
    )

    const recalculatePosition = () => {
        if (!textZoneRect || !wrapperRef?.current) return
        const toolbarElements = wrapperRef.current.getElementsByClassName('ql-toolbar')
        if (!toolbarElements.length) return
        const toolbarElement = toolbarElements[0]
        const editorElement = document.getElementById('editor-playground-container')
        if (!editorElement) return
        const toolbarReact = toolbarElement.getBoundingClientRect()
        const editorRect = editorElement.getBoundingClientRect()

        const minLeft = editorRect.left + 5
        const maxRight = editorRect.right - 5
        const toolbarWidth = toolbarReact.width
        const textZoneCenter = textZoneRect.left + textZoneRect.width / 2

        if (
            (textZoneCenter + toolbarWidth / 2 <= maxRight && textZoneCenter - toolbarWidth / 2 >= minLeft) ||
            (textZoneCenter + toolbarWidth / 2 >= maxRight && textZoneCenter - toolbarWidth / 2 <= minLeft)
        ) {
            toolbarElement.style.left = `calc(50% - ${toolbarWidth / 2}px)`
            toolbarElement.style.right = 'unset'
            return
        }
        const rightOverlap = maxRight - (textZoneCenter + toolbarWidth / 2)
        const leftOverlap = textZoneCenter - toolbarWidth / 2 - minLeft

        if (rightOverlap > leftOverlap) {
            toolbarElement.style.left = `${minLeft - textZoneRect.left}px`
            toolbarElement.style.right = 'unset'
            return
        }
        toolbarElement.style.left = 'unset'
        toolbarElement.style.right = `-${maxRight - textZoneRect.right}px`
    }

    useEffect(() => {
        recalculatePosition()
    }, [textZoneRect])

    return (
        <OutsideClickHandler onOutsideClick={onBlur}>
            <div className={classNames('qt', { __readonly: !isFocused })} onClick={onFocus} ref={wrapperRef}>
                <ReactQuill
                    readOnly={!isFocused}
                    modules={modules}
                    value={text}
                    onChange={handleChange}
                    ref={reactQuillRef}
                />
            </div>

            {isShowColorPicker && (
                <ColorPicker
                    reactQuillRef={reactQuillRef}
                    option="color"
                    onSave={hex => {
                        onSetColor('color', hex)
                        setIsShowColorPicker(false)
                    }}
                    onClose={() => setIsShowColorPicker(false)}
                />
            )}
            {isShowBackgroundPicker && (
                <ColorPicker
                    reactQuillRef={reactQuillRef}
                    option="background"
                    onSave={hex => {
                        onSetColor('background', hex)
                        setIsShowBackgroundPicker(false)
                    }}
                    onClose={() => setIsShowBackgroundPicker(false)}
                />
            )}
        </OutsideClickHandler>
    )
}

export default Text
