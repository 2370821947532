import { matchPath } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { COOKIE_PREFERENCES_KEYS, isHasCookiePreference } from 'common/constants/cookies'

const NETWORK_ERROR = 'Network Error'

export default {
    init,
    log,
}

const isEnabled = () =>
    !!process.env.REACT_APP_SENTRY_CLIENT_DNS && isHasCookiePreference(COOKIE_PREFERENCES_KEYS.ANALYTICAL)

// Make sure the order of the routes is correct. The longest url under the same parent should be placed first and in decreasing order.
const routes = [
    { path: '/' },
    { path: '/auth' },
    { path: '/settings' },
    { path: '/editor/:projectId' },
    { path: '/billing-and-payments' },
    { path: '/template-gallery/:id' },
    { path: '/my-projects/:id/analytics' },
    { path: '/my-projects' },
]

function init(history) {
    if (isEnabled()) {
        Sentry.init({
            ignoreErrors: [
                NETWORK_ERROR,
                'timeout of 30000ms exceeded',
                'ResizeObserver loop limit exceeded',
                "Failed to execute 'getBattery' on 'Navigator': Illegal invocation",
            ],
            dsn: process.env.REACT_APP_SENTRY_CLIENT_DNS,
            environment: process.env.REACT_APP_ENVIRONMENT,
            integrations: [
                new Integrations.BrowserTracing({
                    routingInstrumentation: Sentry.reactRouterV5Instrumentation(history, routes, matchPath),
                }),
            ],
            tracesSampleRate: process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE,
            beforeBreadcrumb(breadcrumb) {
                const needSkip =
                    breadcrumb.category === 'xhr' &&
                    (breadcrumb.data.url.includes('facebook.com/plugins') ||
                        breadcrumb.data.url.includes('google-analytics.com/j/collector') ||
                        breadcrumb.data.url.includes('mc.yandex.ru/webvisor'))
                return needSkip ? null : breadcrumb
            },
        })
    }
}

function log(error, info = { fromErrorBoundary: false }) {
    const isNetworkError = error.message && error.message === NETWORK_ERROR
    if (isEnabled()) {
        Sentry.withScope(scope => {
            scope.setExtras(info)
            scope.setTag('online', String(navigator && navigator.onLine))
            if (isNetworkError) {
                Sentry.captureMessage(error.message, Sentry.Severity.Log)
            } else {
                scope.setLevel(info.fromErrorBoundary ? Sentry.Severity.Fatal : Sentry.Severity.Error)
                Sentry.captureException(error)
            }
        })
    } else {
        console.error(error, info)
    }
}
