const BLOCKS = {
    text: 1,
    image: 2,
    embedInteractyProject: 3,
    flipCards: 4,
    youtubeVideo: 5,
    button: 6,
    interactiveImage: 7,
    hiddenObjects: 8,
    quiz: 9,
    thenNow: 10,
    memoryCards: 11,
    timeline: 12,
    cookies: 13,
    horoscope: 14,
    personalityQuiz: 15,
    puzzle: 16,
    leadForm: 17,
    findPair: 18,
    slideshow: 19,
    treasureHunt: 20,
    logo: 21,
    matching: 22,
    audio: 23,
    embedIframe: 24,
    question: 25,
    wheelSpin: 26,
    crossword: 27,
    slidingPuzzle: 28,
    rankBattle: 29,
    cover: 30,
    textAndImage: 31,
    authForm: 32,
    article: 33,
}

export default BLOCKS
