import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'
import Modal from 'components/Modal/Modal'
import Toast from 'components/Toast/Toast'

import linkIcon from '../../i/link.svg'

import './SelectInternalPageModal.scss'

const MAX_PAGES_ON_LINE = 5

const SelectInternalPageModal = ({
    data: { pages, blockId, fieldName, payload },
    onClose,
    onUpdate,
    customUpdate = null,
}) => {
    const { t } = useTranslation()

    const onSelect = id => {
        if (customUpdate) {
            customUpdate(`#${id}`)
        } else {
            onUpdate({
                [blockId]: {
                    data: { [fieldName]: `#${id}` },
                    payload,
                },
            })
        }
        Toast('info', { title: t('Link created!'), icon: linkIcon })
        onClose()
    }

    const renderedTemplates = useMemo(() => {
        const templateCount = pages.length > MAX_PAGES_ON_LINE ? 0 : MAX_PAGES_ON_LINE - pages.length
        if (!templateCount) {
            return null
        }
        return [...Array(templateCount)].map((val, index) => (
            <div key={index} className="select-internal-page-modal__template" />
        ))
    }, [pages])

    return (
        <Modal closeOnEsc={false} closeOnOverlayClick={false} onClose={onClose} isShowCloseIcon>
            <div className="select-internal-page-modal">
                <h2 className="select-internal-page-modal__title">{t('Select internal page')}</h2>
                <ScrollableBlock maxHeight="80%" styles={{ width: '100%' }}>
                    <div className="select-internal-page-modal__page-list">
                        {pages.map((page, index) => (
                            <div
                                key={page.id}
                                className="select-internal-page-modal__page"
                                onClick={() => onSelect(page.id)}
                            >
                                <div className="select-internal-page-modal__page-label">{page.name || t(`${t('Page')} ${index + 1}`)}</div>
                                <div
                                    className={`select-internal-page-modal__page-img ${
                                        page.blocks.length ? 'is-has-blocks' : ''
                                    }`}
                                />
                            </div>
                        ))}
                        {renderedTemplates}
                    </div>
                </ScrollableBlock>
            </div>
        </Modal>
    )
}
export default SelectInternalPageModal
