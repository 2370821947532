import { Breadcrumbs } from '@mui/material'
import classNames from 'classnames'
import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { IconNext } from 'svg'

import CreateProjectMenu from './components/CreateProjectMenu/CreateProjectMenu'

import './TopBar.scss'

const TopBar = ({ folderPaths, selectedMember, onGoToFolder, onCreateFromScratch }) => {
    const { t } = useTranslation()
    const { user_details } = useSelector(state => ({ user_details: state.user_details }))

    return (
        <div className="my-project-top-bar">
            <div className="title">
                {selectedMember.userId ? (
                    <>
                        <span className="member-prefix">{t('Projects by')}</span>
                        <span>{selectedMember.name}</span>
                        {selectedMember.userId === user_details.id && (
                            <span className="member-postfix">{t('(You)')}</span>
                        )}
                    </>
                ) : (
                    <Breadcrumbs separator={<IconNext />} aria-label="breadcrumb">
                        {!folderPaths ? (
                            <span key="all-projects" className="path">
                                {t('All projects')}
                            </span>
                        ) : (
                            folderPaths.map((item, index) => (
                                <span
                                    key={item.id}
                                    className={classNames('path', {
                                        'is-last': index === folderPaths.length - 1,
                                    })}
                                    onClick={() => onGoToFolder(index === 0 ? null : item.id)}
                                >
                                    {index === 0 ? t('All projects') : item.name}
                                </span>
                            ))
                        )}
                    </Breadcrumbs>
                )}
            </div>
            <div className="actions">
                <CreateProjectMenu onCreateFromScratch={onCreateFromScratch} />
            </div>
        </div>
    )
}

export default TopBar
