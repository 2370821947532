import React, { useEffect } from 'react'
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import WindowSizeListener from 'react-window-size-listener'
import { ToastContainer } from 'react-toastify'
import smoothscroll from 'smoothscroll-polyfill'
import * as Sentry from '@sentry/react'
import useKeypress from "react-use-keypress"
import { StyledEngineProvider } from '@mui/material/styles'

import { API__USER, API__COMMON } from 'api'

import { REMIX_SUPPORTED_FONTS } from 'shared/constants/fonts'

import {
    USER__SET_DETAILS,
    SERVICE__STYLES__SET_WINDOW_SIZES,
    COMMON__SET_LANGUAGE,
    COMMON__SET_CLIENT_COUNTRY,
    COMMON__SET_PAYMENT_CURRENCY,
    COMMON__SET_DEV_MODE,
} from 'store/actions'

import MainLayout from 'layout/MainLayout'

import ErrorPage from 'pages/Error/Error.page'
import { DialogProvider } from 'components/Modal/NotificationDialog/DialogContext'
import { HintProvider } from 'components/Hint/HintContext'
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary'
import ProviderComposer, { provider } from 'components/ProviderComposer/ProviderComposer'
import SEO from 'components/SEO'
import ModalManager from 'components/ModalManager/ModalManager'
import MainPreloader from 'components/MainPreloader/MainPreloader'

import useFreshdesk from 'hooks/useFreshdesk'
import useGoogleTagManager from 'hooks/useGoogleTagManager'
import useGoogleAnalytics, { GoogleAnalyticsTracker } from 'hooks/useGoogleAnalytics'
import useInitialLoading from 'hooks/useInitialLoading'

import Mixpanel from 'common/services/Mixpanel'

import COUNTRIES from 'common/constants/countries'
import CURRENCIES from 'common/constants/currencies'

import ScrollToTop from 'routes/utils/ScrollToTop'
import ProtectedRoute from 'routes/ProtectedRoute'
import AuthRoutes from 'routes/Auth'

import './styles/reset.scss'
import 'react-toastify/dist/ReactToastify.min.css'
import './styles/main.scss'
import 'animate.css'
import 'simplebar-react/dist/simplebar.min.css'

smoothscroll.polyfill()

const App = ({ history }) => {
    const { isReady, onReady } = useInitialLoading()
    const dispatch = useDispatch()
    const {
        i18n: { language },
    } = useTranslation()

    const { clientCountry, isDevMode } = useSelector(state => state)

    useFreshdesk(language)
    useGoogleTagManager()
    useGoogleAnalytics()

    useKeypress('F1', () => {
        dispatch(COMMON__SET_DEV_MODE(!isDevMode))
    })

    useEffect(() => {
        dispatch(COMMON__SET_LANGUAGE({ code: language, isForceSetCookie: false }))

        getInitData()
            .then(() => {
                onReady()
            })
            .catch(err => console.error(err))

        REMIX_SUPPORTED_FONTS.forEach(font => {
            const link = document.createElement('link')
            link.href = `https://fonts.googleapis.com/css2?family=${font}:wght@300;400;700&display=swap`
            link.rel = 'stylesheet'
            document.getElementsByTagName('head')[0].append(link)
        })
    }, [])

    useEffect(() => {
        if (!clientCountry) return
        const currency = clientCountry === COUNTRIES.RU ? CURRENCIES.RUB : CURRENCIES.USD
        dispatch(COMMON__SET_PAYMENT_CURRENCY(currency))
    }, [clientCountry])

    const onResizeWindow = data => {
        dispatch(
            SERVICE__STYLES__SET_WINDOW_SIZES({
                width: data.windowWidth,
                height: data.windowHeight,
            }),
        )
    }
    const tryGetUserCountry = async () => {
        try {
            return await API__COMMON.GET_CLIENT_COUNTRY()
        } catch (err) {
            return null
        }
    }
    const tryGetUserDetails = async () => {
        try {
            return await API__USER.GET_DETAILS()
        } catch (err) {
            return null
        }
    }

    const getInitData = async () => {
        const [userDetails, userCountry] = await Promise.all([tryGetUserDetails(), tryGetUserCountry()])
        if (userDetails) {
            dispatch(USER__SET_DETAILS(userDetails))
            Mixpanel.identify(userDetails.id, userDetails.login)
        }
        if (userCountry) dispatch(COMMON__SET_CLIENT_COUNTRY(userCountry))
    }

    return (
        <>
            <MainPreloader />
            {isReady && (
                <ErrorBoundary isShowReloadBtn>
                    <ProviderComposer providers={[provider(DialogProvider), provider(HintProvider)]}>
                        <StyledEngineProvider injectFirst>
                            <div className="wrapper">
                                <SEO
                                    title="Create interactive content in minutes. Free"
                                    addTitleLabel={false}
                                    description="One of the most powerful editors for interactive content. Create quizzes, photogalleries and games. Generate leads, collect data and drive more traffic. Start with a template. Customize like never before. Get maximum efficiency with detailed analytics and integrations."
                                    thumbnail="/sharing.png"
                                    thumbnailType="image/png"
                                    thumbnailWidth="1000"
                                    thumbnailHeight="672"
                                    locale={language}
                                />

                                <WindowSizeListener onResize={onResizeWindow} />
                                <Router history={history}>
                                    <ModalManager />
                                    <GoogleAnalyticsTracker />

                                    <ScrollToTop />

                                    <Switch>
                                        <Route exact path={['/auth', '/oauth2/redirect']} component={AuthRoutes} />
                                        <Route exact path={['/error']} component={ErrorPage} />
                                        <ProtectedRoute component={MainLayout} />
                                        <Redirect from="*" to="/my-projects" />
                                    </Switch>
                                </Router>
                                <ToastContainer />
                            </div>
                        </StyledEngineProvider>
                    </ProviderComposer>
                </ErrorBoundary>
            )}
        </>
    )
}

export default Sentry.withProfiler(App)
