import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { useAssetManager } from 'components/AssetManager'
import BLOCK from 'common/constants/BlockTypes/BlocksEnum'
import { decodeStringsInObject } from 'common/utils/objectStringsAction'

import { API__TEMPLATES } from 'api'

import icon_addText from '../../../i/add_text.svg'
import icon_addImg from '../../../i/add_img.svg'
import icon_addButton from '../../../i/add_cta.svg'
import icon_more from '../../../i/more.svg'
import icon_question from '../../../i/question_icon--active.svg'

import styles from './Add.module.scss'

const Add = ({ onAdd, onOpenBlockPanel, isHasBlocks, isHasMultiplayerBlocks, isMultiplayerGame }) => {
    const { t } = useTranslation()
    const { chooseAsset } = useAssetManager(true)

    const [isLoading, setIsLoading] = useState(false)

    const addBlockWithImage = () => {
        chooseAsset(null, data => onAdd(BLOCK.image, null, { url: data.src, author: data.author }))
    }

    const addFirstTemplate = async blockTypeId => {
        try {
            setIsLoading(true)
            const templates = await API__TEMPLATES.GET_BLOCK_TEMPLATES(blockTypeId, {
                sort: 'orderNumber,asc',
                page: 0,
                size: 1,
            })
            if (templates.content.length) {
                const template = templates.content[0]
                onAdd(blockTypeId, null, decodeStringsInObject(JSON.parse(template.structureJson)))
            }
        } catch (err) {
            console.error(err)
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className={classNames(styles.add, { [styles.isAddNotHasBlocks]: !isHasBlocks })}>
            <ul className={styles.quickAddList}>
                {isMultiplayerGame && !isHasMultiplayerBlocks && (
                    <span
                        className={classNames(styles.button, { [styles.isLoadingButton]: isLoading })}
                        onClick={() => addFirstTemplate(BLOCK.question)}
                    >
                        <img className={styles.buttonIcon} src={icon_question} alt="text" />
                        {t('Add question')}
                    </span>
                )}
                <span className={styles.button} onClick={() => onAdd(BLOCK.text)}>
                    <img className={styles.buttonIcon} src={icon_addText} alt="text" />
                    {t('Add text')}
                </span>
                <span className={styles.button} onClick={addBlockWithImage}>
                    <img className={styles.buttonIcon} src={icon_addImg} alt="image" />
                    {t('Add image')}
                </span>
                {!isMultiplayerGame && (
                    <span className={styles.button} onClick={() => onAdd(BLOCK.button)}>
                        <img className={styles.buttonIcon} src={icon_addButton} alt="button" />
                        {t('Add button')}
                    </span>
                )}
                <span
                    className={classNames(styles.button, styles.buttonBlue)}
                    data-test="all-blocks-panel-button"
                    onClick={() => onOpenBlockPanel()}
                >
                    <img className={styles.buttonIcon} src={icon_more} alt="more" />
                    {t('All blocks')}
                </span>
            </ul>
            {!isMultiplayerGame && !isHasBlocks && (
                <div className={styles.text}>{t('Start creating your project')}</div>
            )}
        </div>
    )
}

export default Add
