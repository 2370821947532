import { BLOCK_SCHEMA } from 'pages/Editor/Tabs/Editor/schema'
import Normalizer from 'pages/Editor/Tabs/Editor/util/normalizer'
import DataSchema from 'pages/Editor/Tabs/Editor/util/schema'

import { getUniqueId } from '../utils/getUniqueId'

export default class RoundModel {
    id
    name
    blocks

    constructor({ name = '', blocks = [] } = {}) {
        this.id = getUniqueId()
        this.name = name
        this.blocks = blocks.map(block => {
            const newBlock = new Normalizer(new DataSchema(BLOCK_SCHEMA[block.t])).process(block)
            return { ...newBlock, id: getUniqueId() }
        })
    }

    static get name() {
        return 'name'
    }

    static get blocks() {
        return 'blocks'
    }
}
