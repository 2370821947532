import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import TextInput from 'components/Form/TextInput/TextInput'

import ActionsDropdown, { TYPES } from '../../components/ActionsDropdown/ActionsDropdown'

import './Round.scss'

const Round = ({ round, selectedRound, index, arrayLength, methods, draggable }) => {
    const { t } = useTranslation()
    const [name, setName] = useState(round.name || '')
    const [isShowInput, setIsShowInput] = useState(false)

    const onClickRename = () => {
        setIsShowInput(true)
    }

    const onApplyRename = () => {
        methods.onRenameRound(round.id, name)
        setIsShowInput(false)
    }

    return (
        <div
            {...draggable}
            className={classNames('internal-rounds__round', {
                'internal-rounds__round--is-selected': selectedRound && selectedRound.id === round.id,
            })}
            onClick={() => methods.onSelectRound(round)}
        >
            <div className="internal-rounds__round-label">
                {isShowInput ? (
                    <>
                        <div className="internal-rounds__round-input-wrapper" onClick={onApplyRename}/>
                        <TextInput
                            className="internal-rounds__round-input"
                            value={name}
                            onUpdate={val => setName(val)}
                            autoFocus
                            onFocus={evt => evt.target.select()}
                        />
                    </>
                ) : (
                    round.name || t('Round')
                )}
            </div>

            <div className="internal-rounds__round-number">
                <div className="internal-rounds__round-number__text">{index + 1}</div>
            </div>

            <ActionsDropdown
                index={index}
                type={TYPES.ROUND}
                arrayLength={arrayLength}
                methods={{
                    onClickRename,
                    onRemove: () => methods.onRemove(round.id),
                    onClone: () => methods.onClone(round.id),
                    onChangePosition: methods.onChangePosition,
                }}
            />
        </div>
    )
}

export default Round
