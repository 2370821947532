import { getCookie } from 'utils/cookies'

const COOKIES = {
    LOCALE: 'NEXT_LOCALE',
    ACCEPT_COOKIES: 'ACCEPT_COOKIES',
    CLIENT_ID: 'CLIENT_ID',
    COOKIE_PREFERENCE: 'COOKIE_PREFERENCE',
}

Object.freeze(COOKIES)

export const COOKIE_PREFERENCES_KEYS = {
    FUNCTIONAL: 'FUNCTIONAL',
    ANALYTICAL: 'ANALYTICAL',
    MARKETING: 'MARKETING',
}

export const isHasCookiePreference = (key = null) => {
    try {
        // Legacy support
        if (getCookie(COOKIES.ACCEPT_COOKIES)) return true

        // New logic
        const preferenceCookies = getCookie(COOKIES.COOKIE_PREFERENCE)
        if (!preferenceCookies) return false
        if (!key) return true
        const parsedPreferences = JSON.parse(preferenceCookies)
        return parsedPreferences.includes(key)
    } catch (err) {
        console.error(err)
        return false
    }
}

export default COOKIES
