import React, { useState, useContext } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

import BLOCKS from 'shared/constants/blocks'

import { CONDITIONS, hasPermission } from 'common/utils/permissions'

import { API__ANALYTICS } from 'api'

import OverText from '../../components/OverText/OverText'

import PeriodSelector from './components/PeriodSelector/PeriodSelector'
import Leads, { FakeLeads } from './components/Leads/Leads'

import AnalyticsContext from '../../analyticsContext'

const Authorization = ({ projectId }) => {
    const { t } = useTranslation()

    const { authorizationProjection: projection } = useContext(AnalyticsContext)

    const [isCanUseAuthorization] = useState(() => hasPermission(CONDITIONS.CAN_USE_AUTHORIZATION))

    const [dateRange, setDateRange] = useState(null)

    return (
        <>
            <h1>{t('Login form')}</h1>
            {isCanUseAuthorization ? (
                <>
                    <PeriodSelector onChange={v => setDateRange(v)} />
                    {!!dateRange && !!projection ? (
                        <Leads
                            projectId={projectId}
                            blockId={projection.blockId}
                            fields={projection.metadata.leadFormFields}
                            dateRange={dateRange}
                            onLoadDataRequest={API__ANALYTICS.GET_AUTHORIZATION_FORM}
                            onLoadCsvRequest={API__ANALYTICS.GET_AUTHORIZATION_FORM_CSV}
                            csvFilenamePrefix={t('Login form')}
                        />
                    ) : (
                        <FakeLeads>
                            <OverText
                                text={
                                    <Trans
                                        i18nKey="To enable this option, go to the <lnk>editor</lnk> and republish project"
                                        components={{
                                            lnk: <Link to={`/editor/${projectId}`} />,
                                        }}
                                    />
                                }
                            />
                        </FakeLeads>
                    )}
                </>
            ) : (
                <FakeLeads>
                    <OverText />
                </FakeLeads>
            )}
        </>
    )
}

export default Authorization
