import React, { useState } from 'react'

import PersonalityQuizModal from '../components/Modals/PersonalityQuizModal/PersonalityQuizModal'
import StartScreen from '../components/StartScreen/StartScreen'
import QuestionView from '../components/QuestionView/QuestionView'

import ModalEditedBlock from '../components/ModalEditedBlock/ModalEditedBlock'

const PersonalityQuiz = ({ blockData: { struct, colorTheme, id, fontFamily = 'Roboto' }, pagesData, onUpdate }) => {
    const [isShowModal, setIsShowModal] = useState(false)

    return (
        <ModalEditedBlock onEdit={() => setIsShowModal(true)}>
            {Object.keys(struct).length && struct.cover.isShowCover ? (
                <StartScreen struct={struct} backgroundColor={colorTheme} fontFamily={`"${fontFamily}", sans-serif`} />
            ) : (
                <QuestionView question={struct.questions[0]} fontFamily={`"${fontFamily}", sans-serif`} />
            )}

            {isShowModal && (
                <PersonalityQuizModal
                    data={{
                        pages: pagesData,
                        structure: struct,
                        colorTheme: colorTheme,
                        id,
                        fontFamily: `"${fontFamily}", sans-serif`,
                    }}
                    methods={{
                        closeModal: () => setIsShowModal(false),
                        save: onUpdate,
                    }}
                />
            )}
        </ModalEditedBlock>
    )
}

export default PersonalityQuiz
