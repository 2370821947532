import { useContext, useRef, useState } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import Toggle from 'components/Form/Toggle/Toggle'

import { isUrl } from 'utils/validator'

import LinkIcon from './svg/Link'
import AcceptIcon from './svg/Accept'
import DeleteIcon from './svg/Delete'

import styles from './LinkEditor.module.scss'

import { TARGETS } from './constants'

import { PopoverContext } from '../../Popover'

const LinkEditor = ({ attributes, onSet, onReset }) => {
    const { t } = useTranslation()
    const { onClosePopover } = useContext(PopoverContext)

    const inputRef = useRef()

    const [isErrorInput, setIsErrorInput] = useState(false)

    const [href, setHref] = useState(attributes.href || '')
    const [isNewTab, setIsNewTab] = useState(attributes?.target === TARGETS.BLANK || false)

    const handleSet = () => {
        if (href === '') {
            handleReset()
            return
        }

        if (!isUrl(href)) {
            setIsErrorInput(true)
            return
        }

        try {
            onSet(href, isNewTab ? TARGETS.BLANK : TARGETS.SELF)
            onClosePopover()
        } catch (err) {
            console.error(err)
        }
    }
    const handleReset = () => {
        onReset()
        onClosePopover()
    }

    return (
        <div className={styles.linkEditor}>
            <div className={styles.mainBox}>
                <div
                    className={classNames(styles.inputBox, { [styles.isErrorInputBox]: isErrorInput })}
                    onClick={() => inputRef.current.focus()}
                >
                    <LinkIcon />
                    <input
                        ref={inputRef}
                        className={styles.input}
                        type="text"
                        value={href}
                        onChange={evt => setHref(evt.target.value)}
                        placeholder={t('Enter URL')}
                    />
                </div>
                <div className={classNames(styles.inputButton, styles.inputButtonAccept)} onClick={handleSet}>
                    <AcceptIcon />
                </div>
                <div className={classNames(styles.inputButton, styles.inputButtonDelete)} onClick={handleReset}>
                    <DeleteIcon />
                </div>
            </div>
            <div className={styles.targetBox}>
                {t('Open in new tab')} <Toggle value={isNewTab} onUpdate={v => setIsNewTab(v)} />
            </div>
        </div>
    )
}

export default LinkEditor
