import { CDN_URL } from '../common/constants/constants'

export const getProjectEmbedCodeWithLoader = hash =>
    `<div class="remix-app" data-hash="${hash}">
        <script src="${CDN_URL}/l.js" async></script>
    </div>`

export const getProjectEmbedCodeWithIFrame = hash =>
    `<div class="interacty_padding" style="position: relative; padding: 99.5% 0 0 0;">
        <div class="interacty_wrapper" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; display: flex; justify-content: center;">
            <iframe style="border: none; width: 100%; height: 100%;" src="${CDN_URL}/${hash}/iframe.html"></iframe>
        </div>
    </div>`
