import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import ActionsDropdown, { TYPES } from '../../../components/ActionsDropdown/ActionsDropdown'

import useHovered from "hooks/useHovered"

import './Page.scss'

const AuthPage = ({ methods, isSelected }) => {
    const { t } = useTranslation()

    const [isHovered, onMouseOver, onMouseLeave] = useHovered()

    return (
        <div className={classNames("internal-pages__wrapper", "internal-pages__auth-wrapper")}>
            <div
                onClick={() => methods.onSelect()}
                className={classNames('internal-pages__auth-page', { 'select': isSelected })}
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
            >
                <div className="internal-pages__auth-page-label">
                    {t("Login page")}
                </div>

                <div className="internal-pages__auth-page-img is-has-form"/>

                <ActionsDropdown
                    type={TYPES.AUTH}
                    isVisible={isHovered || isSelected}
                    methods={{
                        onRemove: () => methods.onRemove(),
                    }}
                />
            </div>
        </div>
    )
}

export default AuthPage
