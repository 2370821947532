const Underline = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        <path d="M6 4v6a6 6 0 0 0 12 0V4"></path>
        <line x1="4" x2="20" y1="20" y2="20"></line>
    </svg>
)

export default Underline
