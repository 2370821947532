import { useState, useEffect, useContext, useRef, useCallback } from 'react'
import { HexColorPicker } from 'react-colorful'
import classNames from 'classnames'
import { debounce } from 'lodash'

import { PopoverContext } from '../../Popover'

import { getHex } from './utils'

import ArrowIcon from './svg/Arrow'

import styles from './ColorPicker.module.scss'

const PRESET_COLORS = ['#fb7185', '#fdba74', '#d9f99d', '#a7f3d0', '#a5f3fc', '#a5b4fc']

const ColorPicker = ({ value, onChange, onReset }) => {
    const inputRef = useRef()
    const { onClosePopover } = useContext(PopoverContext)

    const [inputValue, setInputValue] = useState(() => getHex(value) || '')
    const [isErrorInput, setIsErrorInput] = useState(false)

    useEffect(() => {
        setInputValue(getHex(value) || '')
    }, [value])

    useEffect(() => {
        if (inputValue === value || !inputValue) {
            setIsErrorInput(false)
            return
        }

        const newHex = getHex(inputValue)
        if (newHex) {
            onChange(newHex)
            setTimeout(() => inputRef.current.focus())
            setIsErrorInput(false)
        } else setIsErrorInput(true)
    }, [inputValue])

    const onDelayedChange = useCallback(
        debounce(color => onChange(color), 50),
        [],
    )

    return (
        <div className={styles.colorPicker}>
            <HexColorPicker color={value || undefined} onChange={newColor => onDelayedChange(newColor)} />
            <div className={styles.inputBox}>
                <input
                    ref={inputRef}
                    className={classNames(styles.input, { [styles.isErrorInput]: isErrorInput })}
                    type="text"
                    value={inputValue || ''}
                    onChange={evt => setInputValue(evt.target.value)}
                    placeholder="#2990fb"
                />
            </div>
            <ul className={styles.presets}>
                {PRESET_COLORS.map(color => (
                    <li
                        key={color}
                        className={styles.preset}
                        onClick={() => {
                            onChange(color)
                            onClosePopover()
                        }}
                        style={{ backgroundColor: color }}
                    />
                ))}
                <li
                    className={classNames(styles.reset, { [styles.isDisabledReset]: !value })}
                    onClick={() => {
                        onReset()
                        onClosePopover()
                    }}
                >
                    <ArrowIcon />
                </li>
            </ul>
        </div>
    )
}

export default ColorPicker
