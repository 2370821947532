import React from 'react'

import TextEditor, { TEXT_EDITOR_MODES } from 'components/TextEditor/TextEditor'

const Article = ({ t, isTransparentBackground, backgroundColor, content, id, onUpdate }) => (
    <div
        className={`block __${t}`}
        style={{ backgroundColor: isTransparentBackground ? 'transparent' : backgroundColor }}
    >
        <TextEditor
            mode={TEXT_EDITOR_MODES.SEPARATE}
            content={content}
            onChange={html => {
                onUpdate({
                    [id]: {
                        data: { content: html },
                    },
                })
            }}
        />
    </div>
)

export default Article
