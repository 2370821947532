const AlignLeft = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        <path d="M15 12H3"></path>
        <path d="M17 18H3"></path>
        <path d="M21 6H3"></path>
    </svg>
)

export default AlignLeft
