import TextInput from './components/Controls/TextInput/TextInput'
import TextArea from './components/Controls/TextArea/TextArea'
import UploadButton from './components/Controls/UploadButton/UploadButton'
import ColorPicker from './components/Controls/ColorPickerControl/ColorPickerControl'
import Checkbox from './components/Controls/Checkbox/Checkbox'
import RadioButton from './components/Controls/RadioButton/RadioButton'
import Select from './components/Controls/Select/Select'
import ArrayWithModal from './components/Controls/ArrayWithModal/ArrayWithModal'
import LinkWithModal from './components/Controls/LinkWithModal/LinkWithModal'
import InputWithModal from './components/Controls/InputWithModal/InputWithModal'
import PaddingEditor from './components/Controls/PaddingEditor/PaddingEditor'
import DemoWithModal from './components/Controls/DemoWithModal/DemoWithModal'
import CssCodeEditor from './components/Controls/CodeEditor/CSS/CSS'

export const CONTROLS_COMPONENTS = {
    TextInput,
    TextArea,
    UploadButton,
    ColorPicker,
    Checkbox,
    RadioButton,
    Select,
    ArrayWithModal,
    LinkWithModal,
    InputWithModal,
    PaddingEditor,
    DemoWithModal,
    CssCodeEditor,
}

export const CONTROLS_ENUM = {
    TextInput: 'TextInput',
    TextArea: 'TextArea',
    UploadButton: 'UploadButton',
    ColorPicker: 'ColorPicker',
    Checkbox: 'Checkbox',
    RadioButton: 'RadioButton',
    Select: 'Select',
    ArrayWithModal: 'ArrayWithModal',
    LinkWithModal: 'LinkWithModal',
    InputWithModal: 'InputWithModal',
    PaddingEditor: 'PaddingEditor',
    DemoWithModal: 'DemoWithModal',
    CssCodeEditor: 'CssCodeEditor',
}
