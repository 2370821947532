import React from 'react'
import { useTranslation } from 'react-i18next'

import Image from 'components/Form/Image'
import Tooltip from 'components/Tooltip/Tooltip'
import { useAssetManager } from 'components/AssetManager'
import { ASSET_CONTENT_TYPE, ASSET_TABS } from 'components/AssetManager/utils/constants'

import { IconMusicalNote } from 'svg'

import './UploadButton.scss'

const UploadButton = props => {
    const {
        name,
        id,
        onUpdate,
        payload,
        tooltipText,
        value,
        valueName,
        schema: { label = '', payload: { isCanDelete = true, contentType } = {} },
    } = props
    const { t } = useTranslation()
    const { chooseAsset, deleteAsset } = useAssetManager(true)

    const onUpdateWrapper = data => onUpdate(id, { [name]: data.src, author: data.author, name: data.name }, payload)
    const onDelete = () => onUpdate(id, { [name]: '', author: null }, payload)

    const onChooseAsset = () => {
        const settings =
            contentType === ASSET_CONTENT_TYPE.audio
                ? { tab: ASSET_TABS.TAB_AUDIO, managerTabs: [ASSET_TABS.TAB_AUDIO, ASSET_TABS.TAB_FREE_AUDIO] }
                : undefined
        chooseAsset(value, onUpdateWrapper, settings)
    }

    return (
        <div className="upload-button">
            <p className="upload-button__label">
                {label}
                {<Tooltip tooltipText={tooltipText} />}
            </p>
            {valueName && <p className="upload-button__value-name">{valueName}</p>}
            <div className="content upload">
                {value &&
                    (contentType === ASSET_CONTENT_TYPE.audio ? (
                        <div className="upload-button__file-icon">
                            <IconMusicalNote />
                        </div>
                    ) : (
                        <Image className="upload-button__img-preview" src={value} />
                    ))}
                <button className="upload-btn" onClick={onChooseAsset}>
                    {value ? t('Change') : t('Upload')}
                </button>
                {value && isCanDelete ? (
                    <button className="remove" onClick={() => deleteAsset(value, onDelete)} />
                ) : null}
            </div>
        </div>
    )
}

export default UploadButton
