import React from 'react'
import { useTranslation } from 'react-i18next'

import { pickColor } from 'utils/colors'

import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'

import Preview from './components/Preview/Preview'

import LeadFormEditorView from './LeadFormEditorView'

import './Form.scss'

const Form = ({
    isEnableRating,
    structure,
    backgroundColor,
    fontFamily,
    validateErrors,
    methods: { updateStructure },
    mode,
}) => {
    const { t } = useTranslation()

    return (
        <div className="lead-form__form">
            <LeadFormEditorView
                mode={mode}
                isEnableRating={isEnableRating}
                structure={structure}
                validateErrors={validateErrors}
                onUpdateStructure={updateStructure}
            />
            <div className="lead-form__form__preview-wrapper">
                <ScrollableBlock>
                    <div className="lead-form__form__preview">
                        <p className="lead-form__form__preview-title">{t('Approximate preview')}</p>
                        <Preview
                            isModal
                            backgroundColor={backgroundColor}
                            color={pickColor(backgroundColor, '#3C3C3C', '#fff')}
                            fontFamily={fontFamily}
                            fields={structure.form.fields}
                            logoImage={structure.form.logoImage}
                            headerText={structure.form.headerText}
                            descriptionText={structure.form.descriptionText}
                            buttonText={structure.form.buttonText}
                            linkPrivacyPolicyLink={structure.form.linkPrivacyPolicyLink}
                            additionalNotes={structure.form.additionalNotes}
                        />
                    </div>
                </ScrollableBlock>
            </div>
        </div>
    )
}

export default Form
