import React, { useMemo, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import classNames from 'classnames'
import { default as RcDropdown } from 'rc-dropdown'
import Menu, { Item as MenuItem } from 'rc-menu'

import 'rc-dropdown/assets/index.css'

import Modal from 'components/Modal/Modal'

import './DropdownMenu.scss'

const DropdownMenu = ({
    className,
    actionHtml,
    items = [],
    selectable = false,
    placement,
    overlayClassName,
    menuClassName,
    menuItemClassName,
}) => {
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' })
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    const onVisibleChange = isOpen => {
        setIsDropdownOpen(isOpen)
    }

    const onCloseModal = () => {
        setIsDropdownOpen(false)
    }

    const onMenuClick = () => {
        setIsDropdownOpen(false)
    }

    const menu = useMemo(
        () => (
            <Menu
                selectable={selectable}
                onClick={onMenuClick}
                className={classNames({ [menuClassName]: !!menuClassName })}
            >
                {items
                    .filter(item => !!item)
                    .map((item, index) => (
                        <MenuItem key={index} className={classNames({ [menuItemClassName]: !!menuItemClassName })}>
                            {item}
                        </MenuItem>
                    ))}
            </Menu>
        ),
        [items, selectable, menuClassName, menuItemClassName],
    )

    return (
        <>
            <RcDropdown
                visible={isDropdownOpen && !isMobile}
                onVisibleChange={onVisibleChange}
                overlay={menu}
                trigger={['click']}
                placement={placement}
                overlayClassName={overlayClassName}
            >
                <div className={classNames('dropdown-menu', { [className]: !!className })}>
                    {actionHtml}
                </div>
            </RcDropdown>
            {isDropdownOpen && isMobile && (
                <Modal isShowCloseIcon closeOnOverlayClick={false} closeOnEsc={false} onClose={onCloseModal}>
                    <div className="dropdown-menu-modal">{menu}</div>
                </Modal>
            )}
        </>
    )
}

export default DropdownMenu
