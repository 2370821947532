import { useState, useEffect } from 'react'

import { CONTROLS } from '../../../controls'

const useControls = ({
    isMultiplayerGame,
    controlKey,
    typeIdentifier,
    blockData,
    appData,
    integrations,
    totalPagesCount,
}) => {
    const [controls, setControls] = useState([])

    useEffect(() => {
        const _controls = typeIdentifier ? CONTROLS[controlKey][typeIdentifier] : CONTROLS[controlKey]

        if (!_controls) return []

        setControls(
            _controls.map(group => ({
                ...group,
                isVisible: group.isVisible
                    ? group.isVisible({ blockData, isMultiplayerGame, integrations, totalPagesCount, appData })
                    : true,
                isDisabled: group.isDisabled ? group.isDisabled({ blockData, appData }) : false,
                values: group.values.map(value => ({
                    ...value,
                    isVisible: value.isVisible
                        ? value.isVisible({ blockData, isMultiplayerGame, integrations, totalPagesCount, appData })
                        : true,
                    isDisabled: value.isDisabled ? value.isDisabled({ blockData, appData }) : false,
                    fieldValue: value.getValue ? value.getValue({ blockData, appData }) : undefined,
                })),
                tooltipText: typeof group.tooltipText === 'function' ? group.tooltipText() : group.tooltipText,
                tab: !!group.tab ? {
                    ...group.tab,
                    isActive: group.tab.isActive ? group.tab.isActive({ blockData, appData }) : false,
                } : null,
            })),
        )
    }, [isMultiplayerGame, controlKey, typeIdentifier, blockData, appData, integrations, totalPagesCount])

    return { controls }
}

export default useControls
