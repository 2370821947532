import { Extension } from '@tiptap/core'

import { REMIX_SUPPORTED_FONTS } from 'shared/constants/fonts'

const FontFamily = Extension.create({
    name: 'fontFamily',

    addOptions() {
        return {
            types: ['textStyle'],
        }
    },

    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    fontFamily: {
                        default: null,
                        parseHTML: element => {
                            const fontFamily = element.style.fontFamily.replace(/['"]+/g, '')
                            if (!fontFamily || !REMIX_SUPPORTED_FONTS.includes(fontFamily)) return null
                            return fontFamily
                        },
                        renderHTML: ({ fontFamily }) => {
                            if (!fontFamily) return {}

                            return {
                                style: `font-family: ${fontFamily}`,
                            }
                        },
                    },
                },
            },
        ]
    },

    addCommands() {
        return {
            setFontFamily: fontFamily => ({ chain }) => {
                return chain().setMark('textStyle', { fontFamily }).run()
            },
            unsetFontFamily: () => ({ chain }) => {
                return chain().setMark('textStyle', { fontFamily: null }).removeEmptyTextStyle().run()
            },
        }
    },
})

export default FontFamily
