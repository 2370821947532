import { AUTHORIZATION_TYPES, AUTHORIZATION_TOKEN_LIFETIMES } from './Tabs/Editor/schema'

const AUTH_APPROVAL_TYPES = {
    NO: 'NO',
    [AUTHORIZATION_TYPES.EMAIL_WITHOUT_CONFIRMATION]: 'EMAIL',
    [AUTHORIZATION_TYPES.EMAIL_WITH_CONFIRMATION]: 'APPROVAL_EMAIL',
}

const TOKEN_LIFETIME = {
    [AUTHORIZATION_TOKEN_LIFETIMES.ONE_SESSION]: 0,
    [AUTHORIZATION_TOKEN_LIFETIMES.ONE_DAY]: 86400 * 1000,
    [AUTHORIZATION_TOKEN_LIFETIMES.ONE_WEEK]: 604800 * 1000,
    [AUTHORIZATION_TOKEN_LIFETIMES.ONE_MONTH]: 2592000 * 1000,
}

export const getAuthSettings = ({ isUseAuthorization, authorizationType, authorizationTokenLifetime }) => {
    const result = {
        approvalType: AUTH_APPROVAL_TYPES.NO,
        tokenLifeTime: null,
    }

    if (!isUseAuthorization) return result

    result.approvalType = AUTH_APPROVAL_TYPES[authorizationType]
    result.tokenLifeTime = TOKEN_LIFETIME[authorizationTokenLifetime]

    return result
}
