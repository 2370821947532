import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import styles from './QuestionView.module.scss'

const SIZES = {
    BIG: 'BIG',
    SMALL: 'SMALL',
    MEDIUM: 'MEDIUM',
}

const QuestionView = ({ question, fontFamily }) => {
    const [isBigImage, setIsBigImage] = useState(false)
    const [size, setSize] = useState(false)

    useEffect(() => {
        setIsBigImage(question.answers.length <= 3 || (question.answers.length >= 5 && question.answers.length <= 6))
        setSize(question.text.length <= 100 ? SIZES.BIG : question.image ? SIZES.SMALL : SIZES.MEDIUM)
    }, [question])

    return (
        <div className={styles.questionView}>
            <div className={classNames(styles.question, { [styles.noImageQuestion]: !question.image })}>
                {question.image ? (
                    <>
                        <img className={styles.questionImage} src={question.image} alt="Question image" />
                        <div className={styles.questionTextWrap}>
                            <div
                                className={classNames(styles.questionText, {
                                    [styles.questionTextBig]: size === SIZES.BIG,
                                    [styles.questionTextMedium]: size === SIZES.MEDIUM,
                                    [styles.questionTextSmall]: size === SIZES.SMALL,
                                })}
                                style={{ fontFamily }}
                            >
                                {question.text}
                            </div>
                        </div>
                    </>
                ) : (
                    <div
                        className={classNames(styles.questionText, {
                            [styles.questionTextBig]: size === SIZES.BIG,
                            [styles.questionTextMedium]: size === SIZES.MEDIUM,
                            [styles.questionTextSmall]: size === SIZES.SMALL,
                        })}
                        style={{ fontFamily }}
                    >
                        {question.text}
                    </div>
                )}
            </div>

            <ul className={classNames(styles.answers, { [styles.isImageAnswers]: !question.isText })}>
                {question.answers.map(answer => (
                    <li
                        key={answer.id}
                        className={classNames(styles.answer, {
                            [styles.isImageAnswer]: !question.isText,
                            [styles.isBigImageAnswer]: !question.isText && isBigImage,
                        })}
                    >
                        {question.isText ? (
                            <div className={styles.answerText} style={{ fontFamily }}>
                                {answer.text}
                            </div>
                        ) : (
                            <div className={styles.answerImageWrapper}>
                                <img
                                    className={classNames(styles.answerImage, {
                                        [styles.isBigAnswerImage]: isBigImage,
                                    })}
                                    src={answer.image}
                                    alt="Answer image"
                                />
                                {!!answer.imageLabel && (
                                    <p className={styles.answerImageLabel} style={{ fontFamily }}>
                                        {answer.imageLabel}
                                    </p>
                                )}
                            </div>
                        )}
                    </li>
                ))}
            </ul>

            {!!question.image && !!question.imageDisclaimer && (
                <div className={styles.questionImageDisclaimer} style={{ fontFamily }}>
                    {question.imageDisclaimer}
                </div>
            )}
        </div>
    )
}

export default QuestionView
