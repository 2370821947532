import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { cloneDeep, defaultsDeep } from 'lodash'

import ScrollableBlock from 'components/ScrollableBlock/ScrollableBlock'
import Checkbox from 'components/Form/Checkbox/Checkbox'
import Select from 'components/Form/Select/Select'
import TextInput from 'components/Form/TextInput/TextInput'
import TextArea from 'components/Form/TextArea/TextArea'
import UploadImage from 'components/Form/UploadImage/UploadImage'
import MailChimpIntegrations from 'components/MailChimp/MailChimpIntegrations'
import { useMailChimpManager } from 'components/MailChimp/useMailChimpManager'
import WebhooksIntegrations from 'components/Webhooks/components/WebhooksIntegrations/WebhooksIntegrations'
import { useWebhooksManager } from 'components/Webhooks/useWebhooksManager'

import { CONDITIONS, hasPermission } from 'common/utils/permissions'

import AddListItemButton from '../../../../Blocks/components/Controls/AddListItemButton/AddListItemButton'

import {
    selectCustomFields,
    selectBasicFields,
    createCustomField,
    createDefaultField,
    getInitialValueByType,
} from '../../utils'
import {
    DEFAULT_FORM_FIELDS,
    FIELD_KEYS,
    FIELD_TYPES_DETAILS,
    FORM_KEYS,
    MAX_CUSTOM_FIELDS,
    SORTED_FIELD_KEYS,
} from '../../constants'

import './Form.scss'

const LeadFormEditorView = ({ isEnableRating, structure, validateErrors, onUpdateStructure, mode }) => {
    const canUseIntegrations = hasPermission(CONDITIONS.CAN_USE_INTEGRATIONS)
    const { t } = useTranslation()
    const [customFields, setCustomFields] = useState([])
    const {
        openManager: openMailChimpManager,
        switchConnector: switchMailChimpConnector,
        isEnabled: isMailChimpEnabled,
    } = useMailChimpManager({
        fields: structure.form.fields,
    })
    const { openManager: openWebhooksManager, isEnabled: isWebhooksEnabled } = useWebhooksManager()

    useEffect(() => {
        const _fields = structure.form.fields
        if (isEnableRating && _fields.findIndex(el => el.key === FIELD_KEYS.EMAIL) === -1) {
            addOrRemoveDefaultField(FIELD_KEYS.EMAIL, true)
        }

        const _customFields = selectCustomFields(_fields)
        setCustomFields(_customFields)
    }, [isEnableRating, structure.form.fields])

    const addOrRemoveDefaultField = (fieldKey, isAdd) => {
        let tmpFields = cloneDeep(structure.form.fields)
        if (isAdd) {
            const newField = createDefaultField(fieldKey)
            tmpFields.push(newField)
            const basicFields = selectBasicFields(tmpFields)
            const customFields = selectCustomFields(tmpFields)
            tmpFields = [
                ...basicFields.sort((a, b) => SORTED_FIELD_KEYS.indexOf(a.key) - SORTED_FIELD_KEYS.indexOf(b.key)),
                ...customFields,
            ]
        } else {
            tmpFields = tmpFields.filter(el => el.key !== fieldKey)

            const isEmailFieldRemoved = fieldKey === FIELD_KEYS.EMAIL
            const shouldDisableMailChimpIntegration = isMailChimpEnabled && isEmailFieldRemoved
            if (shouldDisableMailChimpIntegration) {
                switchMailChimpConnector({ notify: true })
            }
        }
        onUpdateStructure({ [FORM_KEYS.fields]: tmpFields })
    }

    const addCustomField = () => {
        const tmpFields = cloneDeep(structure.form.fields)
        const newField = createCustomField(customFields)
        tmpFields.push(newField)
        onUpdateStructure({ [FORM_KEYS.fields]: tmpFields })
    }

    const removeCustomField = fieldKey => {
        const tmpFields = cloneDeep(structure.form.fields).filter(el => el.key !== fieldKey)
        onUpdateStructure({ [FORM_KEYS.fields]: tmpFields })
    }

    const updateCustomField = (field, updatedProps) => {
        const tmpFields = cloneDeep(structure.form.fields)
        const updatedField = defaultsDeep(updatedProps, field)
        const fieldIndex = tmpFields.findIndex(el => el.key === field.key)
        if (fieldIndex === -1) return
        if (updatedProps.type) updatedField.initialValue = getInitialValueByType(updatedProps.type)
        tmpFields[fieldIndex] = updatedField
        onUpdateStructure({ [FORM_KEYS.fields]: tmpFields })
    }

    const isMaxLimitExceeded = customFields.length >= MAX_CUSTOM_FIELDS

    return (
        <div className="lead-form__form__workplace-wrapper">
            <ScrollableBlock>
                <div className="lead-form__form__workplace">
                    <div className="lead-form__form__workplace--box">
                        <h3 className="lead-form__form__workplace-title">
                            {t('Form fields')}
                        </h3>
                        <div className="lead-form__form__workplace-content">
                            <div className="fields-container">
                                <ul className="fields">
                                    {Object.entries(DEFAULT_FORM_FIELDS).map(([key, value]) => {
                                        const fieldIndex = structure.form.fields.findIndex(el => el.key === key)
                                        const isReadonly = fieldIndex !== -1 ? structure.form.fields[fieldIndex].isReadonly : false
                                        return (
                                            <li key={key}>
                                                <Checkbox
                                                    label={value.checkboxLabel}
                                                    value={fieldIndex !== -1}
                                                    onUpdate={v => addOrRemoveDefaultField(key, v)}
                                                    isDisabled={isReadonly || (key === FIELD_KEYS.EMAIL && isEnableRating)}
                                                />
                                            </li>
                                        )
                                    })}
                                </ul>
                                {customFields.map(customField => (
                                    <div key={customField.key} className="custom-field">
                                        <Select
                                            label={t('Field type')}
                                            value={FIELD_TYPES_DETAILS[customField.type]}
                                            onUpdate={type => updateCustomField(customField, { type: type.value })}
                                            options={Object.values(FIELD_TYPES_DETAILS)}
                                            fullWide
                                        />
                                        <TextInput
                                            isRequired
                                            label={t('Field name')}
                                            value={customField.label}
                                            onUpdate={value => updateCustomField(customField, { label: value })}
                                            fullWide
                                            maxLength={FIELD_TYPES_DETAILS[customField.type].maxLength || 128}
                                            errorMessages={
                                                validateErrors.fields?.size &&
                                                validateErrors.fields.get(customField.key)?.label
                                            }
                                        />
                                        <button
                                            className="delete-field-button"
                                            onClick={() => removeCustomField(customField.key)}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        {!isMaxLimitExceeded && <AddListItemButton title={t('Add field')} onClick={addCustomField} />}
                    </div>
                    <div className="lead-form__form__workplace--box">
                        <h3 className="lead-form__form__workplace-title">{t('Additional information')}</h3>
                        <div className="lead-form__form__workplace-content">
                            <UploadImage
                                label={t('Logo')}
                                value={structure.form[FORM_KEYS.logoImage]}
                                onUpdate={value => onUpdateStructure({ [FORM_KEYS.logoImage]: value })}
                            />
                            <TextInput
                                label={t('Header')}
                                value={structure.form[FORM_KEYS.headerText]}
                                errorMessages={validateErrors[FORM_KEYS.headerText]}
                                onUpdate={value => onUpdateStructure({ [FORM_KEYS.headerText]: value })}
                                data-test="header-text-input"
                            />
                            <TextArea
                                label={t('Description')}
                                value={structure.form[FORM_KEYS.descriptionText]}
                                errorMessages={validateErrors[FORM_KEYS.descriptionText]}
                                onUpdate={value => onUpdateStructure({ [FORM_KEYS.descriptionText]: value })}
                                data-test="description-text-input"
                            />
                            <TextInput
                                label={t('Button text')}
                                isRequired
                                value={structure.form[FORM_KEYS.buttonText]}
                                errorMessages={validateErrors[FORM_KEYS.buttonText]}
                                onUpdate={value => onUpdateStructure({ [FORM_KEYS.buttonText]: value })}
                                data-test="button-text-input"
                            />
                            <TextInput
                                label={t('Link to your privacy policy')}
                                value={structure.form[FORM_KEYS.linkPrivacyPolicyLink]}
                                errorMessages={validateErrors[FORM_KEYS.linkPrivacyPolicyLink]}
                                onUpdate={value => onUpdateStructure({ [FORM_KEYS.linkPrivacyPolicyLink]: value })}
                                data-test="link-input"
                            />
                            <TextArea
                                label={t('Any additional notes')}
                                value={structure.form[FORM_KEYS.additionalNotes]}
                                errorMessages={validateErrors[FORM_KEYS.additionalNotes]}
                                onUpdate={value => onUpdateStructure({ [FORM_KEYS.additionalNotes]: value })}
                                data-test="description-text-input"
                            />
                        </div>
                    </div>
                    {canUseIntegrations && (
                        <div className="lead-form__form__workplace--box">
                            <h3 className="lead-form__form__workplace-title">{t('Integrations')}</h3>
                            <div className="lead-form__form__workplace-content">
                                <MailChimpIntegrations
                                    openManager={openMailChimpManager}
                                    onSwitchConnector={switchMailChimpConnector}
                                    isEnabled={isMailChimpEnabled}
                                />
                                <WebhooksIntegrations openManager={openWebhooksManager} isEnabled={isWebhooksEnabled} />
                            </div>
                        </div>
                    )}
                </div>
            </ScrollableBlock>
        </div>
    )
}

export default LeadFormEditorView
