import i18n from 'i18n'

export const PERIOD_INTERVALS = {
    ONE_DAY: '1',
    SEVEN_DAYS: '7',
    THIRTY_DAYS: '30',
    ALL_TIME: 'ALL_TIME',
    CUSTOM: 'CUSTOM',
}

export const PERIOD_INTERVALS_MS = {
    ONE_DAY: 86400000,
    SEVEN_DAYS: 604800000,
    THIRTY_DAYS: 2592000000,
}

export const PERIOD_TYPES = {
    [PERIOD_INTERVALS.ONE_DAY]: {
        name: i18n.t('{{number}} day', { number: 1 }),
        value: PERIOD_INTERVALS.ONE_DAY,
        getTimesRange: (to = Date.now()) => ({ to, from: to - PERIOD_INTERVALS_MS.ONE_DAY }),
    },
    [PERIOD_INTERVALS.SEVEN_DAYS]: {
        name: i18n.t('{{number}} days', { number: 7 }),
        value: PERIOD_INTERVALS.SEVEN_DAYS,
        getTimesRange: (to = Date.now()) => ({ to, from: to - PERIOD_INTERVALS_MS.SEVEN_DAYS }),
    },
    [PERIOD_INTERVALS.THIRTY_DAYS]: {
        name: i18n.t('{{number}} days', { number: 30 }),
        value: PERIOD_INTERVALS.THIRTY_DAYS,
        getTimesRange: (to = Date.now()) => ({ to, from: to - PERIOD_INTERVALS_MS.THIRTY_DAYS }),
    },
    [PERIOD_INTERVALS.ALL_TIME]: {
        name: i18n.t('All this time'),
        value: PERIOD_INTERVALS.ALL_TIME,
        getTimesRange: () => ({ to: Date.now(), from: 1546300800000 }),
    },
    [PERIOD_INTERVALS.CUSTOM]: {
        name: i18n.t('Custom'),
        value: PERIOD_INTERVALS.CUSTOM,
        getTimesRange: (to = Date.now()) => ({ to, from: to - PERIOD_INTERVALS_MS.THIRTY_DAYS }),
    },
}