import { Extension } from '@tiptap/core'

const LineHeight = Extension.create({
    name: 'lineHeight',

    addOptions() {
        return {
            types: ['textStyle'],
        }
    },

    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    lineHeight: {
                        default: null,
                        parseHTML: element => {
                            return element.style.lineHeight
                        },
                        renderHTML: ({ lineHeight }) => {
                            if (!lineHeight) return {}

                            return { style: `line-height: ${lineHeight}` }
                        },
                    },
                },
            },
        ]
    },

    addCommands() {
        return {
            setLineHeight: lineHeight => ({ chain }) => {
                return chain().setMark('textStyle', { lineHeight }).run()
            },
            unsetLineHeight: () => ({ chain }) => {
                return chain().setMark('textStyle', { lineHeight: null }).removeEmptyTextStyle().run()
            },
        }
    },
})

export default LineHeight
