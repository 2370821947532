import React, { useEffect, useState } from 'react'
import {useTranslation} from "react-i18next"
import { throttle } from 'lodash'
import classNames from 'classnames'

import styles from "./PanelHeader.module.scss"

const PanelHeader = ({ className = '', blockName, isSecondary, onClose, scrollRef }) => {
    const { t } = useTranslation()
    const [titleClass, setTitleClass] = useState('')

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.addEventListener('scroll', throttle(handleScroll, 50))
        }

        return () => {
            if (scrollRef.current) {
                scrollRef.current.removeEventListener('scroll', throttle(handleScroll, 50))
            }
        }
    }, [scrollRef, titleClass])

    const handleScroll = evt => {
        try {
            const newTitleClass = evt.target.scrollTop > 0 ? 'is-sticky' : ''

            if (titleClass !== newTitleClass) {
                setTitleClass(newTitleClass)
            }
        } catch (err) {
            console.error(err)
        }
    }

    if (!blockName) return null

    return (
        <h3 className={classNames(className, titleClass)}>
            {isSecondary && (
                <div className={styles.backButton} onClick={onClose} title={t('Back to App settings')}/>
            )}
            {blockName}
        </h3>
    )
}

export default PanelHeader
