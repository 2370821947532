import COOKIES from 'common/constants/cookies'

import { getCookie, setCookie } from '../utils/cookies'

import { initialState } from './initialState'

export default (state = initialState, action) => {
    switch (action.type) {
        // Loader
        case 'ADD_LOADING_PROCESS':
            state = {
                ...state,
                loadingProcesses: [...state.loadingProcesses, action.payload],
            }
            break
        case 'REMOVE_LOADING_PROCESS':
            state = {
                ...state,
                loadingProcesses: state.loadingProcesses.filter(processId => processId !== action.payload),
            }
            break
        // Auth
        case 'AUTH__LOGOUT':
            state = {
                ...initialState,
                language: state.language,
                clientCountry: state.clientCountry,
            }
            break
        // User
        case 'USER__SET_DETAILS':
            state = {
                ...state,
                user_details: action.payload,
            }
            break
        case 'USER__SET_SUBSCRIPTION':
            state = {
                ...state,
                user_subscription: action.payload,
            }
            break
        case 'USER__SET_SUBSCRIPTION_CONSTRAINTS':
            state = {
                ...state,
                user_subscription_constraints: action.payload,
            }
            break
        case 'USER__SET_SUBSCRIPTIONS':
            state = {
                ...state,
                user_subscriptions: action.payload,
            }
            break
        case 'USER__SET_PUBLIC_PROFILE':
            state = {
                ...state,
                userPublicProfile: action.payload,
            }
            break
        // Common
        case 'COMMON__SET_DEV_MODE':
            state = {
                ...state,
                isDevMode: action.payload,
            }
            break
        case 'COMMON__SET_LANGUAGE':
            const { code, isForceSetCookie = true } = action.payload
            state = {
                ...state,
                language: code,
            }

            if (isForceSetCookie || !getCookie(COOKIES.LOCALE)) {
                setCookie(COOKIES.LOCALE, code)
            }
            break
        case 'COMMON__SET_CLIENT_COUNTRY':
            state = {
                ...state,
                clientCountry: action.payload,
            }
            break
        case 'COMMON__SET_PAYMENT_CURRENCY':
            state = {
                ...state,
                paymentCurrency: action.payload,
            }
            break
        // Service
        case 'SERVICE__SET_NAV_MENU_WIDTH':
            state = {
                ...state,
                _service: {
                    ...state._service,
                    nav_menu_width: action.payload,
                },
            }
            break
        case 'SERVICE__STYLES__SET_HEADER_SIZES':
            state = {
                ...state,
                _service: {
                    ...state._service,
                    styles: {
                        ...state._service.styles,
                        header_width: action.payload.width,
                        header_height: action.payload.height,
                    },
                },
            }
            break
        case 'SERVICE__STYLES__SET_WINDOW_SIZES':
            state = {
                ...state,
                _service: {
                    ...state._service,
                    styles: {
                        ...state._service.styles,
                        window_width: action.payload.width,
                        window_height: action.payload.height,
                    },
                },
            }
            break
        // AssetManager
        case 'ASSET_MANAGER__MANAGER_SET_TAB':
            state = {
                ...state,
                assetManager: {
                    ...state.assetManager,
                    managerTab: action.payload.tab,
                },
            }

            break
        case 'ASSET_MANAGER__LIBRARY__UP_LIST_PAGE':
            state = {
                ...state,
                assetManager: {
                    ...state.assetManager,
                    library: {
                        ...state.assetManager.library,
                        list: {
                            ...state.assetManager.library.list,
                            pageable: {
                                ...state.assetManager.library.list.pageable,
                                pageNumber: state.assetManager.library.list.pageable.pageNumber + 1,
                            },
                        },
                    },
                },
            }

            break
        case 'ASSET_MANAGER__LIBRARY__SET_LIST':
            state = {
                ...state,
                assetManager: {
                    ...state.assetManager,
                    library: {
                        ...state.assetManager.library,
                        list: action.payload.list,
                    },
                },
            }

            break
        case 'ASSET_MANAGER__LIBRARY__SET_IS_LOAD_LIST':
            state = {
                ...state,
                assetManager: {
                    ...state.assetManager,
                    library: {
                        ...state.assetManager.library,
                        isLoadList: action.payload.isLoadList,
                    },
                },
            }
            break
        case 'ASSET_MANAGER__LIBRARY__SET_IS_LOAD_ERROR':
            state = {
                ...state,
                assetManager: {
                    ...state.assetManager,
                    library: {
                        ...state.assetManager.library,
                        isLoadError: action.payload.isLoadError,
                    },
                },
            }
            break
        case 'ASSET_MANAGER__LIBRARY__SET_SELECTED_ITEM_ID':
            state = {
                ...state,
                assetManager: {
                    ...state.assetManager,
                    library: {
                        ...state.assetManager.library,
                        selectedItemId: action.payload.id,
                    },
                },
            }

            break
        case 'ASSET_MANAGER__LIBRARY__SET_SEARCH':
            state = {
                ...state,
                assetManager: {
                    ...state.assetManager,
                    library: {
                        ...state.assetManager.library,
                        search: action.payload.search,
                    },
                },
            }

            break
        case 'ASSET_MANAGER__LIBRARY__RESET_LIBRARY':
            state = {
                ...state,
                assetManager: {
                    ...state.assetManager,
                    library: {
                        ...state.assetManager.library,
                        ...initialState.assetManager.library,
                    },
                },
            }
            break
        case 'ASSET_MANAGER__LIBRARY__UPLOAD_ITEM':
            state = {
                ...state,
                assetManager: {
                    ...state.assetManager,
                    library: {
                        ...state.assetManager.library,
                        offset: state.assetManager.library.offset + 1,
                        list: {
                            ...state.assetManager.library.list,
                            content: [action.payload.item, ...state.assetManager.library.list.content],
                        },
                    },
                },
            }

            break
        case 'ASSET_MANAGER__LIBRARY__DELETE_ITEM':
            state = {
                ...state,
                assetManager: {
                    ...state.assetManager,
                    library: {
                        ...state.assetManager.library,
                        offset: state.assetManager.library.offset - 1,
                        list: {
                            ...state.assetManager.library.list,
                            content: state.assetManager.library.list.content.filter(
                                item => item.originalItemId !== action.payload.id,
                            ),
                        },
                    },
                },
            }
            break
        case 'ASSET_MANAGER__UPDATE_UPLOAD_MEDIA_INFO':
            state = {
                ...state,
                assetManager: {
                    ...state.assetManager,
                    uploadMedia: {
                        ...state.assetManager.uploadMedia,
                        [action.payload.id]: {
                            ...state.assetManager.uploadMedia[action.payload.id],
                            ...action.payload.info,
                        },
                    },
                },
            }

            break
        case 'ASSET_MANAGER__DEL_UPLOAD_MEDIA_INFO':
            const uploadMedia = { ...state.assetManager.uploadMedia }

            delete uploadMedia[action.payload.id]

            state = {
                ...state,
                assetManager: {
                    ...state.assetManager,
                    uploadMedia: {
                        ...uploadMedia,
                    },
                },
            }

            break
        //MODAL MANAGER
        case 'MODAL_MANAGER_SET_MODAL':
            state = {
                ...state,
                modalManager: {
                    ...state.modalManager,
                    name: action.payload.name,
                    payload: action.payload.payload,
                },
            }

            break

        //ORGANIZATIONS
        case 'ORGANIZATIONS__SET': {
            state = {
                ...state,
                organizations: action.payload,
            }
            break
        }
        case 'ORGANIZATIONS__SET_LIST': {
            state = {
                ...state,
                organizations: {
                    ...state.organizations,
                    organizationList: action.payload,
                },
            }
            break
        }
        case 'ORGANIZATIONS__SET_SELECTED_ORGANIZATION': {
            state = {
                ...state,
                organizations: {
                    ...state.organizations,
                    selectedOrganizationId: action.payload,
                },
            }
            break
        }
        case 'ORGANIZATIONS__SET_SELECTED_FOLDER_ID': {
            state = {
                ...state,
                organizations: {
                    ...state.organizations,
                    selectedFolderId: action.payload,
                },
            }
            break
        }
        case 'ORGANIZATIONS__SET_MEMBERS': {
            state = {
                ...state,
                organizations: {
                    ...state.organizations,
                    memberList: action.payload,
                },
            }
            break
        }
        case 'ORGANIZATIONS__SET_SELECTED_MEMBER': {
            state = {
                ...state,
                organizations: {
                    ...state.organizations,
                    selectedMember: action.payload,
                    selectedFolderId: null,
                },
            }
            break
        }
        case 'ORGANIZATIONS__SET_SELECTED_SUBSCRIPTION': {
            state = {
                ...state,
                organizations: {
                    ...state.organizations,
                    selectedSubscription: action.payload,
                },
            }
            break
        }
        //PROJECTS
        case 'PROJECTS__SET_PROJECT_SUBSCRIPTION':
            state = {
                ...state,
                projectSubscription: action.payload,
            }
            break
        default:
            break
    }
    return state
}
