import React, { useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import classNames from "classnames"

import { IconArrowDown } from '../svg'

export default function Dropdown({ active, list, isError, onChange = v => console.log(v) }) {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div
            className={classNames('dropdown', { 'is-open': isOpen, 'is-error': isError })}
            onClick={() => setIsOpen(!isOpen)}
        >
            <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
                <span className={classNames({ 'is-placeholder': active?.placeholder })}>{active?.name}</span>
                <IconArrowDown />
                <ul className="dropdown-list">
                    {list.map(el => (
                        <li key={el.value} onClick={() => onChange(el)}>
                            {el.name}
                        </li>
                    ))}
                </ul>
            </OutsideClickHandler>
        </div>
    )
}
