import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation, Trans } from 'react-i18next'

import { API__USER } from 'api'

import { USER__SET_DETAILS, USER__SET_PUBLIC_PROFILE } from 'store/actions'
import { ORGANIZATIONS_REFRESH_MEMBERS, ORGANIZATIONS_SET_ME_AS_SELECTED_MEMBER } from 'store/actions'

import { isEducation } from 'common/utils/permissions'
import { USER_PUBLIC_PROFILE_COVER_URLS } from 'common/constants/user'

import useUserPublicProfile from 'hooks/useUserPublicProfile'
import useInitialLoading from 'hooks/useInitialLoading'

import CardsList from './components/CardsList/CardsList'
import Card from './components/Card/Card'

import ChangeInfoForm from './views/ChangeInfoForm/ChangeInfoForm'
import ChangePasswordForm from './views/ChangePasswordForm/ChangePasswordForm'
import SetPasswordForm from './views/SetPasswordForm/SetPasswordForm'
import NewsSubscription from './views/NewsSubscription/NewsSubscription'
import AccountDelete from './views/AccountDelete/AccountDelete'
import Integration from './views/Integration/Integration'
import Public from './views/Public/Public'
import Following from './views/Following/Following'
import StripeConnect from './views/StripeConnect/StripeConnect'
import Text from './views/Text/Text'

import PublicHeadMark from './views/Public/components/HeadMark/HeadMark'

import profileIcon from './i/profile.svg'
import earthIcon from './i/earth.svg'
import keyIcon from './i/key.svg'
import groupIcon from './i/group.svg'
import mailIcon from './i/mail.svg'
import integrationIcon from './i/integration.svg'
import crossIcon from './i/cross.svg'
import successIcon from './i/success.svg'

import { PREVIEW_FOLLOWERS_COUNT, RUS_COMPANY_USER_IDS } from './constants'

import styles from './AccountSettings.module.scss'

const AccountSettings = () => {
    const { t } = useTranslation()
    const { organizations, user_details } = useSelector(state => state)
    const dispatch = useDispatch()
    const { isReady, onReady } = useInitialLoading()
    const { userPublicProfile, isPublishedUserPublicProfile, refreshUserPublicProfile } = useUserPublicProfile()

    const [publicProfileTags, setPublicProfileTags] = useState(null)
    const [publicProfileFollowers, setPublicProfileFollowers] = useState(null)

    useEffect(() => {
        if (!isEducation()) {
            onReady()
            return
        }
        getInitialData()
            .then(() => {})
            .catch(err => {
                console.error(err)
            })
            .finally(() => onReady())
    }, [])

    const getInitialData = async () => {
        const [tags, followers] = await Promise.all([
            API__USER.GET_AUTHORS_TAGS(),
            API__USER.GET_USER_PUBLIC_PROFILE_FOLLOWERS(user_details.id, { size: PREVIEW_FOLLOWERS_COUNT, page: 0 }),
        ])
        setPublicProfileTags(tags)
        setPublicProfileFollowers(followers)
    }

    const onFollow = async authorId => {
        await API__USER.ADD_USER_PUBLIC_PROFILE_FOLLOWER(authorId, user_details.id)
    }
    const onUnfollow = async authorId => {
        await API__USER.DELETE_USER_PUBLIC_PROFILE_FOLLOWER(authorId, user_details.id)
    }

    const refreshUserData = async updatedUser => {
        let updatedUserDetails = updatedUser

        if (!updatedUser) updatedUserDetails = await API__USER.GET_DETAILS(user_details.id)

        dispatch(USER__SET_DETAILS(updatedUserDetails))
        await dispatch(ORGANIZATIONS_REFRESH_MEMBERS())

        if (user_details.id === organizations.selectedMember.userId) dispatch(ORGANIZATIONS_SET_ME_AS_SELECTED_MEMBER())
    }

    return (
        <>
            <Helmet>
                <title>{t('Account settings')} | Interacty</title>
            </Helmet>
            {isReady && (
                <div className={styles.accountSettings}>
                    <div className={styles.container}>
                        <h1 className={styles.title}>{t('Account settings')}</h1>
                        <section className={styles.section}>
                            <CardsList>
                                <Card title={t('Manage your profile')} icon={profileIcon}>
                                    <ChangeInfoForm
                                        userDetails={user_details}
                                        isPublished={isPublishedUserPublicProfile}
                                        onSuccess={updatedUser => refreshUserData(updatedUser)}
                                    />
                                </Card>
                                {isEducation() && (
                                    <Card
                                        title={t('Public page')}
                                        icon={earthIcon}
                                        cover={userPublicProfile?.headerLogoUrl || USER_PUBLIC_PROFILE_COVER_URLS[0]}
                                        description={
                                            <Trans
                                                i18nKey="Public page allows you to share your content with Interacty community. Provided information will be visible to other users. <lnk>Read more</lnk>"
                                                components={{
                                                    lnk: (
                                                        <a
                                                            href="https://help.interacty.me/en/content/how-to-create-a-public-template-based-on-your-project"
                                                            target="_blank"
                                                        />
                                                    ),
                                                }}
                                            />
                                        }
                                        onChangeCover={async url => {
                                            let data = {}
                                            if (userPublicProfile) data = { ...userPublicProfile }
                                            await API__USER.UPDATE_PUBLIC_PROFILE(user_details.id, {
                                                ...data,
                                                headerLogoUrl: url,
                                            })
                                            dispatch(USER__SET_PUBLIC_PROFILE({ ...data, headerLogoUrl: url }))
                                        }}
                                        mark={<PublicHeadMark isActive={isPublishedUserPublicProfile} />}
                                    >
                                        <Public
                                            isPublished={isPublishedUserPublicProfile}
                                            userDetails={user_details}
                                            headerLogoUrl={
                                                userPublicProfile?.headerLogoUrl || USER_PUBLIC_PROFILE_COVER_URLS[0]
                                            }
                                            publicProfile={userPublicProfile}
                                            tags={{
                                                content: publicProfileTags.content,
                                            }}
                                            onSuccess={updatedProfile =>
                                                dispatch(USER__SET_PUBLIC_PROFILE(updatedProfile))
                                            }
                                        />
                                    </Card>
                                )}
                                {user_details.isHasPassword ? (
                                    <Card title={t('Update your password')} icon={keyIcon}>
                                        <ChangePasswordForm
                                            userDetails={user_details}
                                            onSuccess={() => refreshUserData(null)}
                                        />
                                    </Card>
                                ) : (
                                    <Card title={t('Set your password')} icon={keyIcon}>
                                        <SetPasswordForm
                                            userDetails={user_details}
                                            onSuccess={() => refreshUserData(null)}
                                        />
                                    </Card>
                                )}
                                {isEducation() && (
                                    <Card
                                        title={t('Following')}
                                        icon={groupIcon}
                                        description={t(
                                            'Here are the authors you have chosen to follow. You will receive email notifications when these authors post new products.',
                                        )}
                                    >
                                        <Following
                                            content={publicProfileFollowers.content}
                                            total={publicProfileFollowers.totalElements}
                                            onFollow={onFollow}
                                            onUnfollow={onUnfollow}
                                        />
                                    </Card>
                                )}
                                <Card title={t('Manage your email subscription')} icon={mailIcon}>
                                    <NewsSubscription
                                        userDetails={user_details}
                                        onSuccess={updatedUser => refreshUserData(updatedUser)}
                                    />
                                </Card>
                                <Card title={t('Integration')} icon={integrationIcon}>
                                    <Integration userDetails={user_details} />
                                </Card>
                                {isEducation() && userPublicProfile && (
                                    <Card
                                        title="Stripe Connect"
                                        badgeIcon={userPublicProfile.payoutProfile?.payoutsEnabled && successIcon}
                                        isWide
                                    >
                                        <StripeConnect
                                            userId={user_details.id}
                                            payoutProfile={userPublicProfile.payoutProfile}
                                            onRefreshUserPublicProfileData={refreshUserPublicProfile}
                                        />
                                    </Card>
                                )}
                                {RUS_COMPANY_USER_IDS.includes(user_details.id) && (
                                    <Card isOnlyContent isWide>
                                        <Text>Доступ к аккаунту на платформе Interacty предоставлен компанией ООО «Игровой формат» в соответствии с Договором № 006-1. ИНН 9731133193 | ОГРН 1247700271356. Юридический адрес: 121205, Россия, г. Москва, вн.тер.г. муниципальный округ Можайский, территория инновационного центра Сколково, б-р Большой, д. 42, стр. 1</Text>
                                    </Card>
                                )}
                                <Card title={t('Delete your account')} icon={crossIcon} isWide>
                                    <AccountDelete userDetails={user_details} />
                                </Card>
                            </CardsList>
                        </section>
                    </div>
                </div>
            )}
        </>
    )
}

export default AccountSettings
