import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import { DEFAULT_ARTBOARD_BACKGROUND_COLOR } from '../../constants'

import styles from './PlaygroundContainer.module.scss'

const PlaygroundContainer = ({ onReset, app, isMultiplayerGame, children }) => {
    const [pageStyles, setPageStyles] = useState({})

    useEffect(() => {
        if (isMultiplayerGame) {
            setPageStyles({
                backgroundColor: 'transparent',
                backgroundImage: 'none',
                boxShadow: 'none',
            })
            return
        }

        let backgroundColor = DEFAULT_ARTBOARD_BACKGROUND_COLOR,
            backgroundImage = 'none',
            backgroundSize = 'cover',
            backgroundRepeat = 'no-repeat'

        if (app.backgroundImage) backgroundImage = `url(${app.backgroundImage})`
        if (!app.isTransparentBackground) backgroundColor = app.bg

        setPageStyles({
            backgroundColor,
            backgroundImage,
            backgroundSize,
            backgroundRepeat,
        })
    }, [isMultiplayerGame, app])

    return (
        <div id="editor-playground-container" className={styles.playgroundContainer}>
            <div className={styles.overlay} onClick={onReset} />
            <div className={classNames('block_page', styles.page)} style={pageStyles} onClick={onReset}>
                {children}
            </div>
        </div>
    )
}

export default PlaygroundContainer
