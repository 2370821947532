import React, { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { API__ANALYTICS } from 'api'

import Modal from 'components/Modal/Modal'
import Loader from 'components/Loader/Loader'

import { getDateTime } from 'utils/dateTime'

import { FIELD_KEYS } from 'pages/Editor/Tabs/Editor/Modals/LeadFormModal/constants'

import { mapLeadInfo } from '../../../../../utils'

import AnalyticsContext from '../../../../../analyticsContext'

import { getColumnView } from '../utils'

import styles from './LeadInfoModal.module.scss'

const LeadInfoModal = ({ projectId, sessionId, blockProjection, dateRange, onClose, isRating }) => {
    const { t } = useTranslation()
    const { authorizationProjection } = useContext(AnalyticsContext)
    const [isLoading, setIsLoading] = useState(true)
    const [data, setData] = useState({})

    async function fetchData(_sessionId) {
        try {
            setIsLoading(true)
            const query = isRating ? API__ANALYTICS.GET_DETAILED_RATING_INFO : API__ANALYTICS.GET_DETAILED_LEAD_INFO
            const result = await query({
                projectId,
                sessionId: _sessionId,
                blockId: blockProjection.blockId,
                from: dateRange.from,
                to: dateRange.to,
            })
            const authorizationFields = await getAuthorizationFields(result)
            setData({ ...mapLeadInfo(result, blockProjection), authorizationFields })
            setIsLoading(false)
        } catch (err) {
            console.error(err)
        }
    }

    const getAuthorizationFields = async result => {
        try {
            if (!authorizationProjection) return null

            let email = null
            if (result?.gamificationData?.email) {
                email = result.gamificationData.email
            } else {
                if (result.fields) {
                    const emailField = result.fields.find(field => field.key === FIELD_KEYS.EMAIL)
                    if (emailField) email = emailField.value
                }
            }

            if (!email) return null

            const response = await API__ANALYTICS.GET_AUTHORIZATION_INFO(projectId, { email })

            const mappedData = authorizationProjection.metadata.leadFormFields.map(projectionField => {
                return {
                    ...projectionField,
                    value: response[projectionField.key] || null,
                }
            })
            if (mappedData.every(item => !item.value)) return null
            return mappedData
        } catch (err) {
            console.error(err)
            return null
        }
    }

    useEffect(() => {
        console.log('data:', data)
    }, [data])

    useEffect(() => {
        if (sessionId) fetchData(sessionId)
    }, [sessionId])

    return (
        <>
            <Modal isShowCloseIcon onClose={onClose}>
                <div className={styles.leadInfoModal}>
                    <h2 className={styles.leadInfoModalHeader}>{t('Lead info')}</h2>
                    {isLoading ? (
                        <Loader />
                    ) : (
                        <div className={styles.leadInfoModalBody}>
                            <p className={styles.leadInfoModalTimeStamp}>
                                {t('Lead Time')}: {getDateTime(data.created, 'LLLL')}
                            </p>
                            <ul className={styles.leadInfoModalFields}>
                                {data.fields.map(field => (
                                    <li key={field?.key} className={styles.leadInfoModalField}>
                                        {t(field?.label)}: {getColumnView(field)}
                                    </li>
                                ))}
                            </ul>

                            {data.gamificationDataArray && (
                                <div>
                                    <h3 className={styles.leadInfoModalBlockTitle}>{t('Leader board result')}</h3>
                                    <ul className={styles.leadInfoModalFields}>
                                        {data.gamificationDataArray.map((column, index) => (
                                            <li key={index} className={styles.leadInfoModalField}>
                                                {t(column.headerName)}: {getColumnView(column)}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            {data.quizzes &&
                                data.quizzes.map(
                                    quiz =>
                                        !!quiz?.quizResults.length &&
                                        !!quiz?.quizAnswers.length && (
                                            <div key={quiz?.blockId}>
                                                <div>
                                                    <h3 className={styles.leadInfoModalBlockTitle}>
                                                        {t('Quiz result')}
                                                    </h3>

                                                    <ul className={styles.leadInfoModalFields}>
                                                        <li className={styles.leadInfoModalField}>
                                                            {quiz?.quizResults.map((result, index) => (
                                                                <p key={result?.id + index}>{result?.header}</p>
                                                            ))}
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div>
                                                    <h3 className={styles.leadInfoModalBlockTitle}>
                                                        {t('Quiz answers')}
                                                    </h3>

                                                    <ul className={styles.leadInfoModalFields}>
                                                        {quiz?.quizAnswers.map(question => (
                                                            <li
                                                                key={question?.id}
                                                                className={styles.leadInfoModalField}
                                                            >
                                                                <p>{question?.text}</p>
                                                                <div className={styles.leadInfoModalFieldDescription}>
                                                                    {question?.answers.map((answer, index) => (
                                                                        <p key={answer?.id + index}>{answer?.text}</p>
                                                                    ))}
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        ),
                                )}

                            {data.wheels &&
                                data.wheels.map(
                                    wheel =>
                                        !!wheel?.wheelSections.length && (
                                            <div key={wheel?.blockId}>
                                                <div>
                                                    <h3 className={styles.leadInfoModalBlockTitle}>
                                                        {t('Wheel result')}
                                                    </h3>

                                                    <ul className={styles.leadInfoModalFields}>
                                                        <li className={styles.leadInfoModalField}>
                                                            {wheel?.wheelSections.map(section => {
                                                                if (!section) return null
                                                                return (
                                                                    <p key={section.id}>
                                                                        {section.resultHeader}
                                                                        {!!section.resultDescription &&
                                                                            ` | ${section.resultDescription}`}
                                                                    </p>
                                                                )
                                                            })}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        ),
                                )}

                            {!!data.authorizationFields?.length && (
                                <div>
                                    <h3 className={styles.leadInfoModalBlockTitle}>{t('Authorization data')}</h3>
                                    <ul className={styles.leadInfoModalFields}>
                                        {data.authorizationFields.map(field => (
                                            <li key={field?.key} className={styles.leadInfoModalField}>
                                                {t(field?.label)}: {getColumnView(field)}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            <div>
                                <h3 className={styles.leadInfoModalBlockTitle}>{t('Additional Information')}</h3>
                                <ul className={styles.leadInfoModalFields}>
                                    <li className={styles.leadInfoModalField}>
                                        <p>{t('Source')}</p>
                                        <p>{data.source}</p>
                                    </li>
                                    <li className={styles.leadInfoModalField}>
                                        <p>{t('IP address')}</p>
                                        <p>{data.ipAddress}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            </Modal>
        </>
    )
}

export default LeadInfoModal
