import { useCallback, useLayoutEffect, useState } from 'react'

const getRect = element => {
    if (!element) return null
    return element.getBoundingClientRect()
}

const useRect = (ref, timeoutDelay = 0) => {
    const [rect, setRect] = useState(() => getRect(ref.current))
    let timeout = null

    const handleResize = useCallback(() => {
        if (!ref.current) return

        setRect(getRect(ref.current))
    }, [ref])

    useLayoutEffect(() => {
        const element = ref.current
        if (!element) return

        handleResize()
        window.addEventListener('resize', handleResize)
        window.addEventListener('scroll', handleResize)
        if (timeoutDelay) timeout = setTimeout(handleResize, timeoutDelay)
        return () => {
            window.removeEventListener('resize', handleResize)
            window.removeEventListener('scroll', handleResize)
            clearTimeout(timeout)
        }
    }, [handleResize, ref])

    return [rect]
}

export default useRect
