import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Loader from 'components/Loader/Loader'
import Modal from 'components/Modal/Modal'

import { getDateTime } from 'utils/dateTime'

import { API__ANALYTICS } from 'api'

import { getFormattedValue } from '../../utils'

import styles from './LeadDetails.module.scss'

const LeadDetails = ({ sessionId, fields, projectId, blockId, dateRange, onClose }) => {
    const { t } = useTranslation()

    const [data, setData] = useState(null)

    useEffect(() => {
        API__ANALYTICS.GET_DETAILED_LEAD_INFO({
            projectId,
            sessionId,
            blockId,
            from: dateRange.from,
            to: dateRange.to,
        })
            .then(response => {
                response.fields = fields.map(projectionField => {
                    const responseField = response.fields.find(
                        responseField => responseField.key === projectionField.key,
                    )
                    if (responseField) return { ...projectionField, ...responseField }
                    return projectionField
                })
                setData(response)
            })
            .catch(err => {
                console.error(err)
            })
    }, [])

    return (
        <Modal isShowCloseIcon onClose={onClose}>
            <div className={styles.leadModal}>
                <h2 className={styles.header}>{t('Lead info')}</h2>
                {!!data ? (
                    <div className={styles.body}>
                        <div className={styles.time}>
                            {t('Lead Time')}: {getDateTime(data.created, 'LLLL')}
                        </div>
                        <div className={styles.boxes}>
                            <div className={styles.box}>
                                <ul className={styles.boxFields}>
                                    {data.fields.map(field => (
                                        <li key={field.key} className={styles.boxField}>
                                            <strong>{t(field.label)}:</strong>{' '}
                                            {getFormattedValue(field.value, field.key, data.fields)}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className={styles.box}>
                                <h3 className={styles.boxTitle}>{t('Additional Information')}</h3>
                                <ul className={styles.boxFields}>
                                    <li className={styles.boxField}>
                                        <div className={styles.boxFieldTitle}>{t('Source')}</div>
                                        <p>{data.source}</p>
                                    </li>
                                    <li className={styles.boxField}>
                                        <div className={styles.boxFieldTitle}>{t('IP address')}</div>
                                        <p>{data.ipAddress}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                ) : (
                    <Loader />
                )}
            </div>
        </Modal>
    )
}

export default LeadDetails
