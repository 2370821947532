import styles from './ListGroup.module.scss'

const ListGroup = ({ title, children }) => (
    <li className={styles.listGroup}>
        {!!title && <h4 className={styles.title}>{title}</h4>}
        <ul className={styles.list}>{children}</ul>
    </li>
)

export default ListGroup
