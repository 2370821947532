import { useEffect } from 'react'
import ReactGTM from 'react-gtm-module'

import { COOKIE_PREFERENCES_KEYS, isHasCookiePreference } from 'common/constants/cookies'

const GTM_ID = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID
const isEnabled = () => !!GTM_ID && isHasCookiePreference(COOKIE_PREFERENCES_KEYS.ANALYTICAL)

const useGoogleTagManager = () => {
    useEffect(() => {
        if (!isEnabled()) return
        console.info('"Google Tag Manager" initialization')

        ReactGTM.initialize({
            gtmId: GTM_ID,
        })
    }, [])
}

export default useGoogleTagManager
