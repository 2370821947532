import { cloneDeep } from "lodash"

export const findBlockInPagesById = (projectStructure, blockId) => {
    const pages = cloneDeep(projectStructure.pages)

    for (const [pageIndex, page] of pages.entries()) {
        const blockIndex = page.blocks.findIndex(block => block.id === blockId)
        if (blockIndex !== -1) {
            return {
                pageIndex,
                page,
                blockIndex,
                block: pages[pageIndex].blocks[blockIndex],
            }
        }
    }

    return null
}