import React from 'react'
import ReactPaginate from 'react-paginate'
import classNames from "classnames"

import './Pagination.scss'

const Pagination = ({
    disableInitialCallback = true,
    marginPagesDisplayed = 2,
    pageRangeDisplayed = 3,
    currentPage = 0,
    totalPages = 0,
    isLoading = false,
    onChange = () => {},
}) => (
    <div className={classNames('common-pagination', { 'is-visible': totalPages > 1, 'is-loading': isLoading })}>
        <ReactPaginate
            previousLabel={'PREV'}
            nextLabel={'NEXT'}
            breakLabel={'...'}
            breakClassName={'break'}
            disableInitialCallback={disableInitialCallback}
            forcePage={currentPage}
            pageCount={totalPages}
            marginPagesDisplayed={marginPagesDisplayed}
            pageRangeDisplayed={pageRangeDisplayed}
            onPageChange={({ selected }) => onChange(selected)}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'is-active'}
        />
    </div>
)

export default Pagination
