import React from 'react'
import classNames from 'classnames'

import Tooltip from 'components/Tooltip/Tooltip'

class RadioButton extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    changeValue = value => {
        const { onUpdate, name, id } = this.props

        onUpdate(id, { [name]: value })
    }

    render() {
        const {
            value,
            tooltipText,
            schema: { label = '', payload },
        } = this.props

        let items = []
        if (payload && payload.items) {
            items = payload.items
        }
        let style = {}
        if (payload && payload.flexDirection) {
            style = { ...style, flexDirection: payload.flexDirection }
        }

        const _value = !!payload.getOptionFromValue ? payload.getOptionFromValue(value) : value

        return (
            <div className="control-box">
                {!!label.length && (
                    <p className="label">
                        {label}
                        {<Tooltip tooltipText={tooltipText} />}
                    </p>
                )}
                <div className="content radio" style={style}>
                    {items.map(item => (
                        <div
                            key={item.value}
                            className={classNames('radio-option', { 'is-checked': _value.value === item.value })}
                            onClick={() => {
                                if (!!payload.setValueFromOption) this.changeValue(payload.setValueFromOption(item))
                                else this.changeValue(item)
                            }}
                        >
                            {item.label}
                        </div>
                    ))}
                </div>
            </div>
        )
    }
}

export default RadioButton
