import { INTERACTIVE_BLOCKS } from '../pages/Editor/Tabs/Editor/schema'

const getMultiplayerBlocksIds = () => INTERACTIVE_BLOCKS.filter(block => block.isGameEvent).map(({ t }) => t)

export const getIsMultiplayerBlock = ({ t }) => {
    const multiplayerBlocksIds = getMultiplayerBlocksIds()
    return !!multiplayerBlocksIds.includes(t)
}

export const getMultiplayerBlockInPage = ({ blocks }) => {
    const multiplayerBlocksIds = getMultiplayerBlocksIds()
    const block = blocks.find(block => multiplayerBlocksIds.includes(block.t))
    return block || null
}

const getMultiplayerRoundsData = (pages, maxRoundsTimeArray) => {
    const rounds = []

    pages.forEach((page, index) => {
        const multiplayerBlock = getMultiplayerBlockInPage(page)

        rounds.push({
            pageId: page.id,
            maxRoundTime: maxRoundsTimeArray[index] * 1000 || 60 * 1000,
            scorable: multiplayerBlock
                ? {
                      blockId: multiplayerBlock.id,
                      blockTypeId: multiplayerBlock.t,
                  }
                : null,
        })
    })

    return rounds
}

export const getMultiplayerGameData = ({
    projectId,
    name,
    isHasLeader = false,
    playersCount,
    projectStructureJson,
    maxRoundResultTime = 10,
    maxRoundsTimeArray = [],
    isStartNow,
}) => ({
    projectId,
    name,
    isHasLeader,
    playersCount,
    maxRoundResultTime: maxRoundResultTime * 1000,
    rounds: getMultiplayerRoundsData(projectStructureJson.pages, maxRoundsTimeArray),
    isStartNow,
})
