import React from 'react'
import classNames from 'classnames'

import Tooltip from 'components/Tooltip/Tooltip'

const LinkWithModal = ({
    id,
    blockType,
    isEnableRating = false,
    value,
    tooltipText,
    tooltipCallback,
    schema: { label = '', payload },
    schemaName,
    openModalByName,
    colorTheme,
    fontFamily,
    leadFormFontFamily,
    leadFormBackgroundColor,
}) => (
    <div className={classNames('control-box', { 'flexed': !!payload.name })}>
        {payload.name && <p className="name">{payload.name}</p>}
        <span
            className="link"
            onClick={() =>
                openModalByName(payload.modalName, {
                    value,
                    blockId: id,
                    blockType,
                    isEnableRating,
                    fieldName: schemaName,
                    colorTheme,
                    fontFamily,
                    leadFormFontFamily,
                    leadFormBackgroundColor,
                })
            }
        >
            {label}
        </span>
        <Tooltip tooltipText={tooltipText} onClick={tooltipCallback} />
    </div>
)

export default LinkWithModal
