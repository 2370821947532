import React from 'react'
import classNames from 'classnames'

import './Toggle.scss'

const Toggle = ({ label = '', value, onUpdate, isDisabled }) => {
    const onChange = () => {
        if (isDisabled) return
        onUpdate(!value)
    }

    return (
        <div className="common-toggle">
            <div className="common-toggle__slider-wrapper">
                <span className={classNames('common-toggle__slider', { 'checked': value })} onClick={onChange} />
            </div>
            {!!label && (
                <p className="common-toggle__label" onClick={onChange}>
                    {label}
                </p>
            )}
        </div>
    )
}

export default Toggle
