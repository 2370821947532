import classNames from 'classnames'
import { useState } from 'react'
import { Popover as ReactTinyPopover } from 'react-tiny-popover'

import { useDelayedHovered } from 'hooks/useHovered'

import { isTouchDevice } from 'utils/common'

import ArrowIcon from './svg/Arrow'
import MinusIcon from './svg/Minus'
import PlusIcon from './svg/Plus'

import styles from './Button.module.scss'

const Button = ({
    icon = null,
    style = {},
    isActive = false,
    onClick = () => {},
    isDisabled = false,
    isDropdown = false,
    isSmall = false,
    placeholder,
    width,
    label,
    isCentered,
    onPlus,
    onMinis,
    isDisabledMinus,
    isDisabledPlus,
    children,
}) => {
    const [isHovered, onMouseOver, onMouseLeave] = useDelayedHovered(400)
    const [isBlockPopover] = useState(() => isTouchDevice())

    return (
        <ReactTinyPopover
            content={label}
            isOpen={!isBlockPopover && !!label && isHovered}
            positions={['top']}
            padding={2}
            containerClassName={styles.popover}
        >
            <button
                onClick={onClick}
                className={classNames(styles.button, {
                    [styles.isActiveButton]: isActive,
                    [styles.isSmallButton]: isSmall,
                })}
                disabled={isDisabled}
                style={style}
                onMouseOver={() => onMouseOver()}
                onMouseLeave={() => onMouseLeave()}
            >
                {!!onMinis && (
                    <div
                        className={classNames(styles.control, styles.controlMinus, {
                            [styles.isDisabledControl]: isDisabledMinus,
                        })}
                        onClick={evt => {
                            evt.stopPropagation()
                            onMinis()
                        }}
                    >
                        <MinusIcon />
                    </div>
                )}
                {!!children && (
                    <span
                        className={styles.text}
                        style={{ width: width || 'auto', textAlign: isCentered ? 'center' : 'left' }}
                    >
                        {children}
                    </span>
                )}
                {!children && placeholder && (
                    <span className={classNames(styles.text, styles.textPlaceholder)}>{placeholder}</span>
                )}
                {!!onPlus && (
                    <div
                        className={classNames(styles.control, styles.controlPlus, {
                            [styles.isDisabledControl]: isDisabledPlus,
                        })}
                        onClick={evt => {
                            evt.stopPropagation()
                            onPlus()
                        }}
                    >
                        <PlusIcon />
                    </div>
                )}
                {!!icon && <div className={styles.icon}>{icon}</div>}
                {isDropdown && (
                    <div className={styles.dropDownIcon}>
                        <ArrowIcon />
                    </div>
                )}
            </button>
        </ReactTinyPopover>
    )
}

export default Button
