import { Extension } from '@tiptap/core'

const LetterSpacing = Extension.create({
    name: 'letterSpacing',

    addOptions() {
        return {
            types: ['textStyle'],
        }
    },

    addGlobalAttributes() {
        return [
            {
                types: this.options.types,
                attributes: {
                    letterSpacing: {
                        default: null,
                        parseHTML: element => {
                            return element.style.letterSpacing
                        },
                        renderHTML: ({ letterSpacing }) => {
                            if (!letterSpacing) return {}

                            return { style: `letter-spacing: ${letterSpacing}` }
                        },
                    },
                },
            },
        ]
    },

    addCommands() {
        return {
            setLetterSpacing: letterSpacing => ({ chain }) => {
                return chain().setMark('textStyle', { letterSpacing }).run()
            },
            unsetLetterSpacing: () => ({ chain }) => {
                return chain().setMark('textStyle', { letterSpacing: null }).removeEmptyTextStyle().run()
            },
        }
    },
})

export default LetterSpacing
