import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import DateRangePicker from 'components/DateRangePicker'
import Dropdown from 'components/Dropdown'

import styles from './PeriodSelector.module.scss'

import { PERIOD_TYPES, PERIOD_INTERVALS } from './constants'

import { formatRange } from "./utils"

const PeriodSelector = ({
    initialPeriodInterval = PERIOD_INTERVALS.THIRTY_DAYS,
    onChange,
}) => {
    const { t } = useTranslation()

    const [periodType, setPeriodType] = useState(PERIOD_TYPES[initialPeriodInterval])

    const [range, setRange] = useState(null)

    useEffect(() => {
        const newRange = periodType.getTimesRange()
        setRange(newRange)
    }, [periodType])

    useEffect(() => {
        if (!range) return
        onChange(range)
    }, [range])

    if (!range) return null

    return (
        <div className={styles.periodSelector}>
            <div className={styles.dropdownWrapper}>
                <Dropdown
                    active={periodType}
                    list={Object.values(PERIOD_TYPES)}
                    onChange={v => setPeriodType(v)}
                />
            </div>
            {periodType.value === PERIOD_INTERVALS.CUSTOM && (
                <DateRangePicker
                    startDate={range.from}
                    endDate={range.to}
                    onChangeRange={v => setRange(formatRange(v))}
                />
            )}
        </div>
    )
}

export default PeriodSelector
