const ClearFormatting = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        <path d="M4 7V4h16v3"></path>
        <path d="M5 20h6"></path>
        <path d="M13 4 8 20"></path>
        <path d="m15 15 5 5"></path>
        <path d="m20 15-5 5"></path>
    </svg>
)

export default ClearFormatting
