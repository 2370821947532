import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { cloneDeep } from 'lodash'

import StopPanels, {
    useStopPanes,
    VALIDATION_WARNING_TYPES,
} from '../../Blocks/components/Modals/components/StopPanels/StopPanels'

import { TABS_ENUM, MODE, TABS_ARRAY_BY_MODE, STRUCT_KEY_BY_MODE, TAB_TO_FIELD_MAP } from './constants'

import {
    getInitialLeadFormStructureForIndependentMode,
    getInitialLeadFormStructureForEmbeddedMode,
    getInitialAuthFormStructure,
    validateTabs,
} from './utils'

import Modal from 'components/Modal/Modal'
import Header from 'components/Modal/components/Header/Header'
import Body from 'components/Modal/components/Body/Body'

import Form from './views/Form/Form'
import Final from './views/Final/Final'

import './LeadFormModal.scss'

const LeadFormModal = ({
    methods,
    data: { isEnableRating, structure, backgroundColor = '#fff', fontFamily, id },
    mode = MODE.INDEPENDENT,
}) => {
    const { t } = useTranslation()
    const [tmpStructure, setTmpStructure] = useState(() => {
        if (mode === MODE.INDEPENDENT) return getInitialLeadFormStructureForIndependentMode()
        if (mode === MODE.EMBEDDED) return getInitialLeadFormStructureForEmbeddedMode()
        if (mode === MODE.AUTH) return getInitialAuthFormStructure()
    })
    const [activeTab, setActiveTab] = useState(TABS_ENUM.form)
    const [errors, setErrors] = useState({ form: {}, final: {} })

    const {
        isShowQuitAccept,
        onShowQuitAccept,
        validationWarningType,
        setValidationWarningType,
        onClosePanels,
    } = useStopPanes()

    useEffect(() => {
        if (Object.keys(structure).length) {
            setTmpStructure(cloneDeep(structure))
        }
    }, [structure])

    const onSave = async () => {
        const result = validateTabs(tmpStructure, mode)
        if (!result.isHasError) {
            await methods.save({
                [id]: {
                    data: { [STRUCT_KEY_BY_MODE[mode]]: result.tmpStructure },
                },
            })
            onClose()
        } else {
            setErrors(prevState => ({
                ...prevState,
                ...result.errors,
            }))
            setValidationWarningType(VALIDATION_WARNING_TYPES.COMMON)
        }
    }

    const onClose = () => {
        methods.closeModal()
    }

    const onUpdateStructure = type => newFields => {
        const _errors = {}
        Object.keys(newFields).forEach(key => (_errors[key] = []))
        setErrors(prevState => ({
            ...prevState,
            [type]: {
                ...prevState[type],
                ..._errors,
            },
        }))
        setTmpStructure(prevState => ({
            ...prevState,
            [type]: {
                ...prevState[type],
                ...newFields,
            },
        }))
    }

    const onChangeTab = useCallback(
        tabId => {
            const result = validateTabs(tmpStructure, mode, TAB_TO_FIELD_MAP[activeTab])
            if (!result.isHasError) setActiveTab(tabId)
            else {
                setErrors(prevState => ({
                    ...prevState,
                    ...result.errors,
                }))
                setValidationWarningType(VALIDATION_WARNING_TYPES.ACTIVE_TAB)
            }
        },
        [tmpStructure, activeTab],
    )

    return (
        <Modal closeOnOverlayClick={false} closeOnEsc={false}>
            <Header title={mode === MODE.AUTH ? t('Login form') : t('Form options')} onClose={onShowQuitAccept} />
            <Body
                sideNav={{
                    items: TABS_ARRAY_BY_MODE[mode],
                    activeItemId: activeTab,
                    onChangeItem: onChangeTab,
                    analyticsBlockName: 'LEAD_FORM',
                }}
                onSave={onSave}
            >
                <div className="lead-form-modal-wrapper">
                    <div className="lead-form-modal-wrapper__body">
                        {activeTab === TABS_ENUM.form && (
                            <Form
                                mode={mode}
                                isEnableRating={isEnableRating}
                                backgroundColor={backgroundColor}
                                fontFamily={fontFamily}
                                structure={tmpStructure}
                                validateErrors={errors.form}
                                methods={{ ...methods, updateStructure: onUpdateStructure('form') }}
                            />
                        )}
                        {activeTab === TABS_ENUM.final && (
                            <Final
                                structure={tmpStructure}
                                validateErrors={errors.final}
                                methods={{ ...methods, updateStructure: onUpdateStructure('final') }}
                            />
                        )}
                    </div>
                </div>
            </Body>

            <StopPanels
                isShowQuitAccept={isShowQuitAccept}
                validationWarningType={validationWarningType}
                onBack={onClosePanels}
                onQuit={onClose}
            />
        </Modal>
    )
}

export default LeadFormModal
