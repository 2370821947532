import React from 'react'

import i18n from 'i18n'

import styles from './OverText.module.scss'

const OverText = ({ text = i18n.t('This feature is not available for your current plan') }) => (
    <div className={styles.overText}>{!!text && <div className={styles.text}>{text}</div>}</div>
)

export default OverText
