import ParagraphIcon from './svg/Paragraph'
import H1Icon from './svg/H1'
import H2Icon from './svg/H2'
import H3Icon from './svg/H3'
import BulletListIcon from './svg/BulletList'
import OrderedListIcon from './svg/OrderedList'
import BoldIcon from './svg/Bold'
import ItalicIcon from './svg/Italic'
import UnderlineIcon from './svg/Underline'
import StrikeIcon from './svg/Strike'
import ListIcon from './svg/List'
import AlignLeftIcon from './svg/AlignLeft'
import AlignCenterIcon from './svg/AlignCenter'
import AlignRightIcon from './svg/AlignRight'
import AlignJustifyIcon from './svg/AlignJustify'
import ImageIcon from './svg/Image'
import LinkIcon from './svg/Link'
import ClearFormattingIcon from './svg/ClearFormatting'
import LineHeightIcon from './svg/LineHeight'
import PaletteIcon from './svg/Palette'
import HighlightIcon from './svg/Highlight'
import BlockquoteIcon from './svg/Blockquote'
import LetterSpacingIcon from './svg/LetterSpacing'

export const CONTROLS = {
    PARAGRAPH: 'paragraph',
    HEADING: 'heading',
    BULLET_LIST: 'bulletList',
    ORDERED_LIST: 'orderedList',
    BOLD: 'bold',
    ITALIC: 'italic',
    UNDERLINE: 'underline',
    STRIKE: 'strike',
    TEXT_STYLE: 'textStyle',
    BLOCKQUOTE: 'blockquote',
}

export const CONTROL_PARAMS = {
    HEADING: {
        LEVEL: 'level',
    },
    HIGHLIGHT: {
        COLOR: 'color',
    },
}

export const TEXT_PARAMS = {
    TEXT_ALIGN: {
        NAME: 'textAlign',
        VALUES: {
            LEFT: 'left',
            CENTER: 'center',
            RIGHT: 'right',
            JUSTIFY: 'justify',
        },
    },
}

export const ICONS = {
    PARAGRAPH: <ParagraphIcon />,
    HEADING: {
        LEVEL_1: <H1Icon />,
        LEVEL_2: <H2Icon />,
        LEVEL_3: <H3Icon />,
    },
    LIST: <ListIcon />,
    BULLET_LIST: <BulletListIcon />,
    ORDERED_LIST: <OrderedListIcon />,
    BOLD: <BoldIcon />,
    ITALIC: <ItalicIcon />,
    UNDERLINE: <UnderlineIcon />,
    STRIKE: <StrikeIcon />,
    ALIGN: {
        LEFT: <AlignLeftIcon />,
        CENTER: <AlignCenterIcon />,
        RIGHT: <AlignRightIcon />,
        JUSTIFY: <AlignJustifyIcon />,
    },
    IMAGE: <ImageIcon />,
    LINK: <LinkIcon />,
    CLEAR_FORMATTING: <ClearFormattingIcon />,
    LINE_HEIGHT: <LineHeightIcon />,
    PALETTE: <PaletteIcon />,
    HIGHLIGHT: <HighlightIcon />,
    BLOCKQUOTE: <BlockquoteIcon />,
    LETTER_SPACING: <LetterSpacingIcon />,
}

export const FONT_SIZES = [
    '8px',
    '10px',
    '12px',
    '14px',
    '16px',
    '18px',
    '20px',
    '24px',
    '30px',
    '36px',
    '48px',
    '60px',
    '72px',
    '96px',
]
export const DEFAULT_FONT_SIZE = '16px'

export const LINE_HEIGHTS = ['100%', '120%', '150%', '200%', '250%', '300%']
export const DEFAULT_LINE_HEIGHT = '120%'

export const LETTER_SPACINGS = ['-0.1em', '-0.05em', '-0.025em', '0', '0.025em', '0.05em', '0.1em']
export const DEFAULT_LETTER_SPACING = '0'

export const DEFAULT_FONT_FAMILY = 'Roboto'
