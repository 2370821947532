import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import i18n from 'i18n'

import Pagination from 'components/Pagination/Pagination'
import Loader from 'components/Loader/Loader'

import { downloadCSV } from 'common/utils/files'

import LeadDetails from './components/LeadDetails/LeadDetails'

import { getFormattedValue, getCustomFieldsMap, getCsvFilename, getFieldLabelByKey } from './utils'

import csvIcon from './i/csv.svg'
import loaderIcon from './i/loader.svg'
import eyeIcon from './i/eye.svg'
import fakeLeadsImage from './i/fakeLeads.png'

import styles from './Leads.module.scss'

const Leads = ({
    blockId,
    fields,
    projectId,
    dateRange,
    onLoadDataRequest,
    onLoadCsvRequest,
    csvFilenamePrefix = i18n.t('Lead Form'),
}) => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)

    const [lastResponse, setLastResponse] = useState(null)
    const [keys, setKeys] = useState([])
    const [detailedLead, setDetailedLead] = useState(null)

    useEffect(() => {
        if (!dateRange || isLoading) return
        requestData()
    }, [dateRange])

    useEffect(() => {
        setKeys(fields.map(field => field.key))
    }, [lastResponse])

    const requestData = async (page = 0) => {
        try {
            setIsLoading(true)
            const response = await onLoadDataRequest(projectId, {
                size: 20,
                page,
                from: dateRange.from,
                to: dateRange.to,
                sort: 'created,desc',
            })
            setLastResponse(response)
        } catch (err) {
            console.error(err)
        } finally {
            setIsLoading(false)
        }
    }

    const onExportCSV = async () => {
        const content = await onLoadCsvRequest(projectId, {
            blockId,
            from: dateRange.from,
            to: dateRange.to,
            customFieldsMap: getCustomFieldsMap(fields),
        })
        const fileName = getCsvFilename(csvFilenamePrefix, dateRange.from, dateRange.to)
        downloadCSV(content, fileName)
    }

    return (
        <div className={styles.leads}>
            {!!lastResponse ? (
                <>
                    {isLoading && (
                        <div className={styles.loader}>
                            <img src={loaderIcon} alt="loader" />
                        </div>
                    )}
                    {!!lastResponse.content.length ? (
                        <>
                            <div className={styles.header}>
                                <div className={styles.export} onClick={onExportCSV}>
                                    {t('Export CSV')}
                                    <img src={csvIcon} alt="csv" />
                                </div>
                            </div>
                            <div className={styles.grid}>
                                {keys.map(key => (
                                    <div className={styles.gridRow} key={key}>
                                        <div className={styles.gridHead}>{t(getFieldLabelByKey(key, fields))}</div>
                                        {lastResponse.content.map((item, index) => (
                                            <div className={styles.gridItem} key={index}>
                                                {getFormattedValue(item[key], key, fields, '')}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                                <div className={styles.gridRow}>
                                    <div className={styles.gridHead}>{t('More info')}</div>
                                    {lastResponse.content.map((item, index) => (
                                        <div className={styles.gridItem} key={index}>
                                            <button onClick={() => setDetailedLead(item)}>
                                                <img src={eyeIcon} alt="more info" />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <Pagination
                                currentPage={lastResponse.pageable.pageNumber}
                                totalPages={lastResponse.totalPages}
                                onChange={requestData}
                                isLoading={isLoading}
                            />
                        </>
                    ) : (
                        <div className={styles.noContent}>{t('No records yet')}</div>
                    )}
                </>
            ) : (
                <Loader />
            )}
            {!!detailedLead && (
                <LeadDetails
                    fields={fields}
                    projectId={projectId}
                    blockId={blockId}
                    dateRange={dateRange}
                    sessionId={detailedLead.sessionId}
                    onClose={() => setDetailedLead(null)}
                />
            )}
        </div>
    )
}

export const FakeLeads = ({ children }) => (
    <div className={styles.leads}>
        <img src={fakeLeadsImage} alt="leads" />
        {children}
    </div>
)

export default Leads
