import {useEffect, useState} from "react"

import useDebounce from "./useDebounce"

const useHovered = () => {
    const [isHovered, setIsHovered] = useState(false)


    const onMouseOver = () => setIsHovered(true)
    const onMouseLeave = () => setIsHovered(false)

    return [isHovered, onMouseOver, onMouseLeave]
}

export const useDelayedHovered = (delay = 500) => {
    const [isHovered, setIsHovered] = useState(false)
    const [isMouseOver, setIsMouseOver] = useState(false)

    const isDelayedMouseOver = useDebounce(isMouseOver, delay)

    useEffect(() => {
        if (isMouseOver && isDelayedMouseOver) setIsHovered(true)
        else setIsHovered(false)
    }, [isMouseOver, isDelayedMouseOver])

    const onMouseOver = () => setIsMouseOver(true)
    const onMouseLeave = () => setIsMouseOver(false)

    return [isHovered, onMouseOver, onMouseLeave]
}

export default useHovered