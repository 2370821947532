import React from 'react'
import classNames from 'classnames'

import Image from 'components/Form/Image'

import './StartScreen.scss'

const StartScreen = ({ struct, backgroundColor, fontFamily }) => {
    return (
        <div className="start-screen-preview">
            {!!struct.cover.image && <Image className="image" src={struct.cover.image} />}
            <div className={classNames('box', { 'no-image': !struct.cover.image })}>
                <div className="head" style={{ fontFamily }}>
                    {struct.cover.header}
                </div>
                {!!struct.cover.description.length && (
                    <div className="description" style={{ fontFamily }}>
                        {struct.cover.description}
                    </div>
                )}
                <div className="btn-wrap">
                    <div className="btn" style={{ backgroundColor: backgroundColor, fontFamily }}>
                        {struct.cover.buttonText}
                    </div>
                </div>
                {!!struct.cover.imageDisclaimer.length && (
                    <div className="image-disclaimer" style={{ fontFamily }}>
                        {struct.cover.imageDisclaimer}
                    </div>
                )}
            </div>
        </div>
    )
}

export default StartScreen
