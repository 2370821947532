import axios from 'axios'

import { getTimeStep } from 'utils/dateTime'

import { MAIN_SERVER } from './instance'

export const GET_PROJECT_STATISTICS = async ({ projectId, from, to }) =>
    MAIN_SERVER({
        method: 'get',
        url: `/projects/${projectId}/statistics`,
        params: {
            from,
            to,
        },
    })

export const GET_PROJECT_STATISTICS_CHART_DATA = ({ code, projectId, from, to }) =>
    MAIN_SERVER({
        method: 'get',
        url: `/projects/${projectId}/statistics/${code}/discrete`,
        params: {
            from,
            to,
            timeStep: getTimeStep(from, to, false),
        },
    })

export const GET_PROJECT_STATISTICS_MAP_DATA = async (
    { projectId, target, from, to },
    isAttachCountriesData = false,
) => {
    const result = await MAIN_SERVER({
        method: 'get',
        url: `/projects/${projectId}/statistics/countries/${target}`,
        params: {
            from,
            to,
        },
    })

    if (!isAttachCountriesData) return result

    const countryCodesList = result.map(el => el.isoCountryCode)

    const countriesData = (
        await axios({
            method: 'get',
            url: 'https://restcountries.com/v3.1/alpha',
            params: {
                codes: countryCodesList.join(','),
            },
        })
    ).data

    return result.map(item => ({
        ...item,
        country: countriesData.find(country => country.cca2 === item.isoCountryCode),
    }))
}

export const GET_PROJECT_STATISTICS_BY_ACTION_TYPE = ({ projectId, blockId, actionName }) =>
    MAIN_SERVER({
        method: 'get',
        url: `/projects/${projectId}/block-id/${blockId}/statistics/time-intervals`,
        params: {
            userActionTypeName: actionName,
        },
    })

export const GET_PROJECT_STATISTICS_RATING = ({ projectId, blockId, actionName, from, to, page, size = 20 }) => {
    const params = {
        actionTypeName: actionName,
        dateFrom: from,
        size,
        page,
    }
    if (to) {
        params.dateTo = to
    }

    return MAIN_SERVER({
        method: 'get',
        url: `/projects/${projectId}/block-id/${blockId}/statistics/rating`,
        params,
    })
}

export const GET_BLOCK_LEAD_FORM = (projectId, blockId, pagination = {}) =>
    MAIN_SERVER({
        method: 'get',
        url: `/projects/${projectId}/statistics/forms`,
        params: {
            blockId,
            ...pagination,
        },
    })

export const GET_AUTHORIZATION_FORM = async (projectId, pagination = {}) =>
    MAIN_SERVER({
        method: 'get',
        url: `/projects/${projectId}/statistics/sign-in-forms`,
        params: {
            ...pagination,
        },
    })

export const GET_BLOCK_LEAD_FORM_CSV = (projectId, { blockId, from, to, customFieldsMap = {} }) =>
    MAIN_SERVER({
        method: 'get',
        url: `/projects/${projectId}/statistics/forms`,
        headers: {
            Accept: 'text/csv',
            'Content-Disposition': 'attachment; filename=general.csv',
        },
        params: {
            blockId,
            separator: ',',
            noQuote: true,
            noEscape: true,
            orderResults: true,
            from,
            to,
            ...customFieldsMap,
        },
    })

export const GET_AUTHORIZATION_FORM_CSV = (projectId, params = {}) =>
    MAIN_SERVER({
        method: 'get',
        url: `/projects/${projectId}/statistics/sign-in-forms`,
        headers: {
            Accept: 'text/csv',
            'Content-Disposition': 'attachment; filename=general.csv',
        },
        params: {
            separator: ',',
            noQuote: true,
            noEscape: true,
            orderResults: true,
            ...params,
        },
    })

export const GET_BLOCK_RATING_CSV = ({ projectId, blockId, actionName, from, to = null }) => {
    const params = {
        actionTypeName: actionName,
        dateFrom: from,
    }
    if (to) {
        params.dateTo = to
    }

    return MAIN_SERVER({
        method: 'get',
        url: `/projects/${projectId}/block-id/${blockId}/statistics/rating`,
        headers: {
            Accept: 'text/csv',
            'Content-Disposition': 'attachment; filename=rating.csv',
        },
        params,
    })
}

export const GET_QUIZ_STATISTICS = async ({ projectId, blockId, pagination }) =>
    MAIN_SERVER({
        method: 'get',
        url: `/projects/${projectId}/statistics/quiz`,
        params: {
            blockId,
            ...pagination,
        },
    })

export const GET_BLOCK_BASE_STATISTICS = async (projectId, blockId, { dateRange }) =>
    MAIN_SERVER({
        method: 'get',
        url: `/projects/${projectId}/statistics/common/${blockId}`,
        params: {
            from: dateRange.from,
            to: dateRange.to,
        },
    })

export const GET_WHEEL_STATISTICS = async ({ projectId, blockId, pagination }) =>
    MAIN_SERVER({
        method: 'get',
        url: `/projects/${projectId}/statistics/wheel`,
        params: {
            blockId,
            ...pagination,
        },
    })

export const GET_RANK_BATTLE_STATISTICS = async ({ projectId, blockId, pagination }) =>
    MAIN_SERVER({
        method: 'get',
        url: `/projects/${projectId}/statistics/rank-battle`,
        params: {
            blockId,
            ...pagination,
        },
    })

export const GET_PROJECT_PROJECTION = async projectId =>
    MAIN_SERVER({
        method: 'get',
        url: `/projects/${projectId}/projections`,
    })

export const GET_DETAILED_LEAD_INFO = async ({ projectId, sessionId, blockId, from, to = null }) =>
    MAIN_SERVER({
        method: 'get',
        url: `/projects/${projectId}/statistics/forms/${sessionId}`,
        params: {
            blockId,
            from,
            to,
        },
    })

export const GET_DETAILED_RATING_INFO = async ({ projectId, sessionId, blockId, from, to = null }) =>
    MAIN_SERVER({
        method: 'get',
        url: `/projects/${projectId}/statistics/rating/${sessionId}`,
        params: {
            blockId,
            from,
            to,
        },
    })

export const GET_AUTHORIZATION_INFO = async (projectId, params = {}) =>
    MAIN_SERVER({
        method: 'get',
        url: `/projects/${projectId}/statistics/sign-in-form`,
        params,
    })
