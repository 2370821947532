import { FIELD_TYPES } from 'common/constants/fields'
import i18n from 'i18n'
import { defined, isEnum, itemRules, maxLength, minLength, required } from 'shared/validation'

import formIcon from 'components/Modal/components/Body/components/SideNav/i/form.svg'
import thumbIcon from 'components/Modal/components/Body/components/SideNav/i/thumb.svg'

export const FIELD_KEYS = {
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    EMAIL: 'email',
    NICKNAME: 'nickname',
    PHONE_NUMBER: 'phoneNumber',
    COMPANY: 'company',
    CONSENT: 'consent',
    CUSTOM_FIELD: 'customField',
}
export const MAX_CUSTOM_FIELDS = 10
// minus one because FIELD_KEYS contains CUSTOM_FIELD item
export const MAX_FIELDS = Object.keys(FIELD_KEYS).length + MAX_CUSTOM_FIELDS - 1

export const SORTED_FIELD_KEYS = [
    ...Object.values(FIELD_KEYS),
]

export const FIELD_LABELS = {
    [FIELD_KEYS.FIRST_NAME]: 'First name',
    [FIELD_KEYS.LAST_NAME]: 'Last name',
    [FIELD_KEYS.EMAIL]: 'Email',
    [FIELD_KEYS.PHONE_NUMBER]: 'Phone number',
    [FIELD_KEYS.COMPANY]: 'Company name',
    [FIELD_KEYS.NICKNAME]: 'Nickname',
    [FIELD_KEYS.CONSENT]: 'Consent',
}

export const FIELD_TYPES_DETAILS = {
    [FIELD_TYPES.TEXT_INPUT]: {
        label: i18n.t('Text input'),
        value: FIELD_TYPES.TEXT_INPUT,
    },
    [FIELD_TYPES.TEXT_AREA]: {
        label: i18n.t('Text area'),
        value: FIELD_TYPES.TEXT_AREA,
    },
    [FIELD_TYPES.EMAIL]: {
        label: i18n.t('Email'),
        value: FIELD_TYPES.EMAIL,
    },
    [FIELD_TYPES.PHONE_NUMBER]: {
        label: i18n.t('Phone number'),
        value: FIELD_TYPES.PHONE_NUMBER,
    },
    [FIELD_TYPES.URL]: {
        label: i18n.t('URL'),
        value: FIELD_TYPES.URL,
    },
    [FIELD_TYPES.CHECKBOX]: {
        label: i18n.t('Checkbox'),
        value: FIELD_TYPES.CHECKBOX,
    },
    [FIELD_TYPES.CONSENT]: {
        label: i18n.t('Consent'),
        value: FIELD_TYPES.CONSENT,
    },
}

export const TABS_ENUM = {
    form: 1,
    final: 2,
}

export const TAB_TO_FIELD_MAP = {
    [TABS_ENUM.form]: 'form',
    [TABS_ENUM.final]: 'final',
}

export const MODE = {
    INDEPENDENT: 'INDEPENDENT',
    EMBEDDED: 'EMBEDDED',
    AUTH: 'AUTH',
}

export const STRUCT_KEY_BY_MODE = {
    [MODE.INDEPENDENT]: 'struct',
    [MODE.EMBEDDED]: 'leadFormStruct',
    [MODE.AUTH]: 'struct',
}

export const TABS_ARRAY_BY_MODE = {
    [MODE.INDEPENDENT]: [
        {
            id: TABS_ENUM.form,
            label: i18n.t('Form'),
            icon: formIcon,
        },
        {
            id: TABS_ENUM.final,
            label: i18n.t('Thank you'),
            icon: thumbIcon,
        },
    ],
    [MODE.EMBEDDED]: [],
    [MODE.AUTH]: [],
}

export const FORM_KEYS = {
    fields: 'fields',
    headerText: 'headerText',
    descriptionText: 'descriptionText',
    linkPrivacyPolicyLink: 'linkPrivacyPolicyLink',
    buttonText: 'buttonText',
    logoImage: 'logoImage',
    additionalNotes: 'additionalNotes',
}
export const FINAL_KEYS = {
    headerText: 'headerText',
    descriptionText: 'descriptionText',
    imageSrc: 'imageSrc',
    imageDisclaimerText: 'imageDisclaimerText',
}

export const DEFAULT_FORM_FIELDS = {
    [FIELD_KEYS.FIRST_NAME]: {
        checkboxLabel: i18n.t('First name'),
        type: FIELD_TYPES.TEXT_INPUT,
    },
    [FIELD_KEYS.LAST_NAME]: {
        checkboxLabel: i18n.t('Last name'),
        type: FIELD_TYPES.TEXT_INPUT,
    },
    [FIELD_KEYS.EMAIL]: {
        checkboxLabel: i18n.t('Email'),
        type: FIELD_TYPES.EMAIL,
    },
    [FIELD_KEYS.NICKNAME]: {
        checkboxLabel: i18n.t('Nickname'),
        type: FIELD_TYPES.TEXT_INPUT,
    },
    [FIELD_KEYS.PHONE_NUMBER]: {
        checkboxLabel: i18n.t('Phone number'),
        type: FIELD_TYPES.PHONE_NUMBER,
    },
    [FIELD_KEYS.COMPANY]: {
        checkboxLabel: i18n.t('Company name'),
        type: FIELD_TYPES.TEXT_INPUT,
    },
}

function convertFieldErrorsToMap(fields, errors) {
    return new Map(fields.map((field, index) => [field.key, errors[index]]))
}

const fieldRules = {
    label: [required()],
    key: [defined()],
    isRequired: [defined()],
    initialValue: [defined()],
    type: [isEnum(FIELD_TYPES)],
}

const formRules = {
    buttonText: [required()],
    fields: [minLength(1), maxLength(MAX_FIELDS), itemRules(fieldRules, convertFieldErrorsToMap)],
}

export const INDEPENDENT_VALIDATE_RULES_FIELDS = {
    form: formRules,
    final: {
        headerText: [required(), maxLength(50)],
        descriptionText: [maxLength(200)],
        imageDisclaimerText: [maxLength(100)],
    },
}

export const EMBEDDED_VALIDATE_RULES_FIELDS = {
    form: formRules,
}
